import { CardMedia } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { StyledWishListButton } from "../../../components/custom-components/styled-wishList-button";
import { StyledBox } from "../box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useSite } from "../../../_foundation/hooks/useSite";
import { triggerSelectItemDL } from "../../../components/custom-components/Datalayer-Utils/dataLayer-func";
import { loginStatusSelector } from "../../../redux/selectors/user";
import { useSelector } from "react-redux";
import { proCustomerLevelSelector } from "../../../redux/selectors/organization";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { currentContractIdSelector } from "../../../redux/selectors/contract";
const ContainerStyledProductImage = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme, isB2B }) => `

&.product-card-image {
    ${theme.breakpoints.down("sm")} {
        position: relative;
        height: 150px;
        width: 150px;
        align-self: center;
        /*border: 1px solid blue;*/
        div img {
        height: 150px;
        }
    }

    ${theme.breakpoints.down("md")} {
    position: relative;
    height: 185px;
    /*border: 1px solid blue;*/
    div img {
    height: 185px;
    }
}

&.emarsysproduct-card-image {
    position: relative;
    height: 150px;
    width: 150px;
    border: 2px solid red;
}

.styledProductCard--button-wishList{
    width: 100%;
    position: absolute;
    text-align: -webkit-right;
    ${theme.breakpoints.up("md")} {
        margin-top: ${theme.spacing(2)}px;
    }
}

.styled--product-img {

    ${theme.breakpoints.down("sm")} {
    height: 150px;
    width: 150px;
    background-size: contain;
    /*border: 1px solid red;*/
    }

    ${theme.breakpoints.up("sm")} {
    height: 285px;
    background-size: contain;
    /*border: 2px solid red;*/
    }
}

.styled--emarsysproduct-img {
    height: 150px;
    width: 150px;
    background-size: contain;
    border: 2px solid blue;
}

.promo_plp-ribbon-hv {
    width: ${isB2B ? "40px" : "25px"};
    height: ${isB2B ? "33px" : "25px"};
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.product-image {
    height: 150px;
    width: 150px;
    background-size: contain;
  }

`}
`;

interface Props {
  partNumber: string;
  storeConfData: any;
  name: string;
  isWishProduct?: any;
  isEmarsysProduct?: any;
  to: string;
  promociones: any;
  urlRibbons: any;
}

export const StyledProductCardImage = (props: Props) => {
  const { partNumber, storeConfData, name, isWishProduct, isEmarsysProduct, to, promociones, urlRibbons } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { mySite } = useSite();
  const isB2B = mySite?.isB2B;
  
  const loginStatus = useSelector(loginStatusSelector);
  const proCustomerLevel = useSelector(proCustomerLevelSelector);
  const currentStore = useSelector(currentStoreSelector);
  const currentMarketId = currentStore && currentStore?.currentStore?.marketId;
  const currentStoreId = currentStore && currentStore?.currentStore?.stLocId;
  const contract = useSelector(currentContractIdSelector);
  const itemAffiliation = storeConfData?.DL_ITEM_AFFILIATION;
  
  let erroredMainImage: boolean = false;
  let erroredPromoImage: boolean = false;
  const fallbackImage = storeConfData?.IMAGE_SERVER_HOSTNAME + "assets/img/imagen-no-disponible.svg";

  const onErrorMainImage = ({ currentTarget }) => {
    if (!erroredMainImage) {
      currentTarget.src = fallbackImage;
      erroredMainImage = true;
    }
  };

  const onErrorPromoImage = ({ currentTarget }) => {
    if (!erroredPromoImage) {
      currentTarget.src = fallbackImage;
      erroredPromoImage = true;
    }
  };

  const EmarsysProductCard = ()=>{
    return (
      <ContainerStyledProductImage isB2B={isB2B} className={isEmarsysProduct ? "emarsysproduct-card-image" : "product-card-image"}>
      {isEmarsysProduct ? null : (
        <StyledWishListButton
          partNumber={partNumber}
          className="styledProductCard--button-wishList"
          placement="bottom-end"
          isWishProduct={isWishProduct}
        />
      )}
      <Link onClick = {() => {triggerSelectItemDL(
            mySite.storeID,
            isB2B && loginStatus && proCustomerLevel ? currentStore?.currentStore?.stLocId + "_" + proCustomerLevel : currentStore?.currentStore?.stLocId,
            contract,
            partNumber,
            1,
            itemAffiliation,
            currentMarketId,
            isB2B
          )}} to={to} >
      
        <CardMedia
          image={
            storeConfData?.IMAGE_SERVER_HOSTNAME +
            "productos/" +
            partNumber.replace("P", "") +
            "/" +
            partNumber.replace("P", "") +
            ".jpg"
          }
          title={name}
          src=""
          component={"img"}
          loading="lazy"
          onError={ onErrorMainImage }
          className={(isEmarsysProduct || isMobile ? "product-image" : "styled--product-img")}
        />
      </Link>
      {promociones && promociones.includes("PROMOICON_HV") ? (
        <img
          src={urlRibbons["PROMOICON_HV"]}
          loading="lazy"
          onError={ onErrorPromoImage }
          title={t("productBox.hipotecaVerde")}
          className={"promo_plp-ribbon-hv"}
        />
      ) : null}
    </ContainerStyledProductImage>
    )
  }
  const ProductCard = ()=>{
    return (
      <ContainerStyledProductImage isB2B={isB2B} className={isEmarsysProduct ? "emarsysproduct-card-image" : "product-card-image"}>
      {isEmarsysProduct ? null : (
        <StyledWishListButton
          partNumber={partNumber}
          className="styledProductCard--button-wishList"
          placement="bottom-end"
          isWishProduct={isWishProduct}
        />
      )}
      <Link onClick = {() => {triggerSelectItemDL(
            mySite.storeID,
            isB2B && loginStatus && proCustomerLevel ? currentStore?.currentStore?.stLocId + "_" + proCustomerLevel : currentStore?.currentStore?.stLocId,
            contract,
            partNumber,
            1,
            itemAffiliation,
            currentMarketId,
            isB2B
          )}} to={to} >
      
        <CardMedia
          src={
            storeConfData?.IMAGE_SERVER_HOSTNAME +
            "productos/" +
            partNumber.replace("P", "") +
            "/" +
            partNumber.replace("P", "") +
            ".jpg"
          }
          loading="lazy"
          title={name}
          component={"img"}
          onError={ onErrorMainImage }
          className={(isEmarsysProduct || isMobile ? "product-image" : "styled--product-img")}
        />
      </Link>
      {promociones && promociones.includes("PROMOICON_HV") ? (
        <img
          src={urlRibbons["PROMOICON_HV"]}
          loading="lazy"
          onError={ onErrorPromoImage }
          title={t("productBox.hipotecaVerde")}
          className={"promo_plp-ribbon-hv"}
        />
      ) : null}
    </ContainerStyledProductImage>
    )
  }

  return (
    
    isEmarsysProduct ? 
      <EmarsysProductCard/> : 
      <ProductCard/>

  )
};
