import React, { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { Divider } from "@material-ui/core"
import { StyledBox, StyledButton, StyledContainer, StyledGrid, StyledLink, StyledLinkTextSecondary, StyledMenuItem, StyledTextField, StyledTypography } from "../../../hdm"
import ProCustomStepperLabel from "../../custom-components/shared/ProCustomStepperLabel"
import SpecialTitleHDM from "../../custom-components/special-title/SpecialTItleHDM"
import { StyledSelectHdm } from "../../custom-components/styled-select"
import styled from "styled-components"
import { EMPTY_STRING, OK } from "../../../constants/common"
import { useTranslation } from "react-i18next"
import getDisplayName from "react-display-name"
import { useSelector } from "react-redux"
import { currentStoreSelector } from "../../../redux/selectors/current-store"
import { AssociatedAccounts } from "../../../interfaces/ProLoginInterfaces"
import Axios, { Canceler } from "axios";
import addressUtil from "../../../utils/addressUtil"
import { useSite } from "../../../_foundation/hooks/useSite"
import { getAssociatedAccounts } from "../../../hdm/services/getAssociatedAccounts.service"
import personService from "../../../_foundation/apis/transaction/person.service"
import * as ROUTES from "../../../constants/routes";
import { ResetPassword } from "../../widgets/reset-password"
import ChangePassword from "../../widgets/personal-information/ChangePassword"
import EspotHdm from "../../custom-components/shared/EspotHdm"
import { tabletBreakpoint } from "../../../hdm/functions/utils"
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData"

const ProForgotPassContainer = styled(({ ...props }) => <StyledContainer {...props} />)`
  ${({ theme, start, end }) => `

  &.forgot-password-conatiner {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    height: 100%;
    margin: 0;
    max-width: 1440px;
    ${theme.breakpoints.down("1023")} {
        flex-direction: column;
    }
    
  }

  .forgotpassword-form-container {
    background-color: #FFFFFF;
    display: block;
  }

  .forgotpassword-espot-container {
    background-color: #CCCCCC;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // overflow: auto;
  }

  .styled-step-image {
    display: flex;
    justify-content: center;
  }

`}
`;

interface IForgotPasswordPro {
  isPopUp?: boolean;
  setTitle?: any
  closeHandler?: any;
  handleCloseForgotPassword?: any
}

const ForgotPasswordLayoutPro = (Props: IForgotPasswordPro) => {
  
    const isPopUp = Props.isPopUp
    const setTitle = Props.setTitle
    const closeHandler = Props.closeHandler
    const handleCloseForgotPassword = Props.handleCloseForgotPassword
    const currentStoreData = useSelector(currentStoreSelector);
    const widgetName = getDisplayName(ForgotPasswordLayoutPro);
    const { t } = useTranslation();
    const { mySite } = useSite();
    const isB2B: boolean = mySite?.isB2B;
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));
    const [showStep1, setShowStep1] = useState<boolean>(true);
    const [showStep2, setShowStep2] = useState<boolean>(false);
    const [showStep3, setShowStep3] = useState<boolean>(false);
    const [showStep4, setShowStep4] = useState<boolean>(false);
    const [showStep5, setShowStep5] = useState<boolean>(false);
    const [email, setEmail] = useState<string>(EMPTY_STRING);
    const [loginEmailError, setLoginEmailError] = useState<boolean>(false);
    const [loginError, setLoginError] = useState<boolean>(false);
    const emailLabel = t("SignIn.Label.Email");
    const newPasswordLabel = t("ResetPassword.NewPasswordLabel");

    const storeConfData = useSelector(onlineStoreRdc);

    const [logonInputProps, setLogonInputProps] = useState<any>({});
    const [canSubmitStep1, setCanSubmitStep1] = useState<boolean>(false);
    const [canSubmitStep2, setCanSubmitStep2] = useState<boolean>(false);
    const [canSubmitStep3, setCanSubmitStep3] = useState<boolean>(false);
    const [errorMessageKey, setErrorMessageKey] = useState<string>(EMPTY_STRING);

    const [associatedAccounts, setAssociatedAccounts] = useState<AssociatedAccounts[]>([]);
    const [logonPassword, setLogonPassword] = useState<string>(EMPTY_STRING);
    const [associatedAccountSelected, setAssociatedAccountSelected] = useState<string>(EMPTY_STRING);
    const [verificationToken, setVerificationToken] = useState<string>(EMPTY_STRING);
    const [errorCode, setErrorCode] = useState({ error: false, errorMsg: EMPTY_STRING });
    const [code, setCode] = useState(EMPTY_STRING);

    const [showInputError, setShowInputError] = useState({
        emailError: false,
        passwordError: false,
        passwordVerifyError: false,
        isInputPasswordEmpty: false,
    });

    const { emailError, isInputPasswordEmpty } = showInputError;

    const CancelToken = Axios.CancelToken;
    const cancels: Canceler[] = [];

    const payloadBase: any = {
        widget: widgetName,
        cancelToken: new CancelToken(function executor(c) {
            cancels.push(c);
        }),
    };

  useEffect(() => {
    if(!showStep2){
        setCanSubmitStep1(email?.length > 0 && addressUtil.validateEmail(email) ? false : true);
    }     
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);


  const handleEmail = (e) => {
      setShowInputError({
          ...showInputError,
          emailError: false
      })
      setLoginEmailError(false)
      setLoginError(false);
      setEmail(e.target.value);
      };
  
      const canContinueStep1 = (): boolean => {
      let flag: boolean = false;
      if (errorMessageKey) {
          if (
          email.trim() !== EMPTY_STRING 
          ) {
          const isEmail = addressUtil.validateEmail(email);
          flag = isEmail
          }
      } else {
          if (email.trim() !== EMPTY_STRING) {
          flag = addressUtil.validateEmail(email);
          }
      }
      return flag;
  };
    
  const handleSearchProUsers = async(event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isInputsValidated = canContinueStep1();        
    console.log("isInputsValidatedQQQ", isInputsValidated)
    console.log("email", email)
    console.log(" mySite?.storeID",  mySite?.storeID)
    const associatedAccountsResponse = await getAssociatedAccounts({InputEmail: email, storeID: mySite?.storeID});


    if(associatedAccountsResponse === "emailError"){
        setLoginEmailError(true)
        return
    }

    if(associatedAccountsResponse !== undefined && typeof associatedAccountsResponse === "object" ){
        setAssociatedAccounts(prevAccounts => {
        return [...prevAccounts, ...associatedAccountsResponse];
        });

    }

    if(isInputsValidated && !loginEmailError){
        setShowStep1(false)
        setShowStep2(true);
    }
  };
    
  const canContinue = (): boolean => {
    // let flag: boolean = false;
    // if (errorMessageKey) {
    //   if (
    //     email.trim() !== EMPTY_STRING &&
    //     logonPassword !== EMPTY_STRING &&
    //     logonPasswordNew !== EMPTY_STRING &&
    //     logonPasswordVerify !== EMPTY_STRING
    //   ) {
    //     const isEmail = isB2B ? true : addressUtil.validateEmail(email);
    //     flag = isEmail && logonPasswordNew.trim() === logonPasswordVerify.trim();
    //   }
    // } else {
    //   if (email.trim() !== EMPTY_STRING && logonPassword !== EMPTY_STRING) {
    //     flag = isB2B ? true : addressUtil.validateEmail(email);
    //   }
    // }
    // return flag;
    return true
  };
    
        const handleSendCode = (props: any, resendToken: boolean = false) => {
            // const isInputsValidated = canContinue();
            props.preventDefault();
        
        
            const payload = {
            body: {
                logonId: associatedAccountSelected ? associatedAccountSelected : email,
                logonPassword,
                // organizationId: "338002",
                // sfaIdWithOrgData: associatedAccountSelected,
        
                verificationToken: verificationToken,
                resendToken: resendToken,
            },
            query: {},
            physicalStoreId: currentStoreData?.currentStore?.stLocId,
            ...payloadBase,
            }

            console.log("associatedAccountSelectedQQQ", associatedAccountSelected)
            setShowStep1(false)
            setShowStep2(false)
            setShowStep3(true)
            // return 

            // props.preventDefault();
            // props.stopPropagation();
            const storeID = mySite.storeID;
            const parameters: any = {
              responseFormat: "application/json",
              storeId: storeID,
              body: {
                logonId: associatedAccountSelected,
                resetPassword: "true",
                challengeAnswer: "-",
                organizationId: "7333333333333510000",
                sfaIdWithOrgData: "96610003764@@@Luis Nuñez"
                // sfaIdWithOrgData: "699003"

              },
              ...payloadBase,
            };
            personService
              .updatePerson(parameters)
              .then((res: any) => {
                  console.log("res", res)
                if (res.status === OK) {
                //   handleSuccess();
                //   const successMessage = {
                //     key: "success-message.RESEND_VERIFICATION_CODE",
                //   };
                  //dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
                }
              })
              .catch((err) => {
                console.log("error")
                // handleSuccess();
              });



        }
    
      const handleAssociatedAccountSelectChange = (event:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAssociatedAccountSelected(event?.target?.value)
        setCanSubmitStep2(true)
      }
    
      const handleEmailEdit = () => {
        setEmail(EMPTY_STRING)
        setAssociatedAccountSelected(EMPTY_STRING)
        setShowStep1(true)
        setShowStep2(false)
        setCanSubmitStep2(false)
        setAssociatedAccounts([])
        setTitle("Recuperar contraseña")
      }
    
      const handleStep3Submit = async (e: any) => {
    
        e.preventDefault()
        e.stopPropagation();
        const storeID = mySite?.storeID;
        const parameters: any = {
            responseFormat: "application/json",
            storeId: storeID,
            body: {
            logonId: email,
            resetPassword: "true",
            challengeAnswer: "-",
            },
            ...payloadBase,
        };
        await personService
        .updatePerson(parameters)
        .then((response) => {
            console.log('response', response)
            if (response.status === 200 && response?.data?.resourceName && response?.data?.resourceName === 'person' && 
                response?.data?.userId && response?.data?.userId !== '') {
                // handleSuccess();
                // const successMessage = {
                // key: "success-message.RESEND_VERIFICATION_CODE",
                // };
                // dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
                // handleForgotPasswordStepe({step: 1, data: {email: email}})
            }
        })
        .catch((err) => {
            console.log('error', err)
        });
      }
    
      const handleStep4Submit = () => {
        console.log('')
      }
    
      const handleChangeCode = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) => {
        const valor: string | null = e.target.value.trim();
    
        setErrorCode({
          error: false,
          errorMsg: "",
        });
    
        const num = Number(valor);
        if (Number.isInteger(num)) {
          if (valor.length < 6) {
            if (valor !== code) {
              setCode(valor);
            }
          }
        }
      };

      const handleProCanContinueNewPassword = (data) => {
        console.log('dataQQQ', data)
        console.log('codeQQQ', code)
        if(data.isOk){
          setCanSubmitStep3(true)
        }else {
          setCanSubmitStep3(false)
        }
      }
    
      const associatedAccountItems = React.Children.toArray(
        associatedAccounts && associatedAccounts.length > 0 ? (
          associatedAccounts?.map((account, index) => 
    
          (
              <StyledMenuItem value={account?.value[1]} key={`${account?.value}${index}`} className={"styledSelect--menuItems-color"} fullWidth>
                <StyledTypography variant="bodyBaseline">{account?.value?.[0].replace('@@@', ' - ')}</StyledTypography>
              </StyledMenuItem>
          ))
      ) : (
        <StyledMenuItem value={"0"} className={"styledSelect--menuItems-color"} fullWidth>
          <StyledTypography variant="bodyBaseline">{t("AddressForm.Labels.Colony")}</StyledTypography>
        </StyledMenuItem>
      ))

      const associatedAccountItemsX = [1,2,3].map(() => (
        <StyledMenuItem value={"0"} className={"styledSelect--menuItems-color"} fullWidth>
          <StyledTypography variant="bodyBaseline">{t("AddressForm.Labels.Colony")}</StyledTypography>
        </StyledMenuItem>
      ))

      console.log("show1", showStep1)
      console.log("show2", showStep2)
      console.log("show3", showStep3)
      console.log("isTabletQQ", isTablet)

    return (
        <ProForgotPassContainer className="forgot-password-conatiner">
          <StyledGrid item xs={isTablet || isPopUp ? 12 : 4} className={`forgotpassword-form-container ${isPopUp ? "" : "padding-2"}`}>

          {!isPopUp ?
          <SpecialTitleHDM titleText={showStep3 ? "Nueva contraseña" : "Recuperar contraseña"} marginBottom={showStep3 ? 2 : 4} />
          :
          null
          }

          {showStep3 ?
          <>
          <StyledTypography variant="bodyBaseline" weight="regular" className={"bottom-margin-2"}>
            {t("ResetPassword.CreateNewPassword")}
          </StyledTypography>
          <StyledGrid item className={"bottom-margin-2 styled-step-image"}>
            <img
              src={storeConfData?.IMAGE_SERVER_HOSTNAME + "assets/img/reset-password/send-mail.svg"}
              alt={storeConfData?.IMAGE_SERVER_HOSTNAME + "assets/img/reset-password/send-mail.svg"}
              width={90}
              height={56}
              className="lazyload">
             </img>
          </StyledGrid>
          <StyledTypography variant="bodyBaseline" weight="regular" className={"bottom-margin-2"}>
            {t("ResetPassword.Subtitle")}
          </StyledTypography>
          <StyledTypography variant="bodySubText" weight="regular" className="color-gray200 bottom-margin-3 ">
            {t("ResetPassword.Reminder")}
          </StyledTypography>
          </>
          :
          null
          }
          {showStep1 || showStep2 ?
          <ProCustomStepperLabel 
            stepNumber={showStep1 ? 1 : 2} 
            sideText={t(`ForgotPassword.PRO.${showStep1 ? "StepOne" : "StepTwo"}`)}
          />
          :
          null
          }
          
          <form onSubmit={showStep1 ? handleSearchProUsers : showStep2 ? handleSendCode : showStep3 ? handleStep3Submit : handleStep4Submit } noValidate>
            <StyledTextField
              isB2B={isB2B}
              disabled={!showStep1 ? true : false}
              className="bottom-margin-2"
              variant="outlined"
              margin="normal"
              fullWidth
              name="email"
              autoComplete="email"
              label={emailLabel}
              autoFocus
              onChange={handleEmail}
              value={email}
              inputProps={logonInputProps}
              error={emailError || loginEmailError}
              onBlur={() =>
                setShowInputError((prevState): any => ({
                  ...prevState,
                  emailError: !addressUtil.validateEmail(email) ? true : false,
                }))
              }
              helperText={
                emailError ? t("SignIn.Msgs.InvalidFormat")
                : loginEmailError ? t("ForgotPassword.PRO.EmailNotFound") 
                : null
              }
              // helperText={!addressUtil.validateEmail(email) && !isB2B ? t("SignIn.Msgs.InvalidFormat") : EMPTY_STRING}
            />

            {associatedAccounts && associatedAccounts.length > 0 ?
              <>
                {console.log(associatedAccountSelected)}
                <div className="bottom-margin-2">
                    <StyledSelectHdm
                        label={t("SignInPage.ProLabels.AccountSelectDefaultLabel")}
                        name="associated-accounts"
  
                        handleChange={handleAssociatedAccountSelectChange}
                        value={associatedAccountSelected}
                        items={associatedAccountItems}
                        disabled={!showStep2}
                        // autoComplete="address-colony"
                        // helperText={
                        //     (errorColony && addressFormData.addressLine3 == EMPTY_STRING) || errorColony
                        //     ? t("AddressForm.Msgs.Required")
                        //     : ""
                        // }
                        // error={errorColony && addressFormData.addressLine3 == EMPTY_STRING}
                    />
                </div>
              </>
            : null}

            {showStep3 ?
            <StyledTextField
                className="bottom-margin-4"
                margin="normal"
                value={code}
                name="verificationCode"
                label={"Código de verificación"}
                onChange={handleChangeCode}
                inputProps={{ maxLength: 40 }}
                fullWidth
                error={errorCode.error}
                helperText={errorCode.errorMsg}
            />
            :
            null
            }

            {showStep3 ?
              <ChangePassword editPassword={false} handleEditPassword={handleProCanContinueNewPassword} isProForgotPasswprd={true} />
            :
            null
            }
  
            
          <StyledButton
            // testId="sign-in-submit"
            type="submit"
            color="primary"
            disabled={showStep1 ? canSubmitStep1 : showStep2 ? !canSubmitStep2 : (!canSubmitStep3 || code.trim().length === 0)}
            className={`login-process-button bottom-margin-4`}>
            {t(`ForgotPassword.PRO.ContinueButtonLabel${showStep1 ? "StepOne" : showStep2 ? "StepTwo" : showStep3 ? "StepThree" : "showStep4"}`)}
          </StyledButton>
  
          </form>

          {showStep1 ?
          <>
          <StyledGrid item xs={12}>
            <StyledGrid
              container
              alignItems="center"
              direction="row"
              justifyContent="center"
              className={"bottom-margin-2"}>
              <StyledGrid item>
                <StyledTypography variant="body1" component="span">
                  {t("ForgotPassword.AccountInfoRemember") + "  "}
                </StyledTypography>
                {isPopUp ?
                <StyledLinkTextSecondary variant="body1" color="secondary" component="span" onClick={() => handleCloseForgotPassword()} >
                {t("ForgotPassword.SignIn")}
                </StyledLinkTextSecondary>
                :
                <StyledTypography variant="body1" component="span">
                  <StyledLink to={ROUTES.SIGNIN}>{t("ForgotPassword.SignIn")}</StyledLink>
                </StyledTypography>
                }
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
          <StyledGrid item xs={12}>
            <Divider />
            <StyledGrid
              container
              alignItems="left"
              direction="row"
              justifyContent="left"
              className={"vertical-margin-2"}>
              <StyledGrid item>
                <StyledTypography variant="bodySubText" component="span">
                  {t("ForgotPassword.PRO.forgotEmailText1")}
                </StyledTypography>
                <StyledTypography variant="bodySubText" component="span">
                  <a href={"tel:" + t("OrderConfirmation.DeliveryMethod.CustomerSupportPhone")}>
                  {" "}
                  {t("OrderConfirmation.DeliveryMethod.CustomerSupportPhone")}
                  </a>
                </StyledTypography>
                <StyledTypography variant="bodySubText" component="span"> {t("ForgotPassword.PRO.forgotEmailText2")}
                </StyledTypography>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
          </>
          :
          null
          }

          {showStep2 ?
          <StyledBox className="text-align-center">
            <StyledTypography variant="bodyBaseline" component="span" >
              <StyledLinkTextSecondary color="secondary" className="none-underline" onClick={handleEmailEdit}>
                {t("ForgotPassword.PRO.EmailEdit")}
              </StyledLinkTextSecondary>
            </StyledTypography>
          </StyledBox>
          :
          null
          }
          
          {showStep3 ?
          <>
          <StyledBox className="text-align-center">
            <StyledTypography variant="bodyBaseline">¿No recibiste el código de verificación?</StyledTypography>
          </StyledBox>
  
          <StyledBox mt={2}>
            <StyledButton
              color="primary"
              variant="outlined"
              size="small"
              className="top-margin-2"
              type="submit"
              onClick={handleStep3Submit}
              // disabled={!canSave()}
            >
              Reenviar código
            </StyledButton>
          </StyledBox>
          </>
          :
          null
          }


          {/* <ResetPassword
            email={email}
            setEmail={setEmail}
            resendCode={handleSendCode}
            // isDrawer={props.isDrawer}
            // isPopUp={props.isPopUp}
            // setTitle={setTitle}
            // forgotPasswordState={forgotPasswordState}
            // handleRememberPassword={handleRememberPassword}
          /> */}
  
  
          </StyledGrid>
          {!isPopUp ?
          <StyledGrid item xs={isTablet ? 12 : 8} className="forgotpassword-espot-container">
            <EspotHdm espotName="PRO_ForgotPass_AdditionalInfo" />
          </StyledGrid>
          :
          null
          }
  
        </ProForgotPassContainer>
      )
}


export default ForgotPasswordLayoutPro