/***
 *  DataLayer function utilities
 *  @author TCS Developer team
 */
import Axios, { Canceler } from "axios";
import { PAYMENT } from "../../../constants/order";
import { getCookieValue } from "../conversion-api/cookieHandler";
import jsonStoreDetails from "../styled-store-selector/json-stores-info/StoreLocatorStateCitiesList.json";
import { getPromoicons } from "../../../hdm/functions";
import { logEvent, logEventWithValues } from "../../../hdm/functions/utils";

const isMobileApp = window.navigator.userAgent.includes("THDMXAPP");

//Return current page visited by the user
export const currentDomain = () => {
  return window.location.href;
};

//Return the path
export const currentPath = () => {
  return window.location.pathname;
};

//Return the weekday
export const userWeekday = () => {
  const date = new Date();
  return date.getDay();
};

//Return Monthday
export const userMonthday = () => {
  const date = new Date();
  return date.getDate();
};

//Return hours
export const userTime = () => {
  const date = new Date();
  const hour = date.getHours();
  return hour;
};

//Return the browser
export const userBrowser = (userAgent) => {
  switch (true) {
    case userAgent.indexOf("Edg") != -1:
      return "Microsoft Edge";
    case userAgent.indexOf("Chrome") != -1:
      return "Google Chrome";
    case userAgent.indexOf("Firefox") != -1:
      return "Mozilla Firefox";
    case userAgent.indexOf("Opera") != -1:
      return "Opera";
    case userAgent.indexOf("Safari") != -1:
      return "Apple Safari";
    default:
      return "NA";
  }
};

//Return if its a mobile or Desktop
export const userDevice = (userAgent) => {
  if (userAgent.indexOf("Mobile") != -1) {
    return "Mobile";
  } else {
    return "Desktop";
  }
};

//Return user OS
export const userOS = (userAgent) => {
  switch (true) {
    case userAgent.indexOf("Win") != -1:
      return "Windows";
    case userAgent.indexOf("Mac") != -1:
      return "MacOS";
    case userAgent.indexOf("Linux") != -1:
      return "Linux";
    case userAgent.indexOf("iPhone") != -1 || userAgent.indexOf("iPad") != -1 || userAgent.indexOf("iPod") != -1:
      return "iOS";
    case userAgent.indexOf("Android") != -1:
      return "Android";
    default:
      return "NA";
  }
};

//Return previous page visited by the user
export const paidMediaSource = () => {
  const prevUrl = document.referrer;
  if (prevUrl && !prevUrl.includes("")) {
    return prevUrl;
  } else {
    return "NA";
  }
};

export const validateUserId = (uId, statusLoggin) => {
  if (uId && statusLoggin) {
    return uId;
  } else {
    return "NA";
  }
};

function formatNumberWithTwoDecimals(value) {
  return parseFloat(Number(value).toFixed(2));
}

//Return information about the store, it means the city and storeName
export const storeDetailsStruct = (storeDetails) => {
  let actualShopState = "NA";
  if (storeDetails?.stLocName) {
    actualShopState = storeDetails?.stLocName;
  }

  let actualShopName = "NA";
  if (storeDetails?.storeName) {
    actualShopName = storeDetails?.storeName;
  }

  let actualShopCity = "NA";
  if (storeDetails?.shortName) {
    actualShopCity = storeDetails?.shortName;
  }

  return [actualShopCity, actualShopState, actualShopName];
};

export const getStoreMapSite = () => {
  const storeId = getCookieValue("WC_DefaultStore_10351");
  const cityList = jsonStoreDetails.formScheduleObject;

  let storeName = "NA";
  let storeCity = "NA";
  let storeState = "NA";

  cityList.forEach((item) => {
    const store = item.stores.find((store) => store.stLocId === storeId);
    if (store) {
      storeName = store.storeName;
      storeCity = store.shortName;
      storeState = store.stLocName;
    }
  });

  return [storeCity, storeState, storeName];
};

//This function push to the dataLayer object pageView data
export const pageViewEventDL = (previousURL, isLogin, userId, storeDetails) => {
  const userAgent = navigator.userAgent;
  const [actualShopState, actualShopName] = storeDetailsStruct(storeDetails);

  window["dataLayer"].push({
    event: "evPageViewInfo",
    ecommerce: {
      page_actual_domain: window.location.href,
      page_user_came: previousURL,
      page_actual_path: currentPath(),
      user_weekday: userWeekday(),
      user_monthday: userMonthday(),
      user_time: userTime(),
      user_browser: userBrowser(userAgent),
      user_device: userDevice(userAgent),
      user_os: userOS(userAgent),
      paid_media_source: paidMediaSource(),
      paid_media_medium: "Organic",
      paid_media_campaign: "NA",
      paid_media_content: "NA",
      paid_media_term: "NA",
      paid_media_gclid: "NA",
      paid_media_fbclid: "NA",
      user_status_login: isLogin, //TODO
      user_id: userId, //TODO
      actual_shop_city: "NA", //TODO
      actual_shop_state: actualShopState,
      actual_shop_name: actualShopName,
    },
  });
};

/***
 * View Item List
 */
export const dataLayerViewItemListEvent = async (
  DLProductList,
  contractId,
  storeSelected,
  currentMarketId,
  itemAffiliation,
  storeId,
  isB2B
) => {
  const partNumberList: string[] = [];
  const catGrpList: string[] = [];
  let partNumbers: string = "";
  let catGrpIds: string = "";
  let index = 1;
  const catGrpIdDetails = new Map();
  const partNumberCatGrpIds = new Map();
  const productDetails = new Map();
  const promoSelected = getPromotionDetails();
  let isTracked = false;
  try {
    for (const item of DLProductList) {
      if (item && item?.partNumber) {
        partNumberList.push(item.partNumber);
        const prodDetails = new Map();
        prodDetails.set("quantity", 1);
        prodDetails.set("index", index);
        index += 1;
        productDetails.set(item.partNumber, prodDetails);
      }
    }

    if (partNumberList && partNumberList.length >= 1) {
      const url = window.location.pathname.toLowerCase();
      const plpProm = url.split("/");
      if (promoSelected != null) {

        if (promoSelected.key_words.some(p => p.toLowerCase().includes(plpProm[plpProm.length - 1]))) {
          updateProductsPromo(partNumberList);
          isTracked = true;
        }

      }
      for (const partNum of partNumberList) {
        if (partNum) {
          if (partNumbers === "") {
            partNumbers = "partNumber=" + partNum;
          } else {
            partNumbers = partNumbers + "&partNumber=" + partNum;
          }
        }
      }
    }
    await Axios.get<any>(
      `/search/resources/api/v2/products?storeId=${storeId}&physicalStoreId=${storeSelected}&contractId=${contractId}&langId=-5&${partNumbers}`
    ).then((response) => {
      if (response && response.status === 200 && response?.data?.contents) {
        for (const dataResponse of response.data.contents) {
          if (dataResponse && dataResponse.parentCatalogGroupID) {
            let catalogGroupIDs: any;
            if (Array.isArray(dataResponse.parentCatalogGroupID) && dataResponse.parentCatalogGroupID[0]) {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.filter((item) => item.split("/").length === 4);
              if (catalogGroupIDs && catalogGroupIDs.length > 0) {
                catalogGroupIDs = catalogGroupIDs[0].split("/");
              } else {
                catalogGroupIDs = dataResponse.parentCatalogGroupID[0].split("/");
              }
            } else {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.split("/");
            }

            const catGrpIdsList: string[] = [];

            let prodDetails = new Map();
            if (productDetails.get(dataResponse.partNumber) != null) {
              prodDetails = productDetails.get(dataResponse.partNumber);
            }

            for (const price of dataResponse.price) {
              if (price.usage === "Offer" && price.value !== "") {
                prodDetails.set("price", price.value);
              }
            }

            const offerPrice = dataResponse.price.filter((precio) => precio.usage === "Offer")[0];
            const displayPrice = dataResponse.price.filter((precio) => precio.usage === "Display")[0];
            let nlpDiscount: any;
            if (
              offerPrice?.value &&
              displayPrice?.value &&
              parseFloat(displayPrice.value) > parseFloat(offerPrice.value)
            ) {
              nlpDiscount = parseFloat(displayPrice.value) - parseFloat(offerPrice.value);
            }
            prodDetails.set("discount", nlpDiscount);
            prodDetails.set("item_variant", getColorAttrDetails(dataResponse.attributes));
            const prodPromoDetails = getProductPromoDetails(
              dataResponse.attributes,
              isB2B,
              nlpDiscount !== null && nlpDiscount > 0 ? true : false,
              storeSelected,
              currentMarketId
            );
            let promotionId: string = "";
            let promotionName: string = "";
            prodPromoDetails.forEach(function (value, key) {
              if (promotionId === "") {
                promotionId = key;
              } else {
                promotionId = promotionId + ", " + key;
              }
              if (promotionName === "") {
                promotionName = value;
              } else {
                promotionName = promotionName + ", " + value;
              }
            });


            if (promoSelected != null && isTracked == true) {

              prodDetails.set("promotion_id", promoSelected.promotion_id);
              prodDetails.set("promotion_name", promoSelected.promotion_name);
              prodDetails.set("creative_slot", promoSelected.creative_slot);
              prodDetails.set("creative_name", promoSelected.creative_name);
            } else {
              prodDetails.set("promotion_id", "NA");
              prodDetails.set("promotion_name", "NA");
            }
            prodDetails.set("promo_thd", promotionId);

            prodDetails.set("name", dataResponse.name);
            prodDetails.set("brand", dataResponse.manufacturer);
            if (catalogGroupIDs && catalogGroupIDs.length >= 1) {
              for (const catalogGroupID of catalogGroupIDs) {
                if (catalogGroupID) {
                  catGrpList.push(catalogGroupID);
                  catGrpIdsList.push(catalogGroupID);
                }
              }
            }
            partNumberCatGrpIds.set(dataResponse.partNumber, catGrpIdsList);
            productDetails.set(dataResponse.partNumber, prodDetails);
          }
        }
      }
    });

    if (catGrpList) {
      if (catGrpList && catGrpList.length >= 1) {
        for (const catalogGroupID of catGrpList) {
          if (catalogGroupID) {
            if (catGrpIds === "") {
              catGrpIds = "id=" + catalogGroupID;
            } else {
              catGrpIds = catGrpIds + "&id=" + catalogGroupID;
            }
          }
        }
      }
      await Axios.get<any>(
        `/search/resources/api/v2/categories?storeId=${storeId}&contractId=${contractId}&langId=-5&${catGrpIds}`
      ).then((response) => {
        if (response && response.status === 200 && response?.data?.contents && response.data.contents.length > 0) {
          for (const catalogGroupDetails of response.data.contents) {
            if (catalogGroupDetails) {
              catGrpIdDetails.set(catalogGroupDetails.uniqueID, catalogGroupDetails.name);
            }
          }
        }
      });
    }

    const products: any = [];
    for (const partNum of partNumberList) {
      const prdDtls = productDetails.get(partNum);
      const prdCatgrpIds = partNumberCatGrpIds.get(partNum);
      let itemCategory: string = "";
      let itemCategory1: string = "";
      let itemCategory2: string = "";
      let count: number = 0;
      if (prdCatgrpIds && prdCatgrpIds.length >= 1) {
        for (const catalogGroupId of prdCatgrpIds) {
          if (catalogGroupId) {
            const catgry: string =
              catGrpIdDetails.get(catalogGroupId) === null || catGrpIdDetails.get(catalogGroupId) === undefined
                ? ""
                : catGrpIdDetails.get(catalogGroupId);
            if (count === 0) {
              itemCategory = catgry;
            } else if (count === 1) {
              itemCategory1 = catgry;
            } else if (count === 2) {
              itemCategory2 = catgry;
            }
            count = count + 1;
          }
        }
      }
      const productObj = {
        item_name: prdDtls.get("name") === null || prdDtls.get("name") === undefined ? "NA" : prdDtls.get("name"),
        item_id: partNum,
        item_affiliation:
          itemAffiliation === null || itemAffiliation === undefined
            ? !isB2B
              ? "The Home Depot"
              : "The Home Depot PRO"
            : itemAffiliation,
        coupon: "NA",
        creative_name: validateAttribute(prdDtls.get("creative_name")),
        creative_slot: validateAttribute(prdDtls.get("creative_slot")),
        discount:
          prdDtls.get("discount") === null || prdDtls.get("discount") === undefined
            ? 0
            : formatNumberWithTwoDecimals(prdDtls.get("discount")),
        price:
          prdDtls.get("price") === null || prdDtls.get("price") === undefined
            ? "NA"
            : formatNumberWithTwoDecimals(prdDtls.get("price")),
        item_brand: prdDtls.get("brand") === null || prdDtls.get("brand") === undefined ? "NA" : prdDtls.get("brand"),
        item_category: validateAttribute(itemCategory),
        item_category2: validateAttribute(itemCategory1),
        item_category3: validateAttribute(itemCategory2),
        item_variant: prdDtls.get("item_variant"),
        item_list_name: "NA",
        item_list_id: "NA",
        quantity:
          prdDtls.get("quantity") === null || prdDtls.get("quantity") === undefined ? "NA" : prdDtls.get("quantity"),
        promotion_name: validateAttribute(prdDtls.get("promotion_name")),
        promotion_id: validateAttribute(prdDtls.get("promotion_id")),
        promo_thd: validateAttribute(prdDtls.get("promo_thd")),
        index: prdDtls.get("index") === null || prdDtls.get("index") === undefined ? "NA" : prdDtls.get("index"),
      };
      products.push(productObj);
    }
    window["dataLayer"].push({ ecommerce: null }); // Clear the previous ecommerce object.
    window["dataLayer"].push({
      event: "view_item_list",
      ecommerce: {
        items: products,
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

//will return NA if var dont have a value
const validateAttribute = (attribute) => {
  if (attribute && attribute != "") {
    return attribute;
  } else {
    return "NA";
  }
};

const getPriceDL = (item) => {
  let value = "";

  item.forEach((element) => {
    if (element?.usage == "Offer") {
      value = element?.value;
    }
  });
  return value;
};

export const getItemDetails = async (contract, partNumber, store, storeId) => {
  let itemName: string = "";
  let itemId: string = "";
  let itemPrice: string = "";
  let itemBrand: string = "";
  let itemCategory: string = "";
  let itemCategory1: string = "";
  let itemCategory2: string = "";
  const itemQuantity: string = "1";
  let catGrpIds: string = "";
  try {
    await Axios.get<any>(
      `/search/resources/api/v2/products?storeId=${storeId}&physicalStoreId=${store}&contractId=${contract}&langId=-5&partNumber=${partNumber}`
    ).then((response) => {
      if (response && response.status === 200 && response?.data?.contents) {
        const dataResponse = response.data.contents[0];
        itemName = dataResponse.name;
        itemId = dataResponse.partNumber;
        itemBrand = dataResponse.manufacturer;
        for (const price of dataResponse.price) {
          if (price.usage === "Offer" && price.value !== "") {
            itemPrice = price.value;
          }
        }
        if (dataResponse && dataResponse.parentCatalogGroupID) {
          let catalogGroupIDs: any;
          if (Array.isArray(dataResponse.parentCatalogGroupID) && dataResponse.parentCatalogGroupID[0]) {
            catalogGroupIDs = dataResponse.parentCatalogGroupID.filter((item) => item.split("/").length === 4);
            if (catalogGroupIDs && catalogGroupIDs.length > 0) {
              catalogGroupIDs = catalogGroupIDs[0].split("/");
            } else {
              catalogGroupIDs = dataResponse.parentCatalogGroupID[0].split("/");
            }
          } else {
            catalogGroupIDs = dataResponse.parentCatalogGroupID.split("/");
          }

          if (catalogGroupIDs && catalogGroupIDs.length >= 1) {
            for (const catalogGroupID of catalogGroupIDs) {
              if (catalogGroupID) {
                if (catGrpIds === "") {
                  catGrpIds = "id=" + catalogGroupID;
                } else {
                  catGrpIds = catGrpIds + "&id=" + catalogGroupID;
                }
              }
            }
          }
        }
      }
    });
    if (catGrpIds) {
      await Axios.get<any>(
        `/search/resources/api/v2/categories?storeId=${storeId}&contractId=${contract}&langId=-5&${catGrpIds}`
      ).then((response) => {
        if (response && response.status === 200 && response?.data?.contents && response.data.contents.length > 0) {
          let count: number = 0;
          for (const catalogGroupDetails of response.data.contents) {
            if (catalogGroupDetails) {
              if (count === 0) {
                itemCategory = catalogGroupDetails.name;
              } else if (count === 1) {
                itemCategory1 = catalogGroupDetails.name;
              } else if (count === 2) {
                itemCategory2 = catalogGroupDetails.name;
              }
              count = count + 1;
            }
          }
        }
      });
    }
  } catch (error) {
    console.log("error", error);
  }
  return [itemName, itemPrice, itemBrand, itemCategory, itemCategory1, itemCategory2];
};

/** Remove_from_Cart */
export const triggerRemoveFromCartDL = async (
  partNumber,
  contractId,
  quantityRemoved,
  storeId,
  physicalStoreId,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
      const [itemsTotal, itmsList] = await retrieveProductsDetails(
    storeId,
    physicalStoreId,
    contractId,
    partNumber,
        quantityRemoved * -1,
    itemAffiliation,
    currentMarketId,
    isB2B
      );
      window["dataLayer"].push({ ecommerce: null });
      if(isMobileApp){
        try {
          logEventWithValues("remove_from_cart", {
            currency: "MXN",
            value: itemsTotal,
            app_thd: !isB2B ? "DIY" : "PRO",
            items: itmsList,
          });
        } catch (e) {
          console.log(e);
        }
      }else {
        window["dataLayer"].push({
          event: "remove_from_cart",
          ecommerce: {
            currency: "MXN",
            value: itemsTotal,
            items: itmsList,
          },
        });
      }
};

const DLItemDetails = async (
  partNumber,
  contract,
  quantityRemoved,
  storeSelected,
  storeId,
  itemAffiliation,
  marketId
) => {
  const items: any[] = [];

  const [itemName, itemPrice, itemBrand, itemCategory, itemCategory1, itemCategory2] = await getItemDetails(
    contract,
    partNumber,
    storeSelected,
    storeId
  );
  let brandItem = "NA";
  if (itemBrand) {
    brandItem = itemBrand;
  }

  let price = 0;
  if (itemPrice) {
    price = parseFloat(parseFloat(itemPrice).toFixed(2));
  }

  const item = {
    item_name: itemName,
    item_id: partNumber,
    price: price,
    item_brand: brandItem,
    item_category: itemCategory.length > 0 ? itemCategory : "NA",
    item_category2: itemCategory1.length > 0 ? itemCategory1 : "NA",
    item_category3: itemCategory2.length > 0 ? itemCategory2 : "NA",
    item_list_name: "NA",
    item_list_id: "NA",
    index: 1,
    quantity: quantityRemoved * -1,
  };
  items.push(item);

  return items;
};

//AddShippingInfo details:
export const dataLayerAddShippingInfoEvent = async (
  contractId,
  orderId,
  storeId,
  physicalStoreId,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
  const [orderTotal, totalShippingCharge, paymentMthd, itmsList] = await retrieveOrderProductsDetails(
    storeId,
    physicalStoreId,
    contractId,
    orderId,
    true,
    itemAffiliation,
    currentMarketId,
    isB2B
  );
  window["dataLayer"].push({ ecommerce: null });
  if(isMobileApp){
    try {
      logEventWithValues("add_shipping_info", {
        currency: "MXN",
        value: orderTotal,
        shipping_tier: "NA",
        app_thd: !isB2B ? "DIY" : "PRO",
        items: itmsList,
      });
    } catch (e) {
      console.log(e);
    }
  }else {
    window["dataLayer"].push({
      event: "add_shipping_info",
      ecommerce: {
        shipping_tier: "NA",
        value: orderTotal,
        currency: "MXN",
        items: itmsList,
      },
    });
  }
};

//view_item
export const triggerViewItemDL = async (
  storeId,
  physicalStoreId,
  contractId,
  partNumber,
  itemQty,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
      const [itemsTotal, itmsList] = await retrieveProductsDetails(
    storeId,
    physicalStoreId,
    contractId,
    partNumber,
    itemQty,
    itemAffiliation,
    currentMarketId,
    isB2B
      );

      window["dataLayer"].push({ ecommerce: null });
      if(isMobileApp){
        try {
          logEventWithValues("view_item", {
            currency: "MXN",
            value: itemsTotal,
            items: itmsList,
            app_thd: !isB2B ? "DIY" : "PRO"
          });
        } catch (e) {
          console.log(e);
        }
      }
      else {
        window["dataLayer"].push({
          event: "view_item",
          ecommerce: {
            currency: "MXN",
            value: itemsTotal,
            items: itmsList,
          },
        });
  
      }
};

//select_item
export const triggerSelectItemDL = (
  storeId,
  physicalStoreId,
  contractId,
  partNumber,
  itemQty,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
  retrieveSelectItemProductsDetails(
    storeId,
    physicalStoreId,
    contractId,
    partNumber,
    itemQty,
    itemAffiliation,
    currentMarketId,
    isB2B
  )
    .then((itmsList) => {
      let priceVal = 0;
      itmsList.map(function (element, index) {
        if (element?.price) {
          priceVal = priceVal + parseFloat(element?.price);
        }
      });

      window["dataLayer"].push({ ecommerce: null });
      window["dataLayer"].push({
        event: "select_item",
        ecommerce: {
          currency: "MXN",
          value: priceVal,
          items: itmsList,
        },
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

/** Add To Wishlist */
export const triggerAddToWishListDL = async (
  storeId,
  physicalStoreId,
  contractId,
  partNumber,
  itemQty,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
      const [itemsTotal, itmsList] = await retrieveProductsDetails(
    storeId,
    physicalStoreId,
    contractId,
    partNumber,
    itemQty,
    itemAffiliation,
    currentMarketId,
    isB2B
      );

      window["dataLayer"].push({
        event: "add_to_wishlist",
        ecommerce: {
          currency: "MXN",
          value: itemsTotal,
          items: itmsList,
        },
      });
};

//begin_checkout
export const triggerBeginToCheckoutDL = async (
  storeId,
  physicalStoreId,
  contractId,
  orderId,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
  const [orderTotal, totalShippingCharge, paymentMthd, itmsList] = await retrieveOrderProductsDetails(
    storeId,
    physicalStoreId,
    contractId,
    orderId,
    false,
    itemAffiliation,
    currentMarketId,
    isB2B
  );
  window["dataLayer"].push({ ecommerce: null });
  if(isMobileApp){
    try {
      logEventWithValues("begin_checkout", {
        currency: "MXN",
        value: orderTotal,
        app_thd: !isB2B ? "DIY" : "PRO",
        items: itmsList,
      });
    } catch (e) {
      console.log(e);
    }
  }else {
    window["dataLayer"].push({
      event: "begin_checkout",
      ecommerce: {
        currency: "MXN",
        value: orderTotal,
        items: itmsList,
      },
    });
  }
};

//view_cart
export const triggerViewCartDL = async (
  storeId,
  physicalStoreId,
  contractId,
  orderId,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
  const [orderTotal, itmsList] = await retrieveViewCartProductsDetails(
    storeId,
    physicalStoreId,
    contractId,
    orderId,
    itemAffiliation,
    currentMarketId,
    isB2B
  );
  window["dataLayer"].push({ ecommerce: null });
  if(isMobileApp){
    try {
      logEventWithValues("view_cart", {
        currency: "MXN",
        value: orderTotal,
        app_thd: !isB2B ? "DIY" : "PRO",
        items: itmsList,
      });
    } catch (e) {
      console.log(e);
    }
  }
  else {window["dataLayer"].push({
    event: "view_cart",
    ecommerce: {
      currency: "MXN",
      value: orderTotal,
      items: itmsList,
    },
  });
}
};

//Add_Payment_Info
export const triggerAddPaymentInfoDLEvent = async (
  storeId,
  physicalStoreId,
  contractId,
  orderId,
  paymentMethod,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
  const [orderTotal, totalShippingCharge, paymentMthd, itmsList, totalTax] = await retrieveProductsDetailsAddPaymentPurchaseEvent(
    storeId,
    physicalStoreId,
    contractId,
    orderId,
    false,
    itemAffiliation,
    currentMarketId,
    isB2B
  );
  window["dataLayer"].push({ ecommerce: null });
  if(isMobileApp){
    try {
      logEventWithValues("add_payment_info", {
        currency: "MXN",
        value: orderTotal,
        payment_type: paymentMethod,
        app_thd: !isB2B ? "DIY" : "PRO",
        items: itmsList,
      });
    } catch (e) {
      console.log(e);
    }
  }else {
    window["dataLayer"].push({
      event: "add_payment_info",
      ecommerce: {
        currency: "MXN",
        value: orderTotal,
        payment_type: paymentMethod,
        items: itmsList,
      },
    });
  }
};

//Add_Payment_Info
export const triggerPurchaseDLEvent = async (
  storeId,
  physicalStoreId,
  contractId,
  orderId,
  paymentMethod,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
  const [orderTotal, totalShippingCharge, paymentMthd, itmsList, totalTax] = await retrieveProductsDetailsAddPaymentPurchaseEvent(
    storeId,
    physicalStoreId,
    contractId,
    orderId,
    true,
    itemAffiliation,
    currentMarketId,
    isB2B
  );
  window["dataLayer"].push({ ecommerce: null });
  if(isMobileApp){
    try {
      logEventWithValues("purchase", {
        currency: "MXN",
        value: orderTotal,
        transaction_id: orderId,
        affiliation: "The Home Depot",
        tax: totalTax,
        shipping: totalShippingCharge,
        payment_type: paymentMthd,
        app_thd: !isB2B ? "DIY" : "PRO",
        items: itmsList,
      });
    } catch (e) {
      console.log(e);
    }
  }else {
    window["dataLayer"].push({
      event: "purchase",
      ecommerce: {
        currency: "MXN",
        value: orderTotal,
        tax: totalTax,
        shipping: totalShippingCharge,
        transaction_id: orderId,
        payment_type: paymentMthd,
        items: itmsList,
      },
    });
  }
};

/** Add To Cart */
export const triggerAddToCartDL = async (
  storeId,
  physicalStoreId,
  contractId,
  partNumber,
  itemQty,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
      const [itemsTotal, itmsList] = await retrieveProductsDetails(
    storeId,
    physicalStoreId,
    contractId,
    partNumber,
    itemQty,
    itemAffiliation,
    currentMarketId,
    isB2B
      );
      const products: any = [];
      let oiIndex: number = 0;
      itmsList.map(function (element, index) {
        oiIndex = oiIndex + 1;
        if (element?.promotion_name != "NA") {
          const newSKUPromo = {
            SKU: element?.item_id,
            promotion_name: element?.promotion_name,
            promotion_id: element?.promotion_id,
            creative_name: element?.creative_name,
            creative_slot: element?.creative_slot,
          }
          addCartPromo(newSKUPromo);
        }

        const productObj = {
          item_name: element?.item_name,
          item_id: element?.item_id,
          price: element?.price,
          item_brand: element?.item_brand,
          item_category: validateAttribute(element?.item_category),
          item_category2: validateAttribute(element?.item_category2),
          item_category3: validateAttribute(element?.item_category3),
          quantity: element?.quantity,
          item_list_name: "NA",
          item_list_id: "NA",
          index: oiIndex,
          item_affiliation:
            itemAffiliation === null || itemAffiliation === undefined
              ? !isB2B
                ? "The Home Depot"
                : "The Home Depot PRO"
              : itemAffiliation,
          coupon: "NA",
          creative_name: element?.creative_name,
          creative_slot: element?.creative_slot,
          discount: element?.discount,
          item_variant: element?.item_variant,
          promotion_name: element?.promotion_name,
          promotion_id: element?.promotion_id,
          promo_thd: element?.promo_thd,
        };
        products.push(productObj);
      });

      window["dataLayer"].push({ ecommerce: null });
      if(isMobileApp){
        try {
          logEventWithValues("add_to_cart", {
            currency: "MXN",
            value: itemsTotal,
            app_thd: !isB2B ? "DIY" : "PRO",
            items: products,
          });
        } catch (e) {
          console.log(e);
        }
      }else {
        window["dataLayer"].push({
          event: "add_to_cart",
          ecommerce: {
            currency: "MXN",
            value: itemsTotal,
            items: products,
          },
        });
      }
};

export const retrieveProductsDetails = async (
  storeId,
  physicalStoreId,
  contractId,
  partNumber,
  itemQty,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
  const items: any = [];
  let itemName: string = "";
  let itemId: string = "";
  let itemPrice: string = "";
  let itemBrand: string = "";
  let itemQuantity: number = 0;
  let itemCategory: string = "";
  let itemCategory1: string = "";
  let itemCategory2: string = "";
  let catGrpIds: string = "";
  const catGrpIdDetails = new Map();
  let catalogGroupIDs: any;
  let partNumbers: string = "";
  const partNumberCatGrpIds = new Map();
  const productDetails = new Map();

  if (Array.isArray(partNumber)) {
    let count: number = 0;
    for (const partNum of partNumber) {
      if (partNum) {
        if (partNumbers === "") {
          partNumbers = "partNumber=" + partNum;
        } else {
          partNumbers = partNumbers + "&partNumber=" + partNum;
        }
        const prodDetails = new Map();
        prodDetails.set("quantity", Number(itemQty[count]));
        productDetails.set(partNum, prodDetails);
      }
      count = count + 1;
    }
  } else {
    partNumbers = "partNumber=" + partNumber;
    const prodDetails = new Map();
    prodDetails.set("quantity", Number(itemQty));
    productDetails.set(partNumber, prodDetails);
  }

  try {
    await Axios.get<any>(
      `/search/resources/api/v2/products?storeId=${storeId}&physicalStoreId=${physicalStoreId}&contractId=${contractId}&langId=-5&${partNumbers}`
    ).then((response) => {
      if (response && response.status === 200 && response?.data?.contents) {
        for (const dataResponse of response.data.contents) {
          let prodDetails = new Map();
          if (productDetails.get(dataResponse.partNumber) != null) {
            prodDetails = productDetails.get(dataResponse.partNumber);
          }
          prodDetails.set("name", dataResponse.name);
          prodDetails.set("brand", dataResponse.manufacturer);
          for (const price of dataResponse.price) {
            if (price.usage === "Offer" && price.value !== "") {
              prodDetails.set("price", price.value);
            }
          }

          const offerPrice = dataResponse.price.filter((precio) => precio.usage === "Offer")[0];
          const displayPrice = dataResponse.price.filter((precio) => precio.usage === "Display")[0];
          let nlpDiscount: any;
          if (
            offerPrice?.value &&
            displayPrice?.value &&
            parseFloat(displayPrice.value) > parseFloat(offerPrice.value)
          ) {
            nlpDiscount = parseFloat(displayPrice.value) - parseFloat(offerPrice.value);
          }
          prodDetails.set("discount", nlpDiscount);
          prodDetails.set("item_variant", getColorAttrDetails(dataResponse.attributes));
          const prodPromoDetails = getProductPromoDetails(
            dataResponse.attributes,
            isB2B,
            nlpDiscount !== null && nlpDiscount > 0 ? true : false,
            physicalStoreId,
            currentMarketId
          );
          let promotionId: string = "";
          let promotionName: string = "";
          prodPromoDetails.forEach(function (value, key) {
            if (promotionId === "") {
              promotionId = key;
            } else {
              promotionId = promotionId + ", " + key;
            }
            if (promotionName === "") {
              promotionName = value;
            } else {
              promotionName = promotionName + ", " + value;
            }
          });

          const promoSelected = getPromotionDetails();
          //is combo discount logic for childrens
          if(promotionId ==="C"){
            let childrenPartNumbers = "";
            
            if(dataResponse.components!=null){
              for(const children in dataResponse?.components){
                if(childrenPartNumbers === ""){
                  childrenPartNumbers = "partNumber="+dataResponse?.components[children].partNumber;
                }else{
                  childrenPartNumbers += "&partNumber="+dataResponse?.components[children].partNumber;
                }
              }
              Axios.get<any>(
                `/search/resources/api/v2/products?storeId=${storeId}&physicalStoreId=${physicalStoreId}&contractId=${contractId}&langId=-5&${childrenPartNumbers}`
              ).then((response) => {
                
                if (response && response.status === 200 && response?.data?.contents) {
                  let prices = 0;
                  for (const dataResponse of response.data.contents) {
                    
                    for (const price of dataResponse.price) {
                      if (price.usage === "Offer" && price.value !== "") {
                        prices += parseFloat(price.value);
                        
                      }
                    }
                   
                  }
                  nlpDiscount = prices - parseFloat(prodDetails.get("price"));
                  prodDetails.set("discount", nlpDiscount);
                  
                }
              });
            }
          }
          
          if (promoSelected != null) {

            const { exist, promotion_name, promotion_id, creative_name, creative_slot } = verifyPartNumber(dataResponse.partNumber);

            if (exist == true) {

              prodDetails.set("promotion_id", promotion_id);
              prodDetails.set("promotion_name", promotion_name);
              prodDetails.set("creative_name", creative_name);
              prodDetails.set("creative_slot", creative_slot);
            }
            else {
              prodDetails.set("promotion_id", "NA");
              prodDetails.set("promotion_name", "NA");
              prodDetails.set("creative_name", "NA");
              prodDetails.set("creative_slot", "NA");
            }
          } else {
            prodDetails.set("promotion_id", "NA");
            prodDetails.set("promotion_name", "NA");
            prodDetails.set("creative_name", "NA");
            prodDetails.set("creative_slot", "NA");
          }
          prodDetails.set("promo_thd", promotionId);

          if (dataResponse && dataResponse.parentCatalogGroupID) {
            if (Array.isArray(dataResponse.parentCatalogGroupID) && dataResponse.parentCatalogGroupID[0]) {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.filter((item) => item.split("/").length === 4);
              if (catalogGroupIDs && catalogGroupIDs.length > 0) {
                catalogGroupIDs = catalogGroupIDs[0].split("/");
              } else {
                catalogGroupIDs = dataResponse.parentCatalogGroupID[0].split("/");
              }
            } else {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.split("/");
            }
            if (catalogGroupIDs && catalogGroupIDs.length >= 1) {
              const catGrpIdsList: string[] = [];
              for (const catalogGroupID of catalogGroupIDs) {
                if (catalogGroupID) {
                  if (catGrpIds === "") {
                    catGrpIds = "id=" + catalogGroupID;
                  } else {
                    catGrpIds = catGrpIds + "&id=" + catalogGroupID;
                  }
                  catGrpIdsList.push(catalogGroupID);
                }
              }
              partNumberCatGrpIds.set(dataResponse.partNumber, catGrpIdsList);
            }
          }
          productDetails.set(dataResponse.partNumber, prodDetails);
        }
      }
    });
    if (catGrpIds) {
      await Axios.get<any>(
        `/search/resources/api/v2/categories?storeId=${storeId}&contractId=${contractId}&langId=-5&${catGrpIds}`
      ).then((response) => {
        if (response && response.status === 200 && response?.data?.contents && response.data.contents.length > 0) {
          for (const catalogGroupDetails of response.data.contents) {
            if (catalogGroupDetails) {
              catGrpIdDetails.set(catalogGroupDetails.uniqueID, catalogGroupDetails.name);
            }
          }
        }
      });
    }
  } catch (error) {
    console.log("error", error);
  }
  let itemsTotal: any = 0;

  if (Array.isArray(partNumber)) {
    for (const partNum of partNumber) {
      if (partNum) {
        itemName = "";
        itemId = "";
        itemPrice = "";
        itemBrand = "";
        itemQuantity = 0;
        itemCategory = "";
        itemCategory1 = "";
        itemCategory2 = "";

        let prodDetails = new Map();
        if (productDetails.get(partNum) != null) {
          prodDetails = productDetails.get(partNum);
          itemName = prodDetails.get("name");
          itemId = partNum;
          itemPrice = prodDetails.get("price");
          itemBrand = prodDetails.get("brand");
          itemQuantity = prodDetails.get("quantity");
        }
        const prdCatgrpIds = partNumberCatGrpIds.get(partNum);
        let count: number = 0;
        if (prdCatgrpIds && prdCatgrpIds.length >= 1) {
          for (const catalogGroupID of prdCatgrpIds) {
            if (catalogGroupID) {
              const catgryName: string =
                catGrpIdDetails.get(catalogGroupID) === null || catGrpIdDetails.get(catalogGroupID) === undefined
                  ? "NA"
                  : catGrpIdDetails.get(catalogGroupID);
              if (count === 0) {
                itemCategory = catgryName;
              } else if (count === 1) {
                itemCategory1 = catgryName;
              } else if (count === 2) {
                itemCategory2 = catgryName;
              }
              count = count + 1;
            }
          }
        }
        console.log("itemName", itemName);
        console.log("itemId", itemId);
        console.log("itemPrice", itemPrice);
        console.log("itemBrand", itemBrand);
        console.log("itemCategory", itemCategory);
        console.log("itemCategory1", itemCategory1);
        console.log("itemCategory2", itemCategory2);
        console.log("itemQuantity", itemQuantity);

        itemsTotal = itemsTotal
          + (itemPrice === null || itemPrice === undefined ? 0 : formatNumberWithTwoDecimals(itemPrice))
          * (!isNaN(itemQuantity) ? itemQuantity : 1);

        const item = {
          item_name: itemName === null || itemName === undefined ? "NA" : itemName,
          item_id: itemId,
          price: itemPrice === null || itemPrice === undefined ? "NA" : formatNumberWithTwoDecimals(itemPrice),
          item_brand: itemBrand === null || itemBrand === undefined ? "NA" : itemBrand,
          item_category: validateAttribute(itemCategory),
          item_category2: validateAttribute(itemCategory1),
          item_category3: validateAttribute(itemCategory2),
          quantity: !isNaN(itemQuantity) ? itemQuantity : 1,
          item_affiliation:
            itemAffiliation === null || itemAffiliation === undefined
              ? !isB2B
                ? "The Home Depot"
                : "The Home Depot PRO"
              : itemAffiliation,
          coupon: "NA",
          creative_name: validateAttribute(prodDetails.get("creative_name")),
          creative_slot: validateAttribute(prodDetails.get("creative_slot")),
          discount:
            prodDetails.get("discount") === null || prodDetails.get("discount") === undefined
              ? 0
              : formatNumberWithTwoDecimals(prodDetails.get("discount")),
          index: 0,
          item_list_id: "NA",
          item_list_name: "NA",
          item_variant: prodDetails.get("item_variant"),
          promotion_name: validateAttribute(prodDetails.get("promotion_name")),
          promotion_id: validateAttribute(prodDetails.get("promotion_id")),
          promo_thd: validateAttribute(prodDetails.get("promo_thd")),
        };
        items.push(item);
      }
    }
  } else {
    let prodDetails = new Map();
    if (productDetails.get(partNumber) != null) {
      prodDetails = productDetails.get(partNumber);
      itemName = prodDetails.get("name");
      itemId = partNumber;
      itemPrice = prodDetails.get("price");
      itemBrand = prodDetails.get("brand");
      itemQuantity = prodDetails.get("quantity");
    }
    const prdCatgrpIds = partNumberCatGrpIds.get(partNumber);
    let count: number = 0;
    if (prdCatgrpIds && prdCatgrpIds.length >= 1) {
      for (const catalogGroupID of prdCatgrpIds) {
        if (catalogGroupID) {
          const catgryName: string =
            catGrpIdDetails.get(catalogGroupID) === null || catGrpIdDetails.get(catalogGroupID) === undefined
              ? "NA"
              : catGrpIdDetails.get(catalogGroupID);
          if (count === 0) {
            itemCategory = catgryName;
          } else if (count === 1) {
            itemCategory1 = catgryName;
          } else if (count === 2) {
            itemCategory2 = catgryName;
          }
          count = count + 1;
        }
      }
    }
    console.log("itemName", itemName);
    console.log("itemId", itemId);
    console.log("itemPrice", itemPrice);
    console.log("itemBrand", itemBrand);
    console.log("itemCategory", itemCategory);
    console.log("itemCategory1", itemCategory1);
    console.log("itemCategory2", itemCategory2);
    console.log("itemQuantity", itemQuantity);
    itemsTotal = (itemPrice === null || itemPrice === undefined ? 0 : formatNumberWithTwoDecimals(itemPrice))
      * (!isNaN(itemQuantity) ? itemQuantity : 1);

    const item = {
      item_name: itemName === null || itemName === undefined ? "NA" : itemName,
      item_id: itemId,
      price: itemPrice === null || itemPrice === undefined ? "NA" : formatNumberWithTwoDecimals(itemPrice),
      item_brand: itemBrand === null || itemBrand === undefined ? "NA" : itemBrand,
      item_category: validateAttribute(itemCategory),
      item_category2: validateAttribute(itemCategory1),
      item_category3: validateAttribute(itemCategory2),
      quantity: itemQuantity,
      item_affiliation:
        itemAffiliation === null || itemAffiliation === undefined
          ? !isB2B
            ? "The Home Depot"
            : "The Home Depot PRO"
          : itemAffiliation,
      coupon: "NA",
      creative_name:  validateAttribute(prodDetails.get("creative_name")),
      creative_slot:  validateAttribute(prodDetails.get("creative_slot")),
      discount:
        prodDetails.get("discount") === null || prodDetails.get("discount") === undefined
          ? 0
          : formatNumberWithTwoDecimals(prodDetails.get("discount")),
      index: 0,
      item_list_id: "NA",
      item_list_name: "NA",
      item_variant: prodDetails.get("item_variant"),
      promotion_name: validateAttribute(prodDetails.get("promotion_name")),
      promotion_id: validateAttribute(prodDetails.get("promotion_id")),
      promo_thd: validateAttribute(prodDetails.get("promo_thd")),
    };
    items.push(item);
  }
  try {
    await Axios.get<any>(`/wcs/resources/store/10351/cart/@self?sortOrderItemBy=orderItemID`).then((response) => {
      if (response && response.status === 200 && response?.data?.orderItem) {
        let ordItemsTotal = 0;
        let isItemExists = false;
        for (const orderItem of response.data.orderItem) {
          if (orderItem && orderItem.partNumber) {
            let unitPrice: any;
            if (Array.isArray(partNumber)) {
              for (const partNum of partNumber) {
                if (partNum) {
                  if (orderItem.partNumber === partNum) {
                    let prodDetails = new Map();
                    if (productDetails.get(partNumber) != null) {
                      prodDetails = productDetails.get(partNumber);
                      itemQuantity = prodDetails.get("quantity");
                    }
                    if (orderItem.xitem_taxRate) {
                      unitPrice = (
                        parseFloat(orderItem.orderItemPrice) +
                        parseFloat(orderItem.orderItemPrice) * (parseFloat(orderItem.xitem_taxRate) / 100) +
                        (parseFloat(orderItem.totalAdjustment.value) +
                          parseFloat(orderItem.totalAdjustment.value) * (parseFloat(orderItem.xitem_taxRate) / 100)));
                    } else {
                      unitPrice = (parseFloat(orderItem.orderItemPrice) + parseFloat(orderItem.totalAdjustment.value));
                    }
                    ordItemsTotal = ordItemsTotal + (formatNumberWithTwoDecimals(unitPrice / Number(orderItem.quantity)) * Number(itemQuantity));
                    isItemExists = true;
                  }
                }
              }
            } else if (orderItem.partNumber === partNumber) {
              if (orderItem.xitem_taxRate) {
                unitPrice =
                  parseFloat(orderItem.orderItemPrice) +
                  parseFloat(orderItem.orderItemPrice) * (parseFloat(orderItem.xitem_taxRate) / 100) +
                  (parseFloat(orderItem.totalAdjustment.value) +
                    parseFloat(orderItem.totalAdjustment.value) * (parseFloat(orderItem.xitem_taxRate) / 100));
              } else {
                unitPrice = parseFloat(orderItem.orderItemPrice) + parseFloat(orderItem.totalAdjustment.value);
              }
              ordItemsTotal = (formatNumberWithTwoDecimals(unitPrice / Number(orderItem.quantity)) * Number(itemQuantity));
              isItemExists = true;
            }
          }
        }
        if(isItemExists){
          itemsTotal = ordItemsTotal;
        }
      }
    });
  } catch (error) {
    console.log("error", error);
  }
  return [formatNumberWithTwoDecimals(itemsTotal), items];
};

export const retrieveOrderProductsDetails = async (
  storeId,
  physicalStoreId,
  contractId,
  orderId,
  isPurchaseEvent,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
  const products: any = [];
  const partNumberList: string[] = [];
  const catGrpList: string[] = [];
  let partNumbers: string = "";
  let catGrpIds: string = "";
  const catGrpIdDetails = new Map();
  const partNumberCatGrpIds = new Map();
  const productDetails = new Map();
  let orderTotal: any = "";
  let totalShippingCharge: any = "";
  let paymentMethod: string = "";
  try {
    await Axios.get<any>(`/wcs/resources/store/${storeId}/order/${orderId}`).then((response) => {
      if (response && response.status === 200 && response?.data?.orderItem) {
        orderTotal = formatNumberWithTwoDecimals(response.data.grandTotal);
        if (isPurchaseEvent) {
          totalShippingCharge = formatNumberWithTwoDecimals(
            parseFloat(response.data.totalShippingCharge) + parseFloat(response.data.totalShippingTax)
          );
          if (response.data.paymentInstruction) {
            for (const payIns of response.data.paymentInstruction) {
              if (payIns.protocolData) {
                for (const pd of payIns.protocolData) {
                  if (pd.name === "payment_method") {
                    if (
                      pd.value === PAYMENT.paymentMethodName.cod ||
                      pd.value === PAYMENT.paymentMethodName.oxxo ||
                      pd.value === PAYMENT.paymentMethodName.openPay ||
                      pd.value === PAYMENT.paymentMethodName.paypal ||
                      pd.value === PAYMENT.paymentMethodName.carnet ||
                      pd.value === PAYMENT.paymentMethodName.giftCard
                    ) {
                      paymentMethod = pd.value;
                    } else {
                      paymentMethod = "TDC";
                    }
                  }
                }
              }
            }
          }
        }
        let oiIndex: number = 0;
        for (const orderItem of response.data.orderItem) {
          oiIndex = oiIndex + 1;
          let hasNxMPromo = false;
          let hasAMPromo = false;
          const prodPromoDetails = new Map();
          let comboSkuCount: number = 0;
          let comboAdjAmount: any = 0;

          if (orderItem.adjustment) {
            for (const oiAdj of orderItem.adjustment) {
              if (
                !hasNxMPromo &&
                ((oiAdj.code && oiAdj.code.includes("NXM")) || (oiAdj.description && oiAdj.description.includes("NxM")))
              ) {
                hasNxMPromo = true;
              } else if ((oiAdj.code && oiAdj.code.includes("CP")) || (oiAdj.description && oiAdj.description.includes("Cruzada"))
              ) {
                hasAMPromo = true;
              }
              if (!isB2B) {
                if ((oiAdj.code && oiAdj.code.includes("NXM")) || (oiAdj.description && oiAdj.description.includes("NxM"))
                ) {
                  prodPromoDetails.set("NXM", "NxM");
                } else if ((oiAdj.code && oiAdj.code.includes("PG")) || (oiAdj.description && oiAdj.description.includes("de Regalo"))
                ) {
                  prodPromoDetails.set("PG", "Producto Gratis");
                } else if ((oiAdj.code && oiAdj.code.includes("AHP")) || (oiAdj.description && oiAdj.description.includes("Precio Mayoreo"))
                ) {
                  prodPromoDetails.set("PM", "Precio Mayoreo");
                } else if ((oiAdj.code && oiAdj.code.includes("CP")) || (oiAdj.description && oiAdj.description.includes("Cruzada"))
                ) {
                  prodPromoDetails.set("AM", "Ahorra Mas (Cross Dept)");
                } else if ((oiAdj.code && oiAdj.code.includes("PAQ")) || (oiAdj.description && oiAdj.description.includes("Combo"))
                ) {
                  prodPromoDetails.set("C", "Combos");
                  comboSkuCount = comboSkuCount + 1;
                  if (orderItem.xitem_taxRate) {
                    comboAdjAmount = comboAdjAmount + formatNumberWithTwoDecimals((parseFloat(oiAdj.amount) * -1) +
                      ((parseFloat(oiAdj.amount.value) * -1) * (parseFloat(orderItem.xitem_taxRate) / 100)));
                  } else {
                    comboAdjAmount = comboAdjAmount + formatNumberWithTwoDecimals(parseFloat(oiAdj.amount) * -1);
                  }
                }
              }
            }
          }
          if (orderItem && orderItem.partNumber) {
            partNumberList.push(orderItem.partNumber);
            let unitPrice: any;
            if (orderItem.xitem_taxRate) {
              if (hasNxMPromo) {
                unitPrice =
                  parseFloat(orderItem.unitPrice) +
                  parseFloat(orderItem.unitPrice) * (parseFloat(orderItem.xitem_taxRate) / 100);
              } else {
                unitPrice =
                  parseFloat(orderItem.orderItemPrice) +
                  parseFloat(orderItem.orderItemPrice) * (parseFloat(orderItem.xitem_taxRate) / 100) +
                  (parseFloat(orderItem.totalAdjustment.value) +
                    parseFloat(orderItem.totalAdjustment.value) * (parseFloat(orderItem.xitem_taxRate) / 100));
              }
            } else {
              if (hasNxMPromo) {
                unitPrice = parseFloat(orderItem.unitPrice);
              } else {
                unitPrice = parseFloat(orderItem.orderItemPrice) + parseFloat(orderItem.totalAdjustment.value);
              }
            }

            const prodDetails = new Map();
            if (hasNxMPromo) {
              prodDetails.set("price", formatNumberWithTwoDecimals(unitPrice));
            } else {
              prodDetails.set("price", formatNumberWithTwoDecimals(unitPrice / Number(orderItem.quantity)));
            }

            prodDetails.set("quantity", Number(orderItem.quantity));
            if (parseFloat(orderItem.totalAdjustment.value) < 0) {
              let discountVal: any;
              if (orderItem.xitem_taxRate) {
                discountVal = formatNumberWithTwoDecimals(((parseFloat(orderItem.totalAdjustment.value) * -1) +
                  (parseFloat(orderItem.totalAdjustment.value) * -1) * (parseFloat(orderItem.xitem_taxRate) / 100)));
              } else {
                discountVal = formatNumberWithTwoDecimals((parseFloat(orderItem.totalAdjustment.value) * -1));
              }
              discountVal = discountVal - comboAdjAmount;
              prodDetails.set("discount", formatNumberWithTwoDecimals(discountVal / Number(orderItem.quantity)));
            } else {
              prodDetails.set("discount", 0);
            }

            prodDetails.set("index", oiIndex);
            prodDetails.set("item_list_id", "NA");
            prodDetails.set("item_list_name", "NA");
            prodDetails.set("item_variant", "NA");
            prodDetails.set("hasAMPromo", hasAMPromo);
            prodDetails.set("promo", prodPromoDetails);
            prodDetails.set("comboSkuCount", comboSkuCount);
            prodDetails.set("comboAdjAmount", comboAdjAmount);
            productDetails.set(orderItem.partNumber, prodDetails);
          }
        }
      }
      if (partNumberList && partNumberList.length >= 1) {
        for (const partNum of partNumberList) {
          if (partNum) {
            if (partNumbers === "") {
              partNumbers = "partNumber=" + partNum;
            } else {
              partNumbers = partNumbers + "&partNumber=" + partNum;
            }
          }
        }
      }
    });

    await Axios.get<any>(
      `/search/resources/api/v2/products?storeId=${storeId}&physicalStoreId=${physicalStoreId}&contractId=${contractId}&langId=-5&${partNumbers}`
    ).then((response) => {
      if (response && response.status === 200 && response?.data?.contents) {
        for (const dataResponse of response.data.contents) {
          const offerPrice = dataResponse.price.filter((precio) => precio.usage === "Offer")[0];
          const displayPrice = dataResponse.price.filter((precio) => precio.usage === "Display")[0];
          let nlpDiscount: any;
          if (
            offerPrice?.value &&
            displayPrice?.value &&
            parseFloat(displayPrice.value) > parseFloat(offerPrice.value)
          ) {
            nlpDiscount = parseFloat(displayPrice.value) - parseFloat(offerPrice.value);
          }
          const prodPromoAttrDetails = getProductPromoDetails(
            dataResponse.attributes,
            isB2B,
            nlpDiscount !== null && nlpDiscount > 0 ? true : false,
            physicalStoreId,
            currentMarketId
          );

          if (dataResponse && dataResponse.parentCatalogGroupID) {
            let catalogGroupIDs: any;
            if (Array.isArray(dataResponse.parentCatalogGroupID) && dataResponse.parentCatalogGroupID[0]) {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.filter((item) => item.split("/").length === 4);
              if (catalogGroupIDs && catalogGroupIDs.length > 0) {
                catalogGroupIDs = catalogGroupIDs[0].split("/");
              } else {
                catalogGroupIDs = dataResponse.parentCatalogGroupID[0].split("/");
              }
            } else {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.split("/");
            }

            const catGrpIdsList: string[] = [];
            let prodDetails = new Map();
            if (productDetails.get(dataResponse.partNumber) != null) {
              prodDetails = productDetails.get(dataResponse.partNumber);
            }

            const prodPromoDetails = prodDetails.get("promo");
            prodPromoAttrDetails.forEach(function (value, key) {
              if (!prodPromoDetails.has(key)) {
                prodPromoDetails.set(key, value);
              }
            });
            const hasAMPromo = prodDetails.get("hasAMPromo");
            if (hasAMPromo) {
              prodPromoDetails.set("AM", "Ahorra Mas (Cross Dept)");
            }
            prodDetails.set("name", dataResponse.name);
            prodDetails.set("brand", dataResponse.manufacturer);
            let promotionId: string = "";
            let promotionName: string = "";
            prodPromoDetails.forEach(function (value, key) {
              if (promotionId === "") {
                promotionId = key;
              } else {
                promotionId = promotionId + ", " + key;
              }
              if (promotionName === "") {
                promotionName = value;
              } else {
                promotionName = promotionName + ", " + value;
              }
            });

            const promoSelected = getPromotionDetails();
            if (promoSelected != null) {
              const { exist, promotion_name, promotion_id, creative_slot, creative_name } = verifyPartNumber(dataResponse.partNumber);
              if (exist == true) {
                prodDetails.set("promotion_id", promotion_id);
                prodDetails.set("promotion_name", promotion_name);
                prodDetails.set("creative_name", creative_name);
                prodDetails.set("creative_slot", creative_slot);
              } else {
                prodDetails.set("promotion_id", "NA");
                prodDetails.set("promotion_name", "NA");
                prodDetails.set("creative_name", "NA");
                prodDetails.set("creative_slot", "NA");
              }
            } else {
              prodDetails.set("promotion_id", "NA");
              prodDetails.set("promotion_name", "NA");
              prodDetails.set("creative_name", "NA");
                prodDetails.set("creative_slot", "NA");
            }

            prodDetails.set("promo_thd", promotionId);
            prodDetails.set("item_variant", getColorAttrDetails(dataResponse.attributes));
            if (catalogGroupIDs && catalogGroupIDs.length >= 1) {
              for (const catalogGroupID of catalogGroupIDs) {
                if (catalogGroupID) {
                  catGrpList.push(catalogGroupID);
                  catGrpIdsList.push(catalogGroupID);
                }
              }
            }
            partNumberCatGrpIds.set(dataResponse.partNumber, catGrpIdsList);
            productDetails.set(dataResponse.partNumber, prodDetails);
          }
        }
      }
    });
    if (catGrpList) {
      if (catGrpList && catGrpList.length >= 1) {
        for (const catalogGroupID of catGrpList) {
          if (catalogGroupID) {
            if (catGrpIds === "") {
              catGrpIds = "id=" + catalogGroupID;
            } else {
              catGrpIds = catGrpIds + "&id=" + catalogGroupID;
            }
          }
        }
      }
      await Axios.get<any>(
        `/search/resources/api/v2/categories?storeId=${storeId}&contractId=${contractId}&langId=-5&${catGrpIds}`
      ).then((response) => {
        if (response && response.status === 200 && response?.data?.contents && response.data.contents.length > 0) {
          for (const catalogGroupDetails of response.data.contents) {
            if (catalogGroupDetails) {
              catGrpIdDetails.set(catalogGroupDetails.uniqueID, catalogGroupDetails.name);
            }
          }
        }
      });
    }

    for (const partNum of partNumberList) {
      const prdDtls = productDetails.get(partNum);
      const prdCatgrpIds = partNumberCatGrpIds.get(partNum);
      let itemCategory: string = "";
      let itemCategory1: string = "";
      let itemCategory2: string = "";
      let count: number = 0;
      if (prdCatgrpIds && prdCatgrpIds.length >= 1) {
        for (const catalogGroupId of prdCatgrpIds) {
          if (catalogGroupId) {
            const catgry: string =
              catGrpIdDetails.get(catalogGroupId) === null || catGrpIdDetails.get(catalogGroupId) === undefined
                ? "NA"
                : catGrpIdDetails.get(catalogGroupId);
            if (count === 0) {
              itemCategory = catgry;
            } else if (count === 1) {
              itemCategory1 = catgry;
            } else if (count === 2) {
              itemCategory2 = catgry;
            }
            count = count + 1;
          }
        }
      }
      const productObj = {
        item_name: prdDtls.get("name") === null || prdDtls.get("name") === undefined ? "NA" : prdDtls.get("name"),
        item_id: partNum,
        price: prdDtls.get("price") === null || prdDtls.get("price") === undefined ? "NA" : prdDtls.get("price"),
        item_brand: prdDtls.get("brand") === null || prdDtls.get("brand") === undefined ? "NA" : prdDtls.get("brand"),
        item_category: validateAttribute(itemCategory),
        item_category2: validateAttribute(itemCategory1),
        item_category3: validateAttribute(itemCategory2),
        quantity:
          prdDtls.get("quantity") === null || prdDtls.get("quantity") === undefined ? "NA" : prdDtls.get("quantity"),
        item_affiliation:
          itemAffiliation === null || itemAffiliation === undefined
            ? !isB2B
              ? "The Home Depot"
              : "The Home Depot PRO"
            : itemAffiliation,
        coupon: "NA",
        creative_name: validateAttribute(prdDtls.get("creative_name")),
        creative_slot: validateAttribute(prdDtls.get("creative_slot")),
        discount:
        prdDtls.get("discount") === null || prdDtls.get("discount") === undefined ? 0 :
        prdDtls.get("discount")
        + (prdDtls.get("comboSkuCount") === 0 ? 0 : prdDtls.get("comboAdjAmount") / Number(prdDtls.get("comboSkuCount"))),
        index: prdDtls.get("index") === null || prdDtls.get("index") === undefined ? 0 : prdDtls.get("index"),
        item_list_id: "NA",
        item_list_name: "NA",
        item_variant: prdDtls.get("item_variant"),
        promotion_name: validateAttribute(prdDtls.get("promotion_name")),
        promotion_id: validateAttribute(prdDtls.get("promotion_id")),
        promo_thd: validateAttribute(prdDtls.get("promo_thd")),
      };
      products.push(productObj);
    }
  } catch (error) {
    console.log("error", error);
  }
  return [orderTotal, totalShippingCharge, paymentMethod, products];
};

export const retrieveViewCartProductsDetails = async (
  storeId,
  physicalStoreId,
  contractId,
  orderId,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
  const products: any = [];
  const partNumberList: string[] = [];
  const catGrpList: string[] = [];
  let partNumbers: string = "";
  let catGrpIds: string = "";
  const catGrpIdDetails = new Map();
  const partNumberCatGrpIds = new Map();
  const productDetails = new Map();
  let orderTotal: any = "";
  try {
    await Axios.get<any>(`/wcs/resources/store/${storeId}/order/${orderId}`).then((response) => {
      if (response && response.status === 200 && response?.data?.orderItem) {
        orderTotal = formatNumberWithTwoDecimals(response.data.grandTotal);
        let oiIndex: number = 0;
        for (const orderItem of response.data.orderItem) {
          oiIndex = oiIndex + 1;
          let hasNxMPromo = false;
          let hasAMPromo = false;
          const prodPromoDetails = new Map();
          let comboSkuCount: number = 0;
          let comboAdjAmount: any = 0;

          if (orderItem.adjustment) {
            for (const oiAdj of orderItem.adjustment) {
              if (
                !hasNxMPromo &&
                ((oiAdj.code && oiAdj.code.includes("NXM")) || (oiAdj.description && oiAdj.description.includes("NxM")))
              ) {
                hasNxMPromo = true;
              } else if ((oiAdj.code && oiAdj.code.includes("CP")) || (oiAdj.description && oiAdj.description.includes("Cruzada"))
              ) {
                hasAMPromo = true;
              }
              if (!isB2B) {
                if ((oiAdj.code && oiAdj.code.includes("NXM")) || (oiAdj.description && oiAdj.description.includes("NxM"))
                ) {
                  prodPromoDetails.set("NXM", "NxM");
                } else if ((oiAdj.code && oiAdj.code.includes("PG")) || (oiAdj.description && oiAdj.description.includes("de Regalo"))
                ) {
                  prodPromoDetails.set("PG", "Producto Gratis");
                } else if ((oiAdj.code && oiAdj.code.includes("AHP")) || (oiAdj.description && oiAdj.description.includes("Precio Mayoreo"))
                ) {
                  prodPromoDetails.set("PM", "Precio Mayoreo");
                } else if ((oiAdj.code && oiAdj.code.includes("CP")) || (oiAdj.description && oiAdj.description.includes("Cruzada"))
                ) {
                  prodPromoDetails.set("AM", "Ahorra Mas (Cross Dept)");
                } else if ((oiAdj.code && oiAdj.code.includes("PAQ")) || (oiAdj.description && oiAdj.description.includes("Combo"))
                ) {
                  prodPromoDetails.set("C", "Combos");
                  comboSkuCount = comboSkuCount + 1;
                  if (orderItem.xitem_taxRate) {
                    comboAdjAmount = comboAdjAmount + formatNumberWithTwoDecimals((parseFloat(oiAdj.amount) * -1) +
                      ((parseFloat(oiAdj.amount.value) * -1) * (parseFloat(orderItem.xitem_taxRate) / 100)));
                  } else {
                    comboAdjAmount = comboAdjAmount + formatNumberWithTwoDecimals(parseFloat(oiAdj.amount) * -1);
                  }
                }
              }
            }
          }
          if (orderItem && orderItem.partNumber) {
            partNumberList.push(orderItem.partNumber);
            let unitPrice: any;
            if (orderItem.xitem_taxRate) {
              if (hasNxMPromo) {
                unitPrice =
                  parseFloat(orderItem.unitPrice) +
                  parseFloat(orderItem.unitPrice) * (parseFloat(orderItem.xitem_taxRate) / 100);
              } else {
                unitPrice =
                  parseFloat(orderItem.orderItemPrice) +
                  parseFloat(orderItem.orderItemPrice) * (parseFloat(orderItem.xitem_taxRate) / 100) +
                  (parseFloat(orderItem.totalAdjustment.value) +
                    parseFloat(orderItem.totalAdjustment.value) * (parseFloat(orderItem.xitem_taxRate) / 100));
              }
            } else {
              if (hasNxMPromo) {
                unitPrice = parseFloat(orderItem.unitPrice);
              } else {
                unitPrice = parseFloat(orderItem.orderItemPrice) + parseFloat(orderItem.totalAdjustment.value);
              }
            }

            const prodDetails = new Map();
            if (hasNxMPromo) {
              prodDetails.set("price", formatNumberWithTwoDecimals(unitPrice));
            } else {
              prodDetails.set("price", formatNumberWithTwoDecimals(unitPrice / Number(orderItem.quantity)));
            }

            prodDetails.set("quantity", Number(orderItem.quantity));
            if (parseFloat(orderItem.totalAdjustment.value) < 0) {
              let discountVal: any;
              if (orderItem.xitem_taxRate) {
                discountVal = formatNumberWithTwoDecimals(((parseFloat(orderItem.totalAdjustment.value) * -1) +
                  (parseFloat(orderItem.totalAdjustment.value) * -1) * (parseFloat(orderItem.xitem_taxRate) / 100)));
              } else {
                discountVal = formatNumberWithTwoDecimals((parseFloat(orderItem.totalAdjustment.value) * -1));
              }
              discountVal = discountVal - comboAdjAmount;
              prodDetails.set("discount", formatNumberWithTwoDecimals(discountVal / Number(orderItem.quantity)));
            } else {
              prodDetails.set("discount", 0);
            }

            prodDetails.set("index", oiIndex);
            prodDetails.set("item_list_id", "NA");
            prodDetails.set("item_list_name", "NA");
            prodDetails.set("item_variant", "NA");
            prodDetails.set("hasAMPromo", hasAMPromo);
            prodDetails.set("promo", prodPromoDetails);
            prodDetails.set("comboSkuCount", comboSkuCount);
            prodDetails.set("comboAdjAmount", comboAdjAmount);
            productDetails.set(orderItem.partNumber, prodDetails);
          }
        }
      }
      if (partNumberList && partNumberList.length >= 1) {
        for (const partNum of partNumberList) {
          if (partNum) {
            if (partNumbers === "") {
              partNumbers = "partNumber=" + partNum;
            } else {
              partNumbers = partNumbers + "&partNumber=" + partNum;
            }
          }
        }
      }
    });

    await Axios.get<any>(
      `/search/resources/api/v2/products?storeId=${storeId}&physicalStoreId=${physicalStoreId}&contractId=${contractId}&langId=-5&${partNumbers}`
    ).then((response) => {
      if (response && response.status === 200 && response?.data?.contents) {
        for (const dataResponse of response.data.contents) {
          const offerPrice = dataResponse.price.filter((precio) => precio.usage === "Offer")[0];
          const displayPrice = dataResponse.price.filter((precio) => precio.usage === "Display")[0];
          let nlpDiscount: any;
          if (
            offerPrice?.value &&
            displayPrice?.value &&
            parseFloat(displayPrice.value) > parseFloat(offerPrice.value)
          ) {
            nlpDiscount = parseFloat(displayPrice.value) - parseFloat(offerPrice.value);
          }

          const prodPromoAttrDetails = getProductPromoDetails(
            dataResponse.attributes,
            isB2B,
            nlpDiscount !== null && nlpDiscount > 0 ? true : false,
            physicalStoreId,
            currentMarketId
          );

          if (dataResponse && dataResponse.parentCatalogGroupID) {
            let catalogGroupIDs: any;
            if (Array.isArray(dataResponse.parentCatalogGroupID) && dataResponse.parentCatalogGroupID[0]) {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.filter((item) => item.split("/").length === 4);
              if (catalogGroupIDs && catalogGroupIDs.length > 0) {
                catalogGroupIDs = catalogGroupIDs[0].split("/");
              } else {
                catalogGroupIDs = dataResponse.parentCatalogGroupID[0].split("/");
              }
            } else {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.split("/");
            }

            const catGrpIdsList: string[] = [];
            let prodDetails = new Map();
            if (productDetails.get(dataResponse.partNumber) != null) {
              prodDetails = productDetails.get(dataResponse.partNumber);
            }
            const prodPromoDetails = prodDetails.get("promo");
            prodPromoAttrDetails.forEach(function (value, key) {
              if (!prodPromoDetails.has(key)) {
                prodPromoDetails.set(key, value);
              }
            });
            const hasAMPromo = prodDetails.get("hasAMPromo");
            if (hasAMPromo) {
              prodPromoDetails.set("AM", "Ahorra Mas (Cross Dept)");
            }
            prodDetails.set("name", dataResponse.name);
            prodDetails.set("brand", dataResponse.manufacturer);
            let promotionId: string = "";
            let promotionName: string = "";
            prodPromoDetails.forEach(function (value, key) {
              if (promotionId === "") {
                promotionId = key;
              } else {
                promotionId = promotionId + ", " + key;
              }
              if (promotionName === "") {
                promotionName = value;
              } else {
                promotionName = promotionName + ", " + value;
              }
            });

            const promoSelected = validateCartPromo(dataResponse.partNumber);
            if (promoSelected != null) {
              prodDetails.set("promotion_id", promoSelected.promotion_id);
              prodDetails.set("promotion_name", promoSelected.promotion_name);
              prodDetails.set("creative_name", promoSelected.creative_name);
              prodDetails.set("creative_slot", promoSelected.creative_slot);

            } else {
              prodDetails.set("promotion_id", "NA");
              prodDetails.set("promotion_name", "NA");
              prodDetails.set("creative_name", "NA");
              prodDetails.set("creative_slot", "NA");
            }

            prodDetails.set("promo_thd", promotionId);
            prodDetails.set("item_variant", getColorAttrDetails(dataResponse.attributes));

            if (catalogGroupIDs && catalogGroupIDs.length >= 1) {
              for (const catalogGroupID of catalogGroupIDs) {
                if (catalogGroupID) {
                  catGrpList.push(catalogGroupID);
                  catGrpIdsList.push(catalogGroupID);
                }
              }
            }
            partNumberCatGrpIds.set(dataResponse.partNumber, catGrpIdsList);
            productDetails.set(dataResponse.partNumber, prodDetails);
          }
        }
      }
    });
    if (catGrpList) {
      if (catGrpList && catGrpList.length >= 1) {
        for (const catalogGroupID of catGrpList) {
          if (catalogGroupID) {
            if (catGrpIds === "") {
              catGrpIds = "id=" + catalogGroupID;
            } else {
              catGrpIds = catGrpIds + "&id=" + catalogGroupID;
            }
          }
        }
      }
      await Axios.get<any>(
        `/search/resources/api/v2/categories?storeId=${storeId}&contractId=${contractId}&langId=-5&${catGrpIds}`
      ).then((response) => {
        if (response && response.status === 200 && response?.data?.contents && response.data.contents.length > 0) {
          for (const catalogGroupDetails of response.data.contents) {
            if (catalogGroupDetails) {
              catGrpIdDetails.set(catalogGroupDetails.uniqueID, catalogGroupDetails.name);
            }
          }
        }
      });
    }

    for (const partNum of partNumberList) {
      const prdDtls = productDetails.get(partNum);
      const prdCatgrpIds = partNumberCatGrpIds.get(partNum);
      let itemCategory: string = "";
      let itemCategory1: string = "";
      let itemCategory2: string = "";
      let count: number = 0;
      if (prdCatgrpIds && prdCatgrpIds.length >= 1) {
        for (const catalogGroupId of prdCatgrpIds) {
          if (catalogGroupId) {
            const catgry: string =
              catGrpIdDetails.get(catalogGroupId) === null || catGrpIdDetails.get(catalogGroupId) === undefined
                ? "NA"
                : catGrpIdDetails.get(catalogGroupId);
            if (count === 0) {
              itemCategory = catgry;
            } else if (count === 1) {
              itemCategory1 = catgry;
            } else if (count === 2) {
              itemCategory2 = catgry;
            }
            count = count + 1;
          }
        }
      }
      const productObj = {
        item_name: prdDtls.get("name") === null || prdDtls.get("name") === undefined ? "NA" : prdDtls.get("name"),
        item_id: partNum,
        price: prdDtls.get("price") === null || prdDtls.get("price") === undefined ? "NA" : prdDtls.get("price"),
        item_brand: prdDtls.get("brand") === null || prdDtls.get("brand") === undefined ? "NA" : prdDtls.get("brand"),
        item_category: validateAttribute(itemCategory),
        item_category2: validateAttribute(itemCategory1),
        item_category3: validateAttribute(itemCategory2),
        quantity:
          prdDtls.get("quantity") === null || prdDtls.get("quantity") === undefined ? "NA" : prdDtls.get("quantity"),
        item_affiliation:
          itemAffiliation === null || itemAffiliation === undefined
            ? !isB2B
              ? "The Home Depot"
              : "The Home Depot PRO"
            : itemAffiliation,
        coupon: "NA",
        creative_name: validateAttribute(prdDtls.get("creative_name")),
        creative_slot: validateAttribute(prdDtls.get("creative_slot")),
        discount:
        prdDtls.get("discount") === null || prdDtls.get("discount") === undefined ? 0 :
        prdDtls.get("discount")
        + (prdDtls.get("comboSkuCount") === 0 ? 0 : prdDtls.get("comboAdjAmount") / Number(prdDtls.get("comboSkuCount"))),
        index: prdDtls.get("index") === null || prdDtls.get("index") === undefined ? 0 : prdDtls.get("index"),
        item_list_id: "NA",
        item_list_name: "NA",
        item_variant: prdDtls.get("item_variant"),
        promotion_name: validateAttribute(prdDtls.get("promotion_name")),
        promotion_id: validateAttribute(prdDtls.get("promotion_id")),
        promo_thd: validateAttribute(prdDtls.get("promo_thd")),
      };
      products.push(productObj);
    }
  } catch (error) {
    console.log("error", error);
  }
  return [orderTotal, products];
};
function getColorAttrDetails(attributes) {
  if (attributes !== null && attributes !== undefined) {
    const attrObj = attributes.filter(
      (att) =>
        att.usage === "Descriptive" && att.displayable && att.identifier === "COLOR" && att.storeDisplay !== "true"
    );
    for (const obj of attrObj) {
      if (obj?.values) {
        const values = obj?.values[0];
        if (values && values?.value) {
          if (Array.isArray(values?.value)) {
            return values?.value[0];
          } else {
            return values?.value;
          }
        }
      }
    }
  }
  return "NA";
}
function getProductPromoDetails(attributes, isB2B, hasNLPPromo, tiendaId, currentMarketId) {
  if (isB2B && tiendaId.indexOf("_") > 0) {
    tiendaId = tiendaId.substring(0, tiendaId.indexOf("_"));
  }
  const promociones = getPromoicons(attributes, isB2B, tiendaId, currentMarketId);
  const prodPromoDetails = new Map();
  if (hasNLPPromo) {
    prodPromoDetails.set("NLP", "NLP");
  }

  if (promociones) {
    for (const promoIcon of promociones) {
      if (promoIcon) {
        if (promoIcon === "PROMOICON_GR") {
          prodPromoDetails.set("R", "Remate");
        } else if (promoIcon === "PROMORIBBON_COMBO") {
          prodPromoDetails.set("C", "Combos");
        } else if (promoIcon === "PROMORIBBON_AHP") {
          prodPromoDetails.set("PM", "Precio Mayoreo");
        } else if (promoIcon === "PROMOICON_FP") {
          prodPromoDetails.set("PG", "Producto Gratis");
        } else if (promoIcon.startsWith("PROMORIBBON_NXM_")) {
          prodPromoDetails.set("NXM", "NxM");
        } else if (promoIcon === "PROMOICON_CP") {
          prodPromoDetails.set("AM", "Ahorra Mas (Cross Dept)");
        }
      }
    }
  }

  return prodPromoDetails;
}
const retrieveSelectItemProductsDetails = async (
  storeId,
  physicalStoreId,
  contractId,
  partNumber,
  itemQty,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
  const items: any = [];
  let itemName: string = "";
  let itemId: string = "";
  let itemPrice: string = "";
  let itemBrand: string = "";
  let itemQuantity: number = 0;
  let itemCategory: string = "";
  let itemCategory1: string = "";
  let itemCategory2: string = "";
  let catGrpIds: string = "";
  const catGrpIdDetails = new Map();
  let catalogGroupIDs: any;
  let partNumbers: string = "";
  const partNumberCatGrpIds = new Map();
  const prodDetails = new Map();

  partNumbers = "partNumber=" + partNumber;
  prodDetails.set("quantity", Number(itemQty));

  try {
    await Axios.get<any>(
      `/search/resources/api/v2/products?storeId=${storeId}&physicalStoreId=${physicalStoreId}&contractId=${contractId}&langId=-5&${partNumbers}`
    ).then((response) => {
      if (response && response.status === 200 && response?.data?.contents) {
        for (const dataResponse of response.data.contents) {
          prodDetails.set("name", dataResponse.name);
          prodDetails.set("brand", dataResponse.manufacturer);
          for (const price of dataResponse.price) {
            if (price.usage === "Offer" && price.value !== "") {
              prodDetails.set("price", price.value);
            }
          }
          const offerPrice = dataResponse.price.filter((precio) => precio.usage === "Offer")[0];
          const displayPrice = dataResponse.price.filter((precio) => precio.usage === "Display")[0];
          let nlpDiscount: any;
          if (
            offerPrice?.value &&
            displayPrice?.value &&
            parseFloat(displayPrice.value) > parseFloat(offerPrice.value)
          ) {
            nlpDiscount = parseFloat(displayPrice.value) - parseFloat(offerPrice.value);
          }
          prodDetails.set("discount", nlpDiscount);
          prodDetails.set("item_variant", getColorAttrDetails(dataResponse.attributes));
          const prodPromoDetails = getProductPromoDetails(
            dataResponse.attributes,
            isB2B,
            nlpDiscount !== null && nlpDiscount > 0 ? true : false,
            physicalStoreId,
            currentMarketId
          );
          let promotionId: string = "";
          let promotionName: string = "";
          prodPromoDetails.forEach(function (value, key) {
            if (promotionId === "") {
              promotionId = key;
            } else {
              promotionId = promotionId + ", " + key;
            }
            if (promotionName === "") {
              promotionName = value;
            } else {
              promotionName = promotionName + ", " + value;
            }
          });

          const promoSelected = getPromotionDetails();
          if (promoSelected != null) {
            const { exist, promotion_name, promotion_id, creative_name, creative_slot } = verifyPartNumber(dataResponse.partNumber);
            if (exist == true) {
              prodDetails.set("promotion_id", promotion_id);
              prodDetails.set("promotion_name", promotion_name);
              prodDetails.set("creative_name", creative_name);
              prodDetails.set("creative_slot", creative_slot);
            } else {
              prodDetails.set("promotion_id", "NA");
              prodDetails.set("promotion_name", "NA");
              prodDetails.set("creative_name", "NA");
              prodDetails.set("creative_slot", "NA");
            }
          } else {
            prodDetails.set("promotion_id", "NA");
            prodDetails.set("promotion_name", "NA");
            prodDetails.set("creative_name", "NA");
              prodDetails.set("creative_slot", "NA");
          }
          prodDetails.set("promo_thd", promotionId);

          if (dataResponse && dataResponse.parentCatalogGroupID) {
            if (Array.isArray(dataResponse.parentCatalogGroupID) && dataResponse.parentCatalogGroupID[0]) {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.filter((item) => item.split("/").length === 4);
              if (catalogGroupIDs && catalogGroupIDs.length > 0) {
                catalogGroupIDs = catalogGroupIDs[0].split("/");
              } else {
                catalogGroupIDs = dataResponse.parentCatalogGroupID[0].split("/");
              }
            } else {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.split("/");
            }
            if (catalogGroupIDs && catalogGroupIDs.length >= 1) {
              const catGrpIdsList: string[] = [];
              for (const catalogGroupID of catalogGroupIDs) {
                if (catalogGroupID) {
                  if (catGrpIds === "") {
                    catGrpIds = "id=" + catalogGroupID;
                  } else {
                    catGrpIds = catGrpIds + "&id=" + catalogGroupID;
                  }
                  catGrpIdsList.push(catalogGroupID);
                }
              }
              partNumberCatGrpIds.set(dataResponse.partNumber, catGrpIdsList);
            }
          }
        }
      }
    });
    if (catGrpIds) {
      await Axios.get<any>(
        `/search/resources/api/v2/categories?storeId=${storeId}&contractId=${contractId}&langId=-5&${catGrpIds}`
      ).then((response) => {
        if (response && response.status === 200 && response?.data?.contents && response.data.contents.length > 0) {
          for (const catalogGroupDetails of response.data.contents) {
            if (catalogGroupDetails) {
              catGrpIdDetails.set(catalogGroupDetails.uniqueID, catalogGroupDetails.name);
            }
          }
        }
      });
    }
  } catch (error) {
    console.log("error", error);
  }

  itemName = prodDetails.get("name");
  itemId = partNumber;
  itemPrice = prodDetails.get("price");
  itemBrand = prodDetails.get("brand");
  itemQuantity = prodDetails.get("quantity");
  const prdCatgrpIds = partNumberCatGrpIds.get(partNumber);
  let count: number = 0;
  if (prdCatgrpIds && prdCatgrpIds.length >= 1) {
    for (const catalogGroupID of prdCatgrpIds) {
      if (catalogGroupID) {
        const catgryName: string =
          catGrpIdDetails.get(catalogGroupID) === null || catGrpIdDetails.get(catalogGroupID) === undefined
            ? "NA"
            : catGrpIdDetails.get(catalogGroupID);
        if (count === 0) {
          itemCategory = catgryName;
        } else if (count === 1) {
          itemCategory1 = catgryName;
        } else if (count === 2) {
          itemCategory2 = catgryName;
        }
        count = count + 1;
      }
    }
  }
  console.log("itemName", itemName);
  console.log("itemId", itemId);
  console.log("itemPrice", itemPrice);
  console.log("itemBrand", itemBrand);
  console.log("itemCategory", itemCategory);
  console.log("itemCategory1", itemCategory1);
  console.log("itemCategory2", itemCategory2);
  console.log("itemQuantity", itemQuantity);

  const item = {
    item_name: itemName === null || itemName === undefined ? "NA" : itemName,
    item_id: itemId,
    price: itemPrice === null || itemPrice === undefined ? "NA" : formatNumberWithTwoDecimals(itemPrice),
    item_brand: itemBrand === null || itemBrand === undefined ? "NA" : itemBrand,
    item_category: validateAttribute(itemCategory),
    item_category2: validateAttribute(itemCategory1),
    item_category3: validateAttribute(itemCategory2),
    quantity: itemQuantity,
    item_affiliation:
      itemAffiliation === null || itemAffiliation === undefined
        ? !isB2B
          ? "The Home Depot"
          : "The Home Depot PRO"
        : itemAffiliation,
    coupon: "NA",
    creative_name: validateAttribute(prodDetails.get("creative_name")),
    creative_slot: validateAttribute(prodDetails.get("creative_slot")),
    discount:
      prodDetails.get("discount") === null || prodDetails.get("discount") === undefined
        ? 0
        : formatNumberWithTwoDecimals(prodDetails.get("discount")),
    index: 0,
    item_list_id: "NA",
    item_list_name: "NA",
    item_variant: prodDetails.get("item_variant"),
    promotion_name: validateAttribute(prodDetails.get("promotion_name")),
    promotion_id: validateAttribute(prodDetails.get("promotion_id")),
    promo_thd: validateAttribute(prodDetails.get("promo_thd")),
  };
  items.push(item);

  return items;
};
export const triggerReOrderAddToCartDLEvent = async (
  storeId,
  physicalStoreId,
  contractId,
  orderId,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
  const [orderTotal, totalShippingCharge, paymentMthd, itmsList] = await retrieveOrderProductsDetails(
    storeId,
    physicalStoreId,
    contractId,
    orderId,
    false,
    itemAffiliation,
    currentMarketId,
    isB2B
  );
  window["dataLayer"].push({ ecommerce: null });
  window["dataLayer"].push({
    event: "add_to_cart",
    ecommerce: {
      currency: "MXN",
      value: orderTotal,
      items: itmsList,
    },
  });
};

export const setupDivs = () => {
  const elementsWithDataDiv = document.querySelectorAll("[data-div]");
  elementsWithDataDiv.forEach((element) => {
    const gtmElements = element.querySelectorAll(".gtm_class");
    gtmElements.forEach((gtmElement) => {
      const dataDivValue = element.getAttribute("data-div");
      gtmElement.setAttribute("data-dv", dataDivValue ? dataDivValue : "");
      gtmElement.setAttribute("promotion-id", dataDivValue ? dataDivValue : "");
 
      if (gtmElement.tagName.toLowerCase() === "a") {
        const imgChild = gtmElement.querySelector("img");
        const alt = gtmElement?.getAttribute("alt");
        if (imgChild) {
          const override = gtmElement?.getAttribute("data-override");
          if (override != null  && override != ""){
            gtmElement.setAttribute("promotion-name", override);
            imgChild.setAttribute("promotion-name", override);
          }else{
            gtmElement.setAttribute("promotion-name", imgChild.alt ? imgChild.alt : "");
            imgChild.setAttribute("promotion-name", imgChild.alt ? imgChild.alt : "");
          }
        } else {
         
          const override = gtmElement?.getAttribute("data-override");
          if (override != null  && override != ""){
            gtmElement.setAttribute("promotion-name", override);
          }else{
            gtmElement.setAttribute(
              "promotion-name",
              gtmElement.textContent ? gtmElement.textContent.replace(/\s+/g, " ").replace("chevron_right","").trim() : ""
            );
          }
        }
      }
    });
  });
};

export const getDepartmentGTMElements = (currentUrl) => {
  const urlSegments = currentUrl.split('/');
  if (currentUrl.includes('/s/')) {
    return 'Search';
  } else if (currentUrl.includes('/b/')) {
    const lastSegment = urlSegments[urlSegments.length - 1];
    return lastSegment;
  } else if (currentUrl.includes('/p/')) {
    return 'Product';
  } else if (currentUrl === "/") {
    return 'Home';
  } else {
    return urlSegments[urlSegments.length - 1];
  }
};

export const retrieveProductsDetailsAddPaymentPurchaseEvent = async (
  storeId,
  physicalStoreId,
  contractId,
  orderId,
  isPurchaseEvent,
  itemAffiliation,
  currentMarketId,
  isB2B
) => {
  const products: any = [];
  const partNumberList: string[] = [];
  const catGrpList: string[] = [];
  let partNumbers: string = "";
  let catGrpIds: string = "";
  const catGrpIdDetails = new Map();
  const partNumberCatGrpIds = new Map();
  const productDetails = new Map();
  let orderTotal: any = "";
  let totalShippingCharge: any = "";
  let totalTax: any = "";
  let paymentMethod: string = "";


  try {
    await Axios.get<any>(`/wcs/resources/store/${storeId}/order/${orderId}`).then((response) => {
      if (response && response.status === 200 && response?.data?.orderItem) {
        orderTotal = formatNumberWithTwoDecimals(response.data.grandTotal);
        if (isPurchaseEvent) {
          totalShippingCharge = formatNumberWithTwoDecimals(
            parseFloat(response.data.totalShippingCharge) + parseFloat(response.data.totalShippingTax)
          );
          totalTax = formatNumberWithTwoDecimals(
            parseFloat(response.data.totalSalesTax) + parseFloat(response.data.totalShippingTax)
          );
          if (response.data.paymentInstruction) {
            for (const payIns of response.data.paymentInstruction) {
              if (payIns.protocolData) {
                for (const pd of payIns.protocolData) {
                  if (pd.name === "payment_method") {
                    if (
                      pd.value === PAYMENT.paymentMethodName.cod ||
                      pd.value === PAYMENT.paymentMethodName.oxxo ||
                      pd.value === PAYMENT.paymentMethodName.openPay ||
                      pd.value === PAYMENT.paymentMethodName.paypal ||
                      pd.value === PAYMENT.paymentMethodName.carnet ||
                      pd.value === PAYMENT.paymentMethodName.giftCard
                    ) {
                      paymentMethod = pd.value;
                    } else {
                      paymentMethod = "TDC";
                    }
                  }
                }
              }
            }
          }
        }
        let oiIndex: number = 0;
        for (const orderItem of response.data.orderItem) {
          const prodPromoDetails = new Map();
          let comboSkuCount: number = 0;
          let comboAdjAmount: any = 0;
          oiIndex = oiIndex + 1;
          let hasNxMPromo = false;
          if (orderItem.adjustment) {
            for (const oiAdj of orderItem.adjustment) {
              if (
                !hasNxMPromo &&
                ((oiAdj.code && oiAdj.code.includes("NXM")) || (oiAdj.description && oiAdj.description.includes("NxM")))
              ) {
                hasNxMPromo = true;
              }
              if (!isB2B) {
                if ((oiAdj.code && oiAdj.code.includes("NXM")) || (oiAdj.description && oiAdj.description.includes("NxM"))
                ) {
                  prodPromoDetails.set("NXM", "NxM");
                } else if ((oiAdj.code && oiAdj.code.includes("PG")) || (oiAdj.description && oiAdj.description.includes("de Regalo"))
                ) {
                  prodPromoDetails.set("PG", "Producto Gratis");
                } else if ((oiAdj.code && oiAdj.code.includes("AHP")) || (oiAdj.description && oiAdj.description.includes("Precio Mayoreo"))
                ) {
                  prodPromoDetails.set("PM", "Precio Mayoreo");
                } else if ((oiAdj.code && oiAdj.code.includes("CP")) || (oiAdj.description && oiAdj.description.includes("Cruzada"))
                ) {
                  prodPromoDetails.set("AM", "Ahorra Mas (Cross Dept)");
                } else if ((oiAdj.code && oiAdj.code.includes("PAQ")) || (oiAdj.description && oiAdj.description.includes("Combo"))
                ) {
                  prodPromoDetails.set("C", "Combos");
                  comboSkuCount = comboSkuCount + 1;
                  if (orderItem.xitem_taxRate) {
                    comboAdjAmount = comboAdjAmount + formatNumberWithTwoDecimals((parseFloat(oiAdj.amount) * -1) +
                      ((parseFloat(oiAdj.amount.value) * -1) * (parseFloat(orderItem.xitem_taxRate) / 100)));
                  } else {
                    comboAdjAmount = comboAdjAmount + formatNumberWithTwoDecimals(parseFloat(oiAdj.amount) * -1);
                  }
                }
              }
            }
          }
          if (orderItem && orderItem.partNumber) {
            partNumberList.push(orderItem.partNumber);
            let unitPrice: any;
            if (orderItem.xitem_taxRate) {
              unitPrice =
                parseFloat(orderItem.unitPrice) +
                parseFloat(orderItem.unitPrice) * (parseFloat(orderItem.xitem_taxRate) / 100);
            } else {
              unitPrice = parseFloat(orderItem.unitPrice);
            }

            const prodDetails = new Map();
            prodDetails.set("price", formatNumberWithTwoDecimals(unitPrice));
            prodDetails.set("quantity", Number(orderItem.quantity));
            if (parseFloat(orderItem.totalAdjustment.value) < 0) {
              let discountVal: any;
              if (orderItem.xitem_taxRate) {
                discountVal = formatNumberWithTwoDecimals(((parseFloat(orderItem.totalAdjustment.value) * -1) +
                  (parseFloat(orderItem.totalAdjustment.value) * -1) * (parseFloat(orderItem.xitem_taxRate) / 100)));
              } else {
                discountVal = formatNumberWithTwoDecimals((parseFloat(orderItem.totalAdjustment.value) * -1));
              }
              discountVal = discountVal - comboAdjAmount;
              prodDetails.set("discount", formatNumberWithTwoDecimals(discountVal / Number(orderItem.quantity)));
            } else {
              prodDetails.set("discount", 0);
            }

            prodDetails.set("index", oiIndex);
            prodDetails.set("item_list_id", "NA");
            prodDetails.set("item_list_name", "NA");
            prodDetails.set("item_variant", "NA");
            prodDetails.set("promo", prodPromoDetails);
            prodDetails.set("comboSkuCount", comboSkuCount);
            prodDetails.set("comboAdjAmount", comboAdjAmount);
            prodDetails.set("hasNxMPromo", hasNxMPromo);

            productDetails.set(orderItem.partNumber, prodDetails);
          }
        }
      }
      if (partNumberList && partNumberList.length >= 1) {
        for (const partNum of partNumberList) {
          if (partNum) {
            if (partNumbers === "") {
              partNumbers = "partNumber=" + partNum;
            } else {
              partNumbers = partNumbers + "&partNumber=" + partNum;
            }
          }
        }
      }
    });

    await Axios.get<any>(
      `/search/resources/api/v2/products?storeId=${storeId}&physicalStoreId=${physicalStoreId}&contractId=${contractId}&langId=-5&${partNumbers}`
    ).then((response) => {
      if (response && response.status === 200 && response?.data?.contents) {
        for (const dataResponse of response.data.contents) {
          const offerPrice = dataResponse.price.filter((precio) => precio.usage === "Offer")[0];
          const displayPrice = dataResponse.price.filter((precio) => precio.usage === "Display")[0];
          let nlpDiscount: any;
          if (
            offerPrice?.value &&
            displayPrice?.value &&
            parseFloat(displayPrice.value) > parseFloat(offerPrice.value)
          ) {
            nlpDiscount = parseFloat(displayPrice.value) - parseFloat(offerPrice.value);
          }
          if (dataResponse && dataResponse.parentCatalogGroupID) {
            let catalogGroupIDs: any;
            if (Array.isArray(dataResponse.parentCatalogGroupID) && dataResponse.parentCatalogGroupID[0]) {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.filter((item) => item.split("/").length === 4);
              if (catalogGroupIDs && catalogGroupIDs.length > 0) {
                catalogGroupIDs = catalogGroupIDs[0].split("/");
              } else {
                catalogGroupIDs = dataResponse.parentCatalogGroupID[0].split("/");
              }
            } else {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.split("/");
            }

            const catGrpIdsList: string[] = [];
            let prodDetails = new Map();
            if (productDetails.get(dataResponse.partNumber) != null) {
              prodDetails = productDetails.get(dataResponse.partNumber);
            }

            prodDetails.set("name", dataResponse.name);
            prodDetails.set("brand", dataResponse.manufacturer);
            const prodPromoAttrDetails = getProductPromoDetails(
              dataResponse.attributes,
              isB2B,
              nlpDiscount !== null && nlpDiscount > 0 ? true : false,
              physicalStoreId,
              currentMarketId
            );
            const prodPromoDetails = prodDetails.get("promo");
            prodPromoAttrDetails.forEach(function (value, key) {
              if (!prodPromoDetails.has(key)) {
                prodPromoDetails.set(key, value);
              }
            });

            if (nlpDiscount !== null && nlpDiscount > 0) {
              prodPromoDetails.set("NLP", "NLP");
            }
            let promotionId: string = "";
            let promotionName: string = "";
            prodPromoDetails.forEach(function (value, key) {
              if (promotionId === "") {
                promotionId = key;
              } else {
                promotionId = promotionId + ", " + key;
              }
              if (promotionName === "") {
                promotionName = value;
              } else {
                promotionName = promotionName + ", " + value;
              }
            });

            const promoSelected = validateCartPromo(dataResponse.partNumber);
            if (promoSelected != null) {
              prodDetails.set("promotion_id", promoSelected.promotion_id);
              prodDetails.set("promotion_name", promoSelected.promotion_name);
              prodDetails.set("creative_name", promoSelected.creative_name);
              prodDetails.set("creative_slot", promoSelected.creative_slot);

            } else {
              prodDetails.set("promotion_id", "NA");
              prodDetails.set("promotion_name", "NA");
              prodDetails.set("creative_name", "NA");
              prodDetails.set("creative_slot", "NA");
            }
            prodDetails.set("promo_thd", promotionId);
            prodDetails.set("item_variant", getColorAttrDetails(dataResponse.attributes));
            if (catalogGroupIDs && catalogGroupIDs.length >= 1) {
              for (const catalogGroupID of catalogGroupIDs) {
                if (catalogGroupID) {
                  catGrpList.push(catalogGroupID);
                  catGrpIdsList.push(catalogGroupID);
                }
              }
            }
            partNumberCatGrpIds.set(dataResponse.partNumber, catGrpIdsList);
            productDetails.set(dataResponse.partNumber, prodDetails);
          }
        }
      }
    });
    if (catGrpList) {
      if (catGrpList && catGrpList.length >= 1) {
        for (const catalogGroupID of catGrpList) {
          if (catalogGroupID) {
            if (catGrpIds === "") {
              catGrpIds = "id=" + catalogGroupID;
            } else {
              catGrpIds = catGrpIds + "&id=" + catalogGroupID;
            }
          }
        }
      }
      await Axios.get<any>(
        `/search/resources/api/v2/categories?storeId=${storeId}&contractId=${contractId}&langId=-5&${catGrpIds}`
      ).then((response) => {
        if (response && response.status === 200 && response?.data?.contents && response.data.contents.length > 0) {
          for (const catalogGroupDetails of response.data.contents) {
            if (catalogGroupDetails) {
              catGrpIdDetails.set(catalogGroupDetails.uniqueID, catalogGroupDetails.name);
            }
          }
        }
      });
    }

    for (const partNum of partNumberList) {
      const prdDtls = productDetails.get(partNum);
      const prdCatgrpIds = partNumberCatGrpIds.get(partNum);
      const hasNxMPromo = prdDtls.get("hasNxMPromo");
      let itemCategory: string = "";
      let itemCategory1: string = "";
      let itemCategory2: string = "";
      let count: number = 0;
      if (prdCatgrpIds && prdCatgrpIds.length >= 1) {
        for (const catalogGroupId of prdCatgrpIds) {
          if (catalogGroupId) {
            const catgry: string =
              catGrpIdDetails.get(catalogGroupId) === null || catGrpIdDetails.get(catalogGroupId) === undefined
                ? "NA"
                : catGrpIdDetails.get(catalogGroupId);
            if (count === 0) {
              itemCategory = catgry;
            } else if (count === 1) {
              itemCategory1 = catgry;
            } else if (count === 2) {
              itemCategory2 = catgry;
            }
            count = count + 1;
          }
        }
      }
      let price: any = -1;
      if (prdDtls.get("price") != null && prdDtls.get("price") != undefined) {
        price = prdDtls.get("price") - prdDtls.get("discount");
      }
      const productObj = {
        item_name: prdDtls.get("name") === null || prdDtls.get("name") === undefined ? "NA" : prdDtls.get("name"),
        item_id: partNum,
        price: prdDtls.get("price") === null || prdDtls.get("price") === undefined || price === -1
          ? "NA" : (prdDtls.get("comboSkuCount") === 0 ? price : price - (prdDtls.get("comboAdjAmount") / Number(prdDtls.get("comboSkuCount")))),
        item_brand: prdDtls.get("brand") === null || prdDtls.get("brand") === undefined ? "NA" : prdDtls.get("brand"),
        item_category: validateAttribute(itemCategory),
        item_category2: validateAttribute(itemCategory1),
        item_category3: validateAttribute(itemCategory2),
        quantity:
          prdDtls.get("quantity") === null || prdDtls.get("quantity") === undefined ? "NA"
            : (prdDtls.get("comboSkuCount") === 0 || prdDtls.get("comboSkuCount") === prdDtls.get("quantity") ? prdDtls.get("quantity") : prdDtls.get("comboSkuCount")),
        item_affiliation:
          itemAffiliation === null || itemAffiliation === undefined
            ? !isB2B
              ? "The Home Depot"
              : "The Home Depot PRO"
            : itemAffiliation,
        coupon: "NA",
        creative_name: validateAttribute(prdDtls.get("creative_name")),
        creative_slot: validateAttribute(prdDtls.get("creative_slot")),
        discount:
          prdDtls.get("discount") === null || prdDtls.get("discount") === undefined ? 0 :
            prdDtls.get("discount")
            + (prdDtls.get("comboSkuCount") === 0 ? 0 : prdDtls.get("comboAdjAmount") / Number(prdDtls.get("comboSkuCount"))),
        index: prdDtls.get("index") === null || prdDtls.get("index") === undefined ? 0 : prdDtls.get("index"),
        item_list_id: "NA",
        item_list_name: "NA",
        item_variant: prdDtls.get("item_variant"),
        promotion_name: validateAttribute(prdDtls.get("promotion_name")),
        promotion_id: validateAttribute(prdDtls.get("promotion_id")),
        promo_thd: validateAttribute(prdDtls.get("promo_thd")),
      };
      products.push(productObj);
      if (prdDtls.get("comboSkuCount") > 0 && prdDtls.get("comboSkuCount") < prdDtls.get("quantity")) {
        const prodCloneObj = { ...productObj };
        prodCloneObj.price = price;
        prodCloneObj.quantity = prdDtls.get("quantity") - prdDtls.get("comboSkuCount");
        prodCloneObj.discount = prdDtls.get("discount") === null || prdDtls.get("discount") === undefined ? 0 : prdDtls.get("discount");
        products.push(prodCloneObj);
      }
    }
  } catch (error) {
    console.log("error", error);
  }
  return [orderTotal, totalShippingCharge, paymentMethod, products, totalTax];
};

//Save in SS  (always in select_promotion or similar)
export const updatePromotionDetails = (promotionDtls) => {
  try {
    const objStr = JSON.stringify(promotionDtls);
    sessionStorage.setItem('promDetails', objStr);
  } catch (error) {
    console.error('Error SS promo save:', error);
  }
}

//Get from SS --> JSON
export const getPromotionDetails = () => {
  try {
    const objStr = sessionStorage.getItem('promDetails');
    if (objStr) {
      const objeto = JSON.parse(objStr);
      return objeto;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error reading SS:', error);
    return null;
  }
}

export const updateProductsPromo = (partNumbers) => {
  try {
    const promoDtls = getPromotionDetails();
    promoDtls.list_partnumbers = partNumbers;
    const updatedContent = JSON.stringify(promoDtls);
    sessionStorage.setItem('promDetails', updatedContent);
  } catch (error) {
    console.error('Error modify LS:', error);
  }
}
export const cleanPromoDetails = () => {
  try {
    sessionStorage.removeItem('promDetails');
  } catch (error) {
    console.error('Error del. SS:', error);
  }
}

interface ResultSearch {
  exist: boolean;
  promotion_name?: string;
  promotion_id?: string;
  creative_name?: string;
  creative_slot?: string;
}

const verifyPartNumber = (partnumber: string): ResultSearch => {
  try {
    const promoDtls = getPromotionDetails();
    const prodExist = promoDtls.list_partnumbers.includes(partnumber);
    if (prodExist) {
      return {
        exist: true,
        promotion_name: promoDtls.promotion_name,
        promotion_id: promoDtls.promotion_id,
        creative_name: promoDtls.creative_name,
        creative_slot: promoDtls.creative_slot,
      }
    } else {
      return { exist: false }
    }
  } catch (error) {
    console.error('Error getting values:', error);
    return { exist: false };
  }
}

//Adding records to track value in cart from 1 to n elements
export const addCartPromo = (object) => {
  logEventWithValues("promoCartDtls", object)
  const record = sessionStorage.getItem("promoCartDtls");
  const records = record ? JSON.parse(record) : [];
  const exist = records.some(record => JSON.stringify(record) === JSON.stringify(object));
  if (!exist) {
    records.push(object);
    sessionStorage.setItem("promoCartDtls", JSON.stringify(records));
  }
}

const validateCartPromo = (sku) => {
  const session = sessionStorage.getItem("promoCartDtls");
  const cartDtls = session ? JSON.parse(session) : [];
  const recordExist = cartDtls.find(record => record.SKU === sku);
  return recordExist || null;
}

//search submit
export const triggerSearchSubmitDL = async (
  eventName,
  searchType,
  eventCategory,
  searchTerm,
  urlDestination
) => {

  window["dataLayer"].push({
    event: eventName,
    event_category: eventCategory,
    search_term: searchTerm,
    search_type: searchType,
    url_destination: urlDestination
  });
};

//exception event
export const triggerExceptionDL = async (errorDesc, errorUrl
) => {
  window["dataLayer"].push({
    event: "exception",
    event_category: "Error",
    description: errorDesc,
    url_error: errorUrl,
    fatal: false
  });
};
