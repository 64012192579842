import React, { useEffect, useMemo, useState } from "react";
//UI

import { StyledBox } from "../../hdm";
import LogoHDM from "../../assets/homedepot/images/TheHomeDepot.png";
import styled from "styled-components";
import { palette } from "../../hdm/themes";
import CurrentSelectedStore from "../custom-components/styled-current-selected-store/CurrentSelectedStore";

import { useNavigate } from "react-router";
import EspotHdm from "../custom-components/shared/EspotHdm";
import { useSite } from "../../_foundation/hooks/useSite";

export const StyledBoxHDM = styled((props: any) => <StyledBox {...props} />)`
  ${({ theme, isB2B }) => `
     
      &.header{
        display: flex;
        align-items: center;
        justify-content: space-around;
        ${theme.breakpoints.down(1024)}{
            margin-left: 0px;
        }
        & .header__logo{
            cursor: pointer;
            width: ${isB2B ? "80px" : "43px"};
            height: ${isB2B ? "35px" : "43px"};
            img{
              width: ${isB2B ? "65px" : "43px"};
              height: ${isB2B ? "25px" : "43px"};      
              ${
                isB2B
                  ? `
                margin-left: 10px;
                margin-right: 5px;
                margin-bottom: 10px;
                `
                  : `
                margin-left: 10px;
              `
              }
              
            }
            ${theme.breakpoints.up(1024)}{
              width:${isB2B ? "176px" : "60px"};
              height: ${isB2B ? "80px" : "60px"};
              img{
                width: ${isB2B ? "156px" : "60px"};
                height: 60px;    
                ${
                  isB2B
                    ? `
                  margin: 10px;
                  `
                    : `
                `
                }
                }
            }
        }

        & .header__address{
            padding: ${theme.spacing(2)}px;
            ${
              isB2B
                ? `
              `
                : `
              margin-left: 10px;
              `
            }
            cursor: pointer;
            &.header__address-b2b {
              margin-right: ${theme.spacing(2)}px;
              ${theme.breakpoints.down("md")} {
                margin-right: ${theme.spacing(0)}px;
              }
            }
            & .header__address-store{
                display: flex;
                align-items: center;
                justify-content: normal;
                & .header__address-store-name{
                    display: flex;
                    align-items: center;
                    justify-content: normal;
                    & .MuiSvgIcon-root{
                        font-size: 18px;
                        color: ${palette.primary.main};
                    }
                    & .MuiTypography-root{
                        color: ${palette.secondary.main};
                    }
                }

                & .MuiSvgIcon-root{
                    font-size: 14px;
                    color: #00A950;
                }
            }
        }
      }
      .header_expandMoreIcon_small{
        font-size: 14px !important;
      }
  `}
`;

const HeaderAddress = (props) => {
  const navigate = useNavigate();

  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);

  const redirectHome = () => {
    navigate("/", { replace: true });
  };

  const MemoizedHeaderLogo = useMemo(()=>{
    return <EspotHdm espotName={isB2B ? "ProMainHeader_Logo" : "MainHeader_Logo"} />
  },[])

  return (
    <StyledBoxHDM className={"header"} isB2B={isB2B}>
      <StyledBox className={"header__logo"} onClick={() => redirectHome()}>
        {MemoizedHeaderLogo}
      </StyledBox>
      <StyledBox className={`header__address ${isB2B ? "header__address-b2b" : ""}`} onClick={props.openDrawer} id="storeSelectorBlock">
        <CurrentSelectedStore storeDetails={props.currentStore} variant={"full"} isHeader={true} />
      </StyledBox>
    </StyledBoxHDM>
  );
};
export default HeaderAddress;
