/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
//Custom libraries
import FormattedPriceDisplay from "../formatted-price-display";
//UI
import { StyledGrid, StyledTypography, StyledButton } from "../../../hdm";
import { Box, Divider } from "@material-ui/core";
import StyledPriceFormat from "../../custom-components/styled-price-format/StyledPriceFormat";
import { SignInMini } from "../sign-in/SignInMini";

import { StyledStickyPaper } from "../../custom-components/styled-sticky-paper";
import { StyledCartContainer } from "../cart/StyledCartContainer";
import StyledSwipeableDrawerHDM from "../../custom-components/styled-drawer-hdm/StyledDrawerHDM";
import { loginStatusSelector } from "../../../redux/selectors/user";
import { CONSTANTS } from "../../../constants/order-item-table";
import { StyledPromp } from "../../custom-components/styled-promp";
import { giftCardSelector, isFetchingSelector, orderItemsSelector } from "../../../redux/selectors/order";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { Skeleton } from "@material-ui/lab";
import EspotHdm from "../../custom-components/shared/EspotHdm";
import { StyledWishListButton } from "../../custom-components/styled-wishList-button";
import { useSite } from "../../../_foundation/hooks/useSite";
import withSkeletonLoader from "../../custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { loaders } from "../../custom-components/skeleton-loader/components";
import { backdropLoaderStateAndSrcSelector } from "../../../redux/selectors/backdrop-loader";
import { LoaderActions } from "../../custom-components/skeleton-loader/actions/sl-actions";
import { EMPTY_STRING } from "../../../constants/common";

interface OrderTotalSummaryProps {
  order: any;
  canContinue?: any;
  showDrawer?: any;
  checkout?: any;
  isCheckout?: boolean;
  totalOnly?: boolean;
  variant?: boolean;
  hideTotals?: boolean;
  isTYP?: boolean;
  currentStep?: string;
  deliveryMethod?: string;
  isDrawerOpen?: boolean;
  readOnlyShipping?: boolean;
}

/**
 * Order total summary component
 * displays order total summary section including subtotal, taxes, shipping, discounts and total, if applied
 * @param props
 */
const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: "#e0e0e0",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

const OrderTotalSummary: React.FC<OrderTotalSummaryProps> = (props: any) => {
  const { t } = useTranslation();
  const loginStatus = useSelector(loginStatusSelector);
  const giftCardData = useSelector(giftCardSelector);
  const orderItems = useSelector(orderItemsSelector);
  const storeConfData = useSelector(onlineStoreRdc);
  const isFetching = useSelector(isFetchingSelector);
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);

  const isDrawerOpen = props?.isDrawerOpen;
  const canContinue = props?.canContinue;
  const showDrawer = props?.showDrawer;
  const checkout = props?.checkout;
  const isCheckout = props.isCheckout ? props.isCheckout : false;
  const isTYP = props.isTYP ? props.isTYP : false;
  const totalOnly = props.totalOnly ? props.totalOnly : false;
  const order = props.order;
  const hideTotals = props.hideTotals ? props.hideTotals : false;
  const { subtotal, tax, shipping, shippingTax, totalDiscounts, services, grandTotal, totalTax } =
    initOrderTotalSummary();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);
  const deliveryMethod = props.deliveryMethod ? props.deliveryMethod : "";
  const currentStep = props.currentStep ? props.currentStep : "0";
  const readOnlyShipping = props?.readOnlyShipping;

  const loaderState = useSelector(backdropLoaderStateAndSrcSelector);
  const giftCardOrder = order?.paymentInstruction?.find( payment => payment?.payMethodId == "GiftCard");
  const giftCardUsedBalance = isB2B && isTYP && giftCardOrder?.protocolData?.find(data => data.name === "giftCardUsedBalance")

  const openDrawerOrderContinue = () => {
    setOpenDrawer(true);
  };

  const isValidLoader = () => {
    return loaderState.src === LoaderActions.PRODUCT_QUANTITY_UPDATE ||
      loaderState.src === LoaderActions.ADD_PRODUCT_TO_CART ||
      loaderState.src === LoaderActions.ADD_TV_TO_CART
  }

  const NeedAtentionComponent = (props) => {
    const grandTotal = props.grandTotal;
    if (!canContinue() && !isTYP && !isCheckout) {
      if (storeConfData?.maxShopCartTotal && parseInt(grandTotal) > parseInt(storeConfData.maxShopCartTotal)) {
        return (
          <StyledGrid xs={12} className={"bottom-margin-1"}>
            <StyledPromp variant="alert" message={t("productDetail.CartOrderAmountLimitExceded")} />
          </StyledGrid>
        );
      }
      return (
        <StyledGrid xs={12} className={"bottom-margin-1"}>
          <StyledPromp variant="checkoutAlert" message={t("productDetail.CartOrderNeedAtention")} />
        </StyledGrid>
      );
    } else {
      return <></>;
    }
  };

  const toggleSignInRegisterpage = (flag: boolean) => {
    setShowSignIn(flag);
  };
  /**
   * Initialize the summary data
   */

  function initOrderTotalSummary() {
    let subtotal: number | null = null;
    let tax: number | null = null;
    let shipping: number | null = null;
    let shippingTax: number | null = null;
    let totalDiscounts: number = 0;
    let grandTotal: number | null = null;
    let totalTax: number = 0;
    let services: number = 0;
    if (order) {
      try {
        const orderData = isTYP === true ? order?.orderItem : orderItems;
        console.log("orderItems ", orderItems);
        const taxDivFactor = orderData[0]?.xitem_taxRate
          ? orderData[0].xitem_taxRate / 100 + 1
          : storeConfData.TAX_DIVISION_FACTOR;
        // The promotion taxes value is not registered in the order or orderittems rest
        totalDiscounts = 0;
        orderData.forEach((orderItem) => {
          if (parseFloat(orderItem.salesTax) === 0) {
            if (orderItem?.adjustment?.length > 0) {
              let hasGift = false;
              orderItem?.adjustment?.forEach((adjustment) => {
                if (adjustment?.description === "Artículo de Regalo") {
                  totalDiscounts = totalDiscounts + adjustment?.amount * -1;
                  hasGift = true;
                }
              });
              if (hasGift) return;
            }

            totalDiscounts = totalDiscounts + orderItem.totalAdjustment.value * -1;
          } else {
            totalDiscounts = totalDiscounts + orderItem.totalAdjustment.value * taxDivFactor * -1;
          }
        });

        subtotal =
          parseFloat(order.totalProductPrice) +
          parseFloat(order.totalSalesTax) +
          parseFloat(order.totalAdjustment) +
          totalDiscounts;

        totalDiscounts = totalDiscounts * -1;

        tax = order.totalSalesTax ? parseFloat(order.totalSalesTax) : null;
        if (tax !== null && subtotal !== null) {
          totalTax = tax;
        }
        shipping = order.totalShippingCharge ? parseFloat(order.totalShippingCharge) : null;
        shippingTax = order.totalShippingTax ? parseFloat(order.totalShippingTax) : null;
        if (shippingTax !== null) {
          totalTax = totalTax + shippingTax;
          if (shipping !== null) {
            shipping = shipping + shippingTax;
          }
        }
        grandTotal = order.grandTotal ? parseFloat(order.grandTotal) : null;
      } catch (e) {
        console.log("Could not parse order total summary");
      }
    }
    const orderData = isTYP === true ? order?.orderItem : orderItems;
    if (orderData.length > 0) {
      const installationItems = orderData.filter(
        (order) => order.partNumber.includes("SI") || order.partNumber.includes("TV")
      );
      if (installationItems.length > 0) {
        for (let i = 0; i < installationItems.length; i++) {
          services += parseFloat(installationItems[i].orderItemPrice) + parseFloat(installationItems[i].salesTax);
        }
        if (subtotal) {
          subtotal = subtotal - services;
        }
      }
    }
    return {
      subtotal,
      tax,
      shipping,
      shippingTax,
      totalDiscounts,
      grandTotal,
      totalTax,
      services,
    };
  }

  // console.log("testing", order)
  // console.log("testing", totalDiscounts)

  const contentGrandTotal = (
    <>
      {grandTotal != null ? (
        <>
          <StyledGrid item xs={6} className={"summary--typography-totalMargin"}>
            <StyledTypography variant="bodyBaseline" weight="bold">
              {t("OrderTotalSummary.Labels.Total")}
            </StyledTypography>
            <StyledTypography variant="bodyCaption" className={"summary--typography-taxIncludedColor top-margin-2"}>
              {t(`OrderTotalSummary.Labels.TaxIncluded`) + " "}
              {((isCheckout && currentStep === "2") || isTYP) && (
                <>
                  <FormattedPriceDisplay min={totalTax} currency={order.totalProductPriceCurrency} />
                </>
              )}
            </StyledTypography>
          </StyledGrid>
          <StyledGrid item xs={6} align="right" className={"summary--typography-totalMargin"}>
            <StyledTypography variant="textPrice">
              {/*Grand total or giftCard price*/}
              {withSkeletonLoader(
                loaders.secondary.miniCart.price,
                loaderState.open && isValidLoader(),
                <StyledPriceFormat
                  priceUnformatted={
                    giftCardData
                      ? giftCardData.remainingAmount
                      : giftCardUsedBalance && grandTotal - giftCardUsedBalance?.value == 0
                      ? "0.00"
                      : giftCardUsedBalance && giftCardUsedBalance?.value
                      ? grandTotal - giftCardUsedBalance?.value
                      : grandTotal
                  }
                  giftCardUsed={giftCardUsedBalance ? giftCardUsedBalance : EMPTY_STRING}
                />,
                {
                  omitTimeout: true,
                }
              )}
            </StyledTypography>
          </StyledGrid>
        </>
      ) : (
        <> </>
      )}
    </>
  );

  const stickyContentGrandTotal = (
    <>
      <NeedAtentionComponent grandTotal={grandTotal} />
      <StyledGrid className="summary--grid-stickySummary">
        {!isDrawerOpen ? (
          <>
            <Puller />
            {contentGrandTotal}
          </>
        ) : null}
      </StyledGrid>

      {!isCheckout && (
        <>
          <StyledButton
            disabled={!canContinue()}
            onClick={showDrawer}
            id={"cart--orderTotalSummary-stickyCheckoutButton"}>
            {t("OrderTotalSummary.Labels.Continue")}
          </StyledButton>

          {mySite.isB2B && (
            <StyledWishListButton
              partNumber={orderItems}
              isWishProduct={false}
              variant={"checkout-orderSummary"}
              placement={"top"}
            />
          )}
        </>
      )}
    </>
  );

  return (
    <>
      <StyledCartContainer>
        <StyledGrid container>
          {!totalOnly && (
            <>
              {subtotal ? (
                <>
                  <StyledGrid item xs={6}>
                    <StyledTypography variant="bodyBaseline" className={"bottom-margin-2"}>
                      {t("OrderTotalSummary.Labels.Subtotal") + " "}
                      {isTYP && t("OrderTotalSummary.Labels.SubtotalOrder")}
                    </StyledTypography>
                  </StyledGrid>
                  <StyledGrid item xs={6} align="right">
                    {withSkeletonLoader(
                      loaders.secondary.miniCart.price,
                      loaderState.open && isValidLoader(),
                      <StyledTypography variant="bodyBaseline">
                        <FormattedPriceDisplay
                          idComponent="orderSubtotal"
                          min={subtotal}
                          currency={order.totalProductPriceCurrency}
                        />
                      </StyledTypography>,
                      {
                        omitTimeout: true
                      }
                    )}
                  </StyledGrid>
                </>
              ) : (
                <></>
              )}
              {giftCardData && (
                <>
                  <StyledGrid item xs={6} className="top-margin-2">
                    <StyledTypography variant="bodyBaseline">
                      {"Tarjeta de regalo o cambio" + " "}
                      {isTYP && t("OrderTotalSummary.Labels.SubtotalOrder")}
                    </StyledTypography>
                  </StyledGrid>
                  <StyledGrid item xs={6} align="right" className="top-margin-2">
                    <StyledTypography variant="bodyBaseline">
                      <FormattedPriceDisplay
                        min={parseFloat(giftCardData.remainingAmount) > 0.0 ? giftCardData.availableAmount : grandTotal}
                        currency={order.totalProductPriceCurrency}
                        priceSign={"-"}
                        highlight
                      />
                    </StyledTypography>
                  </StyledGrid>
                </>
              )}
              {/*tax != null && tax != 0 && (
                <>
                  <StyledGrid item xs={6}>
                    <StyledTypography gutterBottom>{t("OrderTotalSummary.Labels.Tax")}</StyledTypography>
                  </StyledGrid>
                  <StyledGrid item xs={6}>
                    <StyledTypography gutterBottom align="right">
                      <FormattedPriceDisplay min={tax} currency={order.totalSalesTaxCurrency} />
                    </StyledTypography>
                  </StyledGrid>
                </>
              )*/}
              {shipping !== null &&
                deliveryMethod !== CONSTANTS.bopis &&
                ((shipping === 0 && currentStep === "2" && isCheckout) || shipping > 0 || isTYP) && (
                  <>
                    <StyledGrid item xs={6} className="top-margin-2">
                      <StyledTypography variant="bodyBaseline" weight={isB2B && shipping !== 0 && !isTYP? "semibold" : ""}>
                        {t("OrderTotalSummary.Labels.Shipping")}
                      </StyledTypography>
                    </StyledGrid>
                    <StyledGrid item xs={6} className="top-margin-2">
                      {isFetching === false ? (
                        <>
                          {shipping === 0 ? (
                            <StyledTypography
                              id="shippingTotal"
                              align="right"
                              variant="bodyBaseline"
                              className={"orderSummary--styledTypography-highlight"}>
                              <>{t("OrderTotalSummary.Labels.Free")}</>
                            </StyledTypography>
                          ) : (
                            <StyledTypography align="right" variant="bodyBaseline" className="orderSummary-shipping-cost">
                              <FormattedPriceDisplay
                                idComponent="shippingTotal"
                                min={shipping}
                                currency={order.totalShippingChargeCurrency}
                              />
                            </StyledTypography>
                          )}
                        </>
                      ) : (
                        <Skeleton width="50px" style={{ float: "right" }} />
                      )}
                    </StyledGrid>
                  </>
                )}
              {/*shippingTax != null && shippingTax != 0 && (
                <>
                  <StyledGrid item xs={6}>
                    <StyledTypography gutterBottom>{t("OrderTotalSummary.Labels.ShippingTax")}</StyledTypography>
                  </StyledGrid>
                  <StyledGrid item xs={6}>
                    <StyledTypography gutterBottom align="right">
                      <FormattedPriceDisplay min={shippingTax} currency={order.totalShippingTaxCurrency} />
                    </StyledTypography>
                  </StyledGrid>
                </>
              )*/}
              {order?.x_installationService == "true" && (
                <>
                  {services !== 0 ? (
                    <>
                      <StyledGrid item xs={6} className="top-margin-2">
                        <StyledTypography variant="bodyBaseline">
                          {t("OrderTotalSummary.Labels.Services") + " "}
                        </StyledTypography>
                      </StyledGrid>
                      <StyledGrid item xs={6} align="right" className="top-margin-2">
                        <StyledTypography variant="bodyBaseline">
                          <FormattedPriceDisplay min={services} currency={order.totalProductPriceCurrency} />
                        </StyledTypography>
                      </StyledGrid>
                    </>
                  ) : (
                    <>
                      <StyledGrid item xs={6} className="top-margin-2">
                        <StyledTypography variant="bodyBaseline">
                          {t("OrderTotalSummary.Labels.Services") + " "}
                        </StyledTypography>
                      </StyledGrid>
                      <StyledGrid item xs={6} align="right" className="top-margin-2 free-installation-cart-text">
                        <StyledTypography weight="semibold" variant="bodyBaseline">
                          {t("OrderTotalSummary.Labels.Free") + " "}
                        </StyledTypography>
                      </StyledGrid>
                    </>
                  )}
                </>
              )}
              {totalDiscounts !== null && totalDiscounts !== 0 && (
                <>
                  <StyledGrid item xs={6} className={"summary--grid-marginDiscounts"}>
                    <StyledTypography
                      className="total-discount orderSummary--styledTypography-highlight"
                      variant="bodyBaseline">
                      {t("OrderTotalSummary.Labels.Discount")}
                    </StyledTypography>
                  </StyledGrid>
                  <StyledGrid item xs={6} className={"summary--grid-marginDiscounts"}>
                    <StyledTypography
                      className="total-discount orderSummary--styledTypography-highlight"
                      variant="bodyBaseline"
                      align="right">
                      <FormattedPriceDisplay min={totalDiscounts * -1} currency={order.totalAdjustmentCurrency} />
                    </StyledTypography>
                  </StyledGrid>
                </>
              )}
              {giftCardUsedBalance && (
                <>
                  <StyledGrid item xs={6} className="top-margin-2">
                    <StyledTypography variant="bodyBaseline">
                      {t("OrderTotalSummary.Labels.GiftCard")}
                    </StyledTypography>
                  </StyledGrid>
                  <StyledGrid item xs={6} align="right" className="top-margin-2">
                    <StyledTypography variant="bodyBaseline">
                      <FormattedPriceDisplay
                        min={parseFloat(giftCardUsedBalance?.value)}
                        currency={order.totalProductPriceCurrency}
                        priceSign={"-"}
                        highlight
                      />
                    </StyledTypography>
                  </StyledGrid>
                </>
              )}
              {mySite?.isB2B && isCheckout && deliveryMethod === CONSTANTS.bopis && readOnlyShipping && (
                <>
                  <StyledGrid item xs={6} className="top-margin-1">
                    <StyledTypography variant="bodyBaseline">
                      {t("OrderTotalSummary.Labels.ShippingBopis")}
                    </StyledTypography>
                  </StyledGrid>
                  <StyledGrid item xs={6} className="top-margin-1">
                    <StyledTypography
                      id="shippingTotal"
                      align="right"
                      variant="bodyBaseline"
                      className={"orderSummary--styledTypography-highlight"}>
                      <>{t("OrderTotalSummary.Labels.Free")}</>
                    </StyledTypography>
                  </StyledGrid>
                </>
              )}
            </>
          )}
          {grandTotal != null ? (
            <>
              <StyledGrid item xs={12}>
                <Divider className="vertical-margin-2"/>
              </StyledGrid>
              <NeedAtentionComponent grandTotal={grandTotal} />
              {contentGrandTotal}
            </>
          ) : (
            <></>
          )}
        </StyledGrid>
        {!hideTotals && (
          <>
            <StyledStickyPaper content={stickyContentGrandTotal} id={"cart--orderTotalSummary-stickyContainer"} />
          </>
        )}
      </StyledCartContainer>
    </>
  );
};

export { OrderTotalSummary };
