import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Axios, { Canceler } from "axios";
import SearchIcon from "@material-ui/icons/Search";
import { Divider } from "@material-ui/core";
import { palette } from "../../themes/color-palette";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import * as customInv from "../../../_foundation/utils/inventoryAvailability";

import CheckIcon from "@material-ui/icons/Check";

import styled from "styled-components";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";

import {
  StyledSelect,
  StyledMenuItem,
  StyledFormControl,
  StyledInputLabel,
  StyledGrid,
  StyledSwipeableDrawer,
  StyledBox,
} from "../../elements";
import { AllowScroll, DisableScroll, logEventWithValues } from "../../functions/utils";
import { useSite } from "../../../_foundation/hooks/useSite";
import { pdpSeoRdc, hdmStoresDataRdc } from "../../../redux/selectors/hdmData";
import { SET_CURRENT_STORE_ACTION } from "../../../redux/actions/current-store";
import jsonStateCityList from "../../../components/custom-components/styled-store-selector/json-stores-info/StoreLocatorStateCitiesList.json";

import {
  ContainerStyledBox,
  ContainerStyledTypography,
  ContainerStyledButton,
  ContainerStyledRadioCardContainer,
  ContainerStyledUncheckedRadioIcon,
  ContainerStyledSuccessRoomIcon,
  ContainerStyledSuccessIconRadio,
  ContainerStylediv,
  StyledSwipeableDrawersDisponibilidad,
} from "./containers-disponibilidad";

import StyledSwipeableDrawerHDM from "../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";
import BuscarEstadoCiudad from "./BuscarEstadoCiudad";
import { useTranslation } from "react-i18next";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { SLPDPSearchOtherStores } from "../../../components/custom-components/skeleton-loader/components/elements/PDP";
import { setCookie } from "../../../components/custom-components/styled-store-selector/cookieHandler";

const StyledKeyboardArrowDown = styled(({ ...props }) => <KeyboardArrowDown {...props} />)`
  ${({ theme }) => `
  font-size: 24px;
`}
`;

const ContainerStyledGrid = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
  
  &.storeLocator--styledGrid-arrowSelect {
    display: flex;
    width: 340px;
    justify-content: flex-end;
    vertical-align: middle;
  }
`}
`;

const RowIconSpan = styled(({ ...props }) => <span {...props} />)`
  ${({ theme }) => `
  width: ${theme.spacing(5)}px;
  padding-top: 7px;
  height: 40px;
  display: inline-block;
  text-align: center;
  margin-right: ${theme.spacing(1)}px;
  z-index: -1;
}


`}
`;

interface Props {
  marketInventory: any;
  currentStoreId: any;
  productId: any;
  partNumber: any;
}

const BuscarEnOtraTienda = (props: Props) => {
  const { marketInventory, currentStoreId, productId, partNumber } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [tiendas, setTiendas] = useState<any>([]);
  const { mySite } = useSite();
  const dispatch = useDispatch();
  const seoRdc = useSelector(pdpSeoRdc);
  const hdmStoresData = useSelector(hdmStoresDataRdc);
  const navigate = useNavigate();
  const cityList = jsonStateCityList.formScheduleObject;
  const currentStore = useSelector(currentStoreSelector);
  const currentMarketId = currentStore && currentStore?.currentStore?.marketId;
  const storeId: string = mySite ? mySite.storeID : "";
  const cancels: Canceler[] = useMemo<Canceler[]>(() => [], []);
  const isB2B: boolean = mySite?.isB2B ? mySite.isB2B : false;

  const CancelToken = Axios.CancelToken;

  const payloadBase: any = {
    storeId: storeId,
    // widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const handleSetTiendas = (data: any) => {
    setTiendas(data);
  };

  const handleOpenBuscarOtraTienda = () => {
    setIsOpen(true);
    
    setTiendas([]);
  };

  const handleCloseBuscarOtraTienda = () => {
    setIsOpen(false);
  };

  const handleGetMarketInventory = async () => {
    // Remove filter by Market ID, adding filter by State only when searching current State
    //let allTiendasIds = hdmStoresData.filter((mktId) => mktId.marketId === currentMarketId);

    let allTiendasIds = hdmStoresData.filter((mktId) => mktId.stLocName.trim() === currentStore.currentStore.stLocName.trim());

    const AllUniqueFetchIds: any = [];

    const physicalStoreUniqueId = allTiendasIds.find(
      (phyStore) => phyStore?.stLocId === currentStore?.currentStore?.stLocId
    );

    
    allTiendasIds = allTiendasIds
    // .filter((store) => store?.stLocId !== currentStore?.currentStore?.stLocId)
    .map((tienda) => {
      AllUniqueFetchIds.push(tienda.uniqueID);
      return tienda
    });
    
    const parameters: any = {
      ...payloadBase,
      productIds: productId,
    };

    const newAllStores = allTiendasIds.map((store) => {
      return {
        x_AvailableQuantityInMarket: "0.00",
        availableQuantity: "0.0",
        availabilityDateTime: "",
        physicalStoreName: store.stLocId,
        productId: productId,
        unitOfMeasure: "EA",
        inventoryStatus: "Unavailable",
        physicalStoreId: store.uniqueID,
        partNumber: partNumber,
        x_NextAvailableInventoryDate: "",
        x_UniqueId: "1",
        x_itemFulFillmentType: "Store",
      };
    });

    const tiendas: any = [];
    // if (marketInventory && marketInventory.length !== 0) {
      
    //   marketInventory.map((disponibilidad) => {
    //     let storedata = hdmStoresData.filter((hdmStore) => hdmStore.stLocId === disponibilidad.physicalStoreName)[0];
    //     storedata = JSON.parse(JSON.stringify(storedata));
    //     if(storedata?.availableQuantity && parseInt(storedata?.availableQuantity) > 0){
    //       storedata.availableQuantity = parseInt(storedata?.availableQuantity)
    //     } else if(storedata?.x_customField2 && parseInt(storedata?.x_customField2) > 0){
    //       storedata.availableQuantity = parseInt(storedata?.x_customField2)
    //     } else {
    //       storedata.availableQuantity = 0
    //     }
    //     storedata.availableQuantity = disponibilidad.availableQuantity;
    //     tiendas.push(storedata);
    //   });

    // }

    if (AllUniqueFetchIds && AllUniqueFetchIds.length !== 0) {

      const disponibilidadStoreMarket = await customInv
      .getAvailabilityByStores({
        ...parameters,
        onlineStoreId: mySite.storeID,
        productavailable: true,
        fullfillment_type: "Store",
        type: "ItemBean",
        search: 2,
        physicalStoreId: [AllUniqueFetchIds]
      })
      .then((res) => {
        if (res?.length !== 0) {
          return res.filter((inv) => inv?.physicalStoreId);
        }
      })
      .catch((e) => {
        console.debug("Cannot get market inventorys.");
      });

      if(disponibilidadStoreMarket && disponibilidadStoreMarket.length !== 0){

        disponibilidadStoreMarket.map((disponibilidad) => {
          let storedata = hdmStoresData.filter((hdmStore) => hdmStore.stLocId === disponibilidad.physicalStoreName)[0];
          storedata = JSON.parse(JSON.stringify(storedata));

          storedata.availableQuantity = disponibilidad.availableQuantity;
          storedata.storeName = storedata.storeName.trim()
          tiendas.push(storedata);
        });
      }else {

        newAllStores.map((disponibilidad) => {
          let storedata = hdmStoresData.filter((hdmStore) => hdmStore.stLocId === disponibilidad.physicalStoreName)[0];
          storedata = JSON.parse(JSON.stringify(storedata));
          storedata.availableQuantity = disponibilidad.availableQuantity;
          storedata.storeName = storedata.storeName.trim()
          tiendas.push(storedata);
        });

      }

    } 
        
    if(tiendas.length !== 0){
      setTiendas(tiendas.sort((a, b) => (a.storeName.toLowerCase() > b.storeName.toLowerCase()) ? 1 : -1));
      
    }else {
      setTiendas([]);      
    }
    

  }

  useEffect(() => {
    if(!window.location.pathname.includes("/p/")){
      if (isOpen) {
        if(tiendas?.length === 0){
          handleGetMarketInventory()
        }
        DisableScroll();
      } else {
        AllowScroll();
      }
    }else {
      if (isOpen) {
        if(tiendas?.length === 0){
          handleGetMarketInventory()
        }
      }
    }

    return () => {
      // setTiendas([]);
    };
  }, [isOpen]);

  const handleCambiarTienda = (data) => {
    logEventWithValues("set_store", data)
    Axios.get(
      `/wcs/resources/store/${mySite.storeID}/storelocator/latitude/${data.latitude}/longitude/${data.longitude}?maxItems=1&radius=1&langId=-5`
    )
    .then((response) => response.data)
    .then((res) => {

      if (parseInt(res.recordSetCount) > 0) {
        const findStores = cityList.filter((states: any) =>
          states.stores.some((city) => city.stLocId === res.PhysicalStore[0].storeName)
        )[0];

        const setDefaultStorePayload = {
          defaultStore: data.stLocId,
          fromPageName: "2",
        };
        Axios.post(
          `/wcs/resources/store/${mySite.storeID}/hdm/store/setDefault?responseFormat=json`,
          setDefaultStorePayload
        )
        .then((response) => response.data)
        .then((res2) => {
          const payload = {
            findStore: {
              ...data,
              stateId: findStores.stateId,
              uniqueID: res.PhysicalStore[0].uniqueID,
              isExternal: true,
            },
          };
          if (res?.PhysicalStore) {
            if(isB2B){
              setCookie("WC_MarketId_11401", payload.findStore.marketId, 1)            
              setCookie("WC_DefaultStore_11401", payload.findStore.stLocId, 1)
            } else {
              setCookie("WC_MarketId_10351", payload.findStore.marketId, 1)            
              setCookie("WC_DefaultStore_10351", payload.findStore.stLocId, 1)
            }
            dispatch(SET_CURRENT_STORE_ACTION(payload));
            // window.location.reload();
            
          }
        });
      } else {
        const findStores = cityList.filter((states: any) =>
          states.stores.some((city) => city.stLocId === data.stLocId)
        )[0];
        const payload = {
          findStore: {
            ...data,
            stateId: findStores.stateId,
            uniqueID: data.uniqueID,
            isExternal: true,
          },
        };
        if(isB2B){
          setCookie("WC_MarketId_11401", payload.findStore.marketId, 1)            
          setCookie("WC_DefaultStore_11401", payload.findStore.stLocId, 1)
        } else {
          setCookie("WC_MarketId_10351", payload.findStore.marketId, 1)            
          setCookie("WC_DefaultStore_10351", payload.findStore.stLocId, 1)
        }
        dispatch(SET_CURRENT_STORE_ACTION(payload));
        // window.location.reload();
      }

      if (seoRdc && seoRdc !== "") {
        navigate(seoRdc);
      }
    });
  };


  return (
    <>
      <ContainerStyledBox width={215}>
        <ContainerStyledButton
          className="availability-PDP"
          id="availabilityStoresLink"
          startIcon={<SearchIcon style={{ fontSize: "15px" }} />}
          style={{ color: "#3E7697", padding: 0 }}
          variant="text"
          disableRipple={true}
          onClick={handleOpenBuscarOtraTienda}>
          Buscar en otra tienda
        </ContainerStyledButton>
      </ContainerStyledBox>

      <StyledSwipeableDrawersDisponibilidad
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchor="right"
        disableEnforceFocus>
        <ContainerStylediv style={{ height: "100%", margin: "0px" }}>
          <ContainerStylediv className="wrapper">
            <ContainerStylediv>
              <ContainerStyledBox py={"10px"} style={{}}>
                <ContainerStyledTypography variant={"textSegment"} align="center" weight="light">
                  Buscar disponibilidad
                </ContainerStyledTypography>

                <ContainerStyledBox style={{ position: "absolute", right: "0px", top: "0px", padding: "10px" }}>
                  <HighlightOffIcon
                    style={{ color: palette.gray["100"], cursor: "pointer" }}
                    onClick={handleCloseBuscarOtraTienda}
                  />
                </ContainerStyledBox>
              </ContainerStyledBox>
              <Divider style={{ backgroundColor: palette.gray["50"] }} />

              <ContainerStyledBox>
                <ContainerStyledTypography variant="bodyBaseline" style={{ padding: "10px" }}>
                  A continuación te mostraremos la disponibilidad en las tiendas de la ciudad que seleccionaste
                </ContainerStyledTypography>
              </ContainerStyledBox>

              <Divider style={{ color: palette.gray["50"] }} />
            </ContainerStylediv>

            {tiendas?.length === 0 ?
            <>
            {[1,2,3,4,5].map( ski => (<SLPDPSearchOtherStores />))}
            </>
            :
            <ContainerStylediv className="content">
              <ContainerStylediv style={{}}>
                {tiendas &&
                  tiendas.map((tienda) => {
                    const cantidad = parseInt(tienda.availableQuantity);
                    let cantidadWording = t("productDetail.Availability.labels.availableQuantity", {
                      n: `${cantidad ? cantidad : 0}`,
                    });
                    // let wording = "para compra en línea";
                    // let iconClass = "check-enabled";
                    if (cantidad > 199) {
                      cantidadWording = `+199 disponibles`;
                    }

                    if (currentStoreId === tienda.stLocId) {
                      return (
                        <ContainerStyledBox key={tienda.stLocId}>
                          <StyledBox p={2}>
                            <ContainerStyledBox>
                              <ContainerStyledTypography
                                variant="bodyBaseline"
                                fontWeight={600}
                                style={{ color: palette.highlight.main }}
                                component="span">
                                {cantidadWording}&nbsp;
                              </ContainerStyledTypography>
                              <ContainerStyledTypography variant="bodyBaseline" component="span">
                                en&nbsp;
                              </ContainerStyledTypography>
                              <ContainerStyledTypography variant="bodyBaseline" fontWeight={600} component="span">
                                {tienda.storeName} #{tienda.stLocId}
                              </ContainerStyledTypography>
                            </ContainerStyledBox>

                            <ContainerStyledBox mt={1}>
                              <ContainerStyledTypography variant="bodySubText" style={{ color: palette.gray["300"] }}>
                                {tienda.address1}, {tienda.address2}. {tienda.city}, {tienda.stLocName} C.P.{" "}
                                {tienda.zipcode}
                              </ContainerStyledTypography>
                            </ContainerStyledBox>
                          </StyledBox>

                          <ContainerStyledBox p={2}>
                            <ContainerStyledRadioCardContainer className={"container storeChecked"}>
                              <ContainerStyledRadioCardContainer item xs={8}>
                                <ContainerStyledSuccessRoomIcon />
                                <ContainerStyledTypography
                                  variant="bodySubText"
                                  className={"storeSelector--styledTypography-primary"}>
                                  Tienda selecionada
                                </ContainerStyledTypography>
                              </ContainerStyledRadioCardContainer>
                              <ContainerStyledRadioCardContainer item xs={4} className={"successIconRadio"}>
                                <ContainerStyledSuccessIconRadio>
                                  <CheckIcon style={{ color: palette.text.expandedMenu, width: 20, height: 20 }} />
                                </ContainerStyledSuccessIconRadio>
                              </ContainerStyledRadioCardContainer>
                            </ContainerStyledRadioCardContainer>
                          </ContainerStyledBox>
                        </ContainerStyledBox>
                      );
                    }

                    return (
                      <ContainerStyledBox key={tienda.stLocId}>
                        <StyledBox p={2}>
                          <ContainerStyledBox >
                            <ContainerStyledTypography
                              variant="bodyBaseline"
                              fontWeight={600}
                              style={{ color: palette.highlight.main }}
                              component="span">
                              {cantidadWording}&nbsp;
                            </ContainerStyledTypography>
                            <ContainerStyledTypography variant="bodyBaseline" component="span">
                              en&nbsp;
                            </ContainerStyledTypography>
                            <ContainerStyledTypography variant="bodyBaseline" fontWeight={600} component="span">
                              {tienda.storeName} #{tienda.stLocId}
                            </ContainerStyledTypography>
                          </ContainerStyledBox>

                          <ContainerStyledBox mt={1}>
                            <ContainerStyledTypography variant="bodySubText" style={{ color: palette.gray["300"] }}>
                              {tienda.address1}, {tienda.address2}. {tienda.city}, {tienda.stLocName} C.P.{" "}
                              {tienda.zipcode}
                            </ContainerStyledTypography>
                          </ContainerStyledBox>
                        </StyledBox>


                        <ContainerStyledBox p={2}>
                          <ContainerStyledRadioCardContainer
                            className={"container"}
                            onClick={() => handleCambiarTienda(tienda)}>
                            <ContainerStyledUncheckedRadioIcon fontSize={"small"} />
                            <ContainerStyledTypography variant="bodySubText" style={{ color: palette.secondary.main }}>
                              Seleccionar tienda
                            </ContainerStyledTypography>
                          </ContainerStyledRadioCardContainer>
                        </ContainerStyledBox>
                      </ContainerStyledBox>
                    );
                  })}
              </ContainerStylediv>
            </ContainerStylediv>
            }

            {tiendas?.length === 0 ?
            null
            :
            <ContainerStylediv>
              <BuscarEstadoCiudad handleSetTiendas={handleSetTiendas} productId={productId} />
            </ContainerStylediv>
            }
          </ContainerStylediv>
        </ContainerStylediv>
      </StyledSwipeableDrawersDisponibilidad>
      {/* <StyledSwipeableDrawerHDM isOpen={isOpen} setOpenDrawer={setIsOpen} variant={"disponibilidad"}>
        <ContainerStyledBox style={{height: '100%', margin: 0}}>
          <ContainerStyledBox className="wrapper-buscar">
          <ContainerStyledBox mb={2}>
            <ContainerStyledTypography variant="bodyBaseline">
              A continuación te mostraremos la disponibilidad en las tiendas de la ciudad que seleccionaste
            </ContainerStyledTypography>
          </ContainerStyledBox>

          <Divider style={{ color: palette.gray["50"] }} />

          <ContainerStyledBox className="content-tiendas">

            {tiendas && tiendas.map(tienda => {

              return (

              <ContainerStyledBox m={2} key={tienda.stLocId}>

                <ContainerStyledBox display="flex" mt={2}>
                  <ContainerStyledTypography
                    variant="bodyBaseline"
                    weight="bold"
                    style={{ color: palette.highlight.main }}
                    component="span">
                    +1 disponibles&nbsp;
                  </ContainerStyledTypography>
                  <ContainerStyledTypography variant="bodyBaseline" component="span">
                    en&nbsp;
                  </ContainerStyledTypography>
                  <ContainerStyledTypography variant="bodyBaseline" weight="bold" component="span">
                    {tienda.storeName} #{tienda.stLocId}
                  </ContainerStyledTypography>
                </ContainerStyledBox>

                <ContainerStyledBox mt={1}>
                  <ContainerStyledTypography variant="bodySubText" style={{ color: palette.gray["300"] }}>
                    {tienda.address1}, {tienda.address2}. {tienda.city}, {tienda.stLocName} C.P. {tienda.zipcode}
                  </ContainerStyledTypography>
                </ContainerStyledBox>

                <ContainerStyledBox>
                  <ContainerStyledRadioCardContainer className={"container"}>
                    <ContainerStyledUncheckedRadioIcon fontSize={"small"} />
                    <ContainerStyledTypography variant="bodySubText" style={{ color: palette.secondary.main }}>
                     Selecciona una tienda
                    </ContainerStyledTypography>
                  </ContainerStyledRadioCardContainer>
                </ContainerStyledBox>

                <ContainerStyledBox mt={2}>
                  <ContainerStyledRadioCardContainer className={"container storeChecked"}>
                    <ContainerStyledRadioCardContainer item xs={8}>
                      <ContainerStyledSuccessRoomIcon fontSize={"small"} />
                      <ContainerStyledTypography variant="bodySubText" className={"storeSelector--styledTypography-primary"}>
                        Selecciona una tienda
                      </ContainerStyledTypography>
                    </ContainerStyledRadioCardContainer>
                    <ContainerStyledRadioCardContainer item xs={4} className={"successIconRadio"}>
                      <ContainerStyledSuccessIconRadio>
                        <CheckIcon style={{ color: palette.text.expandedMenu }} fontSize={"small"} />
                      </ContainerStyledSuccessIconRadio>
                    </ContainerStyledRadioCardContainer>
                  </ContainerStyledRadioCardContainer>
                </ContainerStyledBox>
              </ContainerStyledBox>
              )
            })}
          </ContainerStyledBox>
        <BuscarEstadoCiudad handleSetTiendas={handleSetTiendas} />
        </ContainerStyledBox>
        </ContainerStyledBox>
      </StyledSwipeableDrawerHDM> */}
    </>
  );
};

export default BuscarEnOtraTienda;
