import { Dispatch, Fragment, useEffect, useState } from "react";

import { StyledCardEmpty } from "../../elements/card";

import { Divider } from "@material-ui/core";

import { palette } from "../../themes/color-palette";

import StyledSwipeableDrawerHDM from "../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";

import {
  StyledBox,
  StyledFormControl,
  StyledFormControlLabel,
  StyledMenuItem,
  StyledNativeSelect,
  StyledRadio,
  StyledRadioGroup,
  StyledSelect,
  StyledTypography,
} from "../../elements";
import React from "react";
import { useNavigate } from "react-router";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { hdmDrawerColorDataRdc } from "../../../redux/selectors/hdmData";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";
import * as customInv from "../../../_foundation/utils/inventoryAvailability";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import EspotHdm from "../../../components/custom-components/shared/EspotHdm";
import { StyledLinkButton } from "../../../components/custom-components/styled-store-selector/styled-link-button";
import { useTranslation } from "react-i18next";
import { StyledSelectHdm } from "../../../components/custom-components/styled-select/StyledSelectHdm";
import { useSite } from "../../../_foundation/hooks/useSite/useSite";
import Axios, { Canceler } from "axios";
import eSpotService from "../../../_foundation/apis/transaction/eSpot.service";
import SuperSKU from "../../elements/product-card/Styled-product-supersku";
import inventoryavailabilityService from "../../../_foundation/apis/transaction/inventoryavailability.service";
import { BuyByColorDrawer } from "../pdp-comparar-color/BuyByColorDrawer";
import { MenuItemHDM } from "../../../components/header/categories-menu/MenuDesktop";
import { CLOSE_BACKDROP_LOADER_ACTION } from "../../../redux/actions/backdrop-loader";
import { SLPDPHelpForChoose, SLPDPSelectYourOptions } from "../../../components/custom-components/skeleton-loader/components/elements/PDP";

interface Props {
  product: any;
  currentPartNumber: string;
  isTablet: boolean;
}
const SuperSkuContainer = (props: Props) => {
  const { product, currentPartNumber, isTablet } = props;

  const [superSKUAttributes, setSuperSKUAttributes] = useState<any>(null);
  const [itemsAvailability, setItemsAvailability] = useState<any>(null); //initial value null, for change value detection when availability request ends
  const [currentSKUAttributes, setCurrentSKUAttributes] = useState<any>(null);
  const [lastRadioButtonChanged, setLastRadioButtonChanged] = useState<any>(null);
  const [superSKUEspot, setSuperSKUEspot] = useState<any>(null);
  const [superSKUEspotComponent, setSuperSKUEspotComponent] = useState<any>(null);
  const [espotHasContent, setEspotHasContent] = useState<any>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const formRef: any = React.useRef(null);
  const buttonRef: any = React.useRef(null);
  const navigate = useNavigate();
  const currentStore = useSelector(currentStoreSelector);
  const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);
  const { t } = useTranslation();
  const superSkuDrawerTitle = t("SuperSku");
  const [colorCode, setColorCode] = useState<any>(null);
  const [showSkeletonLoader, setShowSkeletonLoader] = useState<any>(false); //flag for showing the Skeleton Loader

  const { mySite } = useSite();
  const isB2B = mySite?.isB2B;
  const DrawerEspotName = isB2B ? "PRO_PDP-SuperSKU_Help" : "PDP-SuperSKU_Help";

  const dispatch = useDispatch<Dispatch<any>>();

  const storeID: string = mySite ? mySite.storeID : "";
  const catalogID: string = mySite ? mySite.catalogID : "";
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const currentStoreData = useSelector(currentStoreSelector);
  const storeUniqueId = currentStoreData?.currentStore?.uniqueID;

  const attributes = product?.attributes;
  const numberOfSKUs = product?.numberOfSKUs;
  const items = product?.items;
  const partNumber = product?.partNumber;

  useEffect(() => {
    if (hdmDrawerColorData?.changeBaseColor) {
      handleChangeBaseColor(hdmDrawerColorData?.changeBaseColor);
    }
  }, [hdmDrawerColorData]);

  function extractCurrentSSKUValues(items) {
    const currentValues = {};
    const data = items?.find((item) => item.partNumber === currentPartNumber);

    superSKUAttributes?.forEach((attribute) => {
      const match = data.attributes?.find((dataAttribute) => dataAttribute.identifier === attribute.identifier);
      if (match !== null) {
        currentValues[attribute.id] = {
          value: match?.values[0]?.identifier,
          identifier: attribute?.identifier,
          valueId: match?.values[0]?.id,
        };
      } else {
        currentValues[attribute.identifier] = null;
      }
    });

    //console.debug("--extractCurrentSSKUValues--")
    //console.debug(currentValues)
    setCurrentSKUAttributes(currentValues);
    handleCheckScroll()
    
  }

  const handleCheckScroll = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      setTimeout(() => {
        sessionStorage.removeItem("scrollPosition");            
        window.scrollTo({ top: parseInt(scrollPosition), behavior: "instant" });
        dispatch(CLOSE_BACKDROP_LOADER_ACTION({})); //if catentry data available close loader
      }, 300);
    }else {
      setTimeout(() => {
        dispatch(CLOSE_BACKDROP_LOADER_ACTION({})); //if catentry data available close loader
      }, 300);

    }
  }

  function extractSSKUValues(data) {
    const prefix = partNumber.split("_")[0];
    const attrs: any = [];
    data?.forEach((element) => {
      //console.log(element)
      //console.log(element.identifier)
      //console.log(element.identifier.endsWith(prefix))
      if (element.identifier.endsWith(prefix)) {
        const skuData = {};
        skuData["title"] = element.name;
        skuData["id"] = element.id;
        skuData["identifier"] = element.identifier;
        skuData["usage"] = element?.usage;
        skuData["counter"] = element.values.length;
        const values: any = [];
        //console.debug("element", element)
        element.values.forEach((value) => {
          const valueData = {};
          valueData["value"] = value.value;
          valueData["id"] = value.id;
          valueData["identifier"] = value.identifier;
          valueData["isAvailable"] = isValueAvailableInSSKUItems(items, value.id);
          valueData["isDisplayable"] = isValueDisplayableInSSKUItems(items, value.id);
          values.push(valueData);
        });
        skuData["values"] = values;
        attrs.push(skuData);
      }
      //console.log("---extractSSKUValues---")
      //console.log(attrs)
      //console.log(currentPartNumber)
      setSuperSKUAttributes(attrs);
    });

    if (attrs.length !== 0) {
      const query = {
        url: "",
        data: {
          ...hdmDrawerColorData,
          superSKUAttributes: attrs,
        },
        option: "HDM_DRAWER_COLOR_DATA",
        fetch: false,
      };

      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
    }
  }

  function isItemAvailable(id) {
    if (Object.keys(itemsAvailability).length > 0) {
      return Object.prototype.hasOwnProperty.call(itemsAvailability, id) && itemsAvailability[id];
    }
  }

  //check if value id is in any of the sSKU items
  function isValueAvailableInSSKUItems(items, id) {
    //check is attribute is in item
    //check inventory availability of items
    //if all items are unavailable, then the attribute is disabled
    let availableInItemAttributes = false;

    const productsWithCurrentID = items?.filter((item) => {
      
      if(item?.attributes){
        return (
          item.attributes.some((attribute) => {
              return attribute.values.some((value) => {
                return value.id === id;
              });
          })
        )
      }

    });

    if(productsWithCurrentID.length > 0 ){
      availableInItemAttributes = true;
    }

    //check for STORE_BUYABLE_EXCLUDE and item availability
    const isAnyItemValidAndAvailable = productsWithCurrentID.some((item)=>{
      const isAvailable = isItemAvailable(item.id);
      return item.attributes.some((attribute) => {
        if (attribute.identifier === "STORE_BUYABLE_EXCLUDE") {
          if (typeof attribute?.values[0]?.value === "string") {
            if (attribute?.values[0]?.value === currentStore.currentStore?.stLocId) {
              return false;
            } else {
              //if STORE_BUYABLE_EXCLUDE is not in item attributes 
              return true && isAvailable;
            }
          } else {
            const isPhysicalStoreIdInValues = attribute.values.some((value) =>
              value.value.some((storeId) => storeId === currentStore.currentStore?.stLocId)
            ) ;

            return !isPhysicalStoreIdInValues && isAvailable;
          }
        } else {
          return true && isAvailable ;
        }
      })
    })

    return isAnyItemValidAndAvailable;
  }

  //check if value id is displayable 
  function isValueDisplayableInSSKUItems(items, id) {
    //check is attribute is in item
    //check inventory availability of items

    const productsWithCurrentID = items?.filter((item) => {
      
      if(item?.attributes){
        return (
          item.attributes.some((attribute) => {
              return attribute.values.some((value) => {
                return value.id === id;
              });
          })
        )
      }

    });
    

    //check for STORE_BUYABLE_EXCLUDE and item availability
    //all products must be valid 
    const isAttributeVisibleInAnyItem = productsWithCurrentID.some((item)=>{
      return item.attributes.some((attribute) => {
        if (attribute.identifier === "STORE_BUYABLE_EXCLUDE") {
          if (typeof attribute?.values[0]?.value === "string") {
            if (attribute?.values[0]?.value === currentStore.currentStore?.stLocId) {
              return false;
            } else {
              //if STORE_BUYABLE_EXCLUDE is not in item attributes 
              return true;
            }
          } else {
            const isPhysicalStoreIdInValues = attribute.values.some((value) =>
              value.value.some((storeId) => storeId === currentStore.currentStore?.stLocId)
            ) ;

            return !isPhysicalStoreIdInValues;
          }
        } else {
          return true;
        }
      })
    })

    return isAttributeVisibleInAnyItem;
  }

  function findItemByLastRadioChange(items) {
    const keys = Object.keys(lastRadioButtonChanged);
    //console.log("--findItemByLastRadioChange--")
    //console.log(keys)

    const matches = items?.filter((item) => {
      return keys
        .map((key) => {
          return item.attributes.some((attr) => attr.id === key && attr.values[0]?.id === lastRadioButtonChanged[key]);
        })
        .some((value) => value);
    });

    //of all matches, select the first in which the current store is noOt excluded
    let match = undefined;
    if (matches !== undefined) {
      match = matches.find((match) =>
        match.attributes.some((attribute) => {
          if (attribute.identifier === "STORE_BUYABLE_EXCLUDE") {
            return !attribute.values.some((value) => {
              return value.value.includes(currentStore.currentStore?.stLocId);
            });
          } else {
            return true;
          }
        })
      );
    }

    return match;
  }

  function findItemByRadioGroupSelection(items, selection) {
    const keys = Object.keys(selection);
    //console.log(keys)
    let matches: any = null;
    if (keys.length > 0) {
      //find all items that match the current selection
      matches = items?.filter((item) => {
        return keys
          .map((key) => {
            return item.attributes.some((attr) => attr.id === key && attr.values[0]?.id === selection[key]);
          })
          .every((value) => value);
      });

      //console.log(matches)
      //of all matches, select the first in which the current store is noOt excluded
      let match = undefined;
      if (matches !== undefined) {
        match = matches.find((match) =>
          match.attributes.some((attribute) => {
            if (attribute.identifier === "STORE_BUYABLE_EXCLUDE") {
              return !attribute.values.some((value) => {
                return value.value.includes(currentStore.currentStore?.stLocId);
              });
            } else {
              return true;
            }
          })
        );
      }

      //console.log(match)

      if (match === undefined) {
        //console.log("--prioritize--")
        //console.log(keys.length , Object.keys(superSKUAttributes).length)
        //prioritize finding item with current radio button change
        //this guarantees that findItemByLastRadioChange is not called multiple times
        if (keys.length === Object.keys(superSKUAttributes).length) {
          const isMatch = findItemByLastRadioChange(items);
          if (isMatch !== undefined) {
            //console.log("--retorno findItemByLastRadioChange--")
            return isMatch;
          }
        }

        //if there is no results even prioritizing current radio button change then start deleting choices until one item is selected
        delete selection[keys[keys.length - 1]];
        return findItemByRadioGroupSelection(items, selection);
      } else {
        return match;
      }
    } else {
      //if there are no matches, return first item in list
      //console.log("--retorno 0 matches--")
      return items[0];
    }
  }

  function obtainItemsAvailability(items: any) {
    let itemsIds:any = null;
    const itemAvailability = {};
    if (items) {
      itemsIds = items.map((item) => item.id);
    }

    customInv
      .getAvailabilityByStores({
        productIds: itemsIds,
        onlineStoreId: mySite.storeID,
        productavailable: true,
        fullfillment_type: "Store",
        type: "ItemBean",
        search: 2,
        physicalStoreId: [storeUniqueId], //["12505", "12521"] <- array of all stores in the market
      })



    // const payload = {
    //   onlineStoreId: mySite.storeID,
    //   productavailable: true,
    //   fullfillment_type: "Store",
    //   type: "ItemBean",
    //   productIds: itemsIds,
    //   physicalStoreId: storeUniqueId,
    // };
    // inventoryavailabilityService
    //   .getInventoryAvailabilityByProductId({
    //     ...payload,
    //   })
      // .then((response) => response.data)
      .then((data) => {
        data.forEach((item) => {
          //WIP: Check if used response field are correct to consider if an item is available or not.
          itemAvailability[item.productId] = item.inventoryStatus === "Available" && (Number(item?.availableQuantity) > 0 || Number(item?.x_AvailableQuantityInMarket) > 0 || Number(item?.x_customField2) > 0);
        });
        setItemsAvailability(itemAvailability);
      })
      .catch(error => {
        //If error, set items availability empty
        setItemsAvailability({})
        console.error("Something went wrong!:", error);
        handleCheckScroll()
      })
  }

  useEffect(() => {
    //get items inventories
    obtainItemsAvailability(items);
  }, [product]);

  useEffect(() => {
    //If still have the initial value null, do nothing
    if(itemsAvailability != null){
      //Here a value has been setted
      if (Object.keys(itemsAvailability).length > 0) {
        extractSSKUValues(attributes);
      }
      else{
        //Item availability request completed, but no item available
        setShowSkeletonLoader(false); //Stop showing Skeleton Loader
        setCurrentSKUAttributes(undefined); //Flag for empty currentSKUAttributes, allow to hide the Skeleton Loader
      }
    }

    setTimeout(() => {
      handleCheckScroll()
    }, 5000);

  }, [itemsAvailability]);

  useEffect(() => {
    superSKUAttributes != null && extractCurrentSSKUValues(items);
  }, [superSKUAttributes]);

  //on component load, call for SuperSKU_Help Espot
  useEffect(() => {
    const asyncCall = async () => {
      const parameters: any = {
        storeId: storeID,
        name: DrawerEspotName,
        catalogId: catalogID,
        query: {
          DM_ReturnCatalogGroupId: true,
          DM_FilterResults: false,
        },
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
      };
      let eSpotRoot;
      try {
        const res = await eSpotService.findByName(parameters);
        eSpotRoot = res.data.MarketingSpotData[0];
        if(!eSpotRoot?.eSpotName){
          // return eSpotRoot = null;
          //If no espot, change null to undefined
          //In order to detect a new value and then can hide the sekeleton loader 
          return setSuperSKUEspot(undefined)
        } 
          setSuperSKUEspot(res);
        
      } catch (e) {
        eSpotRoot = null;
      }
    };

    asyncCall();
  }, [superSKUAttributes]);

  useEffect(() => {
    //If still have the initial value null, do nothing
    if(superSKUEspot !== null){
      //Here a value has been setted
      //If there is an espot drawer, enable the link to open it
      if (superSKUEspot != undefined) {
        setEspotHasContent(true);
        setSuperSKUEspotComponent(<EspotHdm espotName={DrawerEspotName} />);
      }
      else{
        //Asign false so the Skeleton Loader can be hidden
        setEspotHasContent(false);
      }
    }
  }, [superSKUEspot]);

  const handleSetScrollPosition = () => {
    sessionStorage.setItem("scrollPosition", window.scrollY.toString());
  }

  const handleChangeBaseColor = async ({ name, value, id, color }) => {
    // console.log('data', data)

    const lastSelection = {};
    lastSelection[id] = value;

    await setColorCode(color);
    const updatedValue = {};
    //value field is not used, in case it is neccesary.. further development is necessary
    updatedValue[id] = { value: "", identifier: name, valueId: value };
    await setCurrentSKUAttributes((currentSKUAttributes) => ({
      ...currentSKUAttributes,
      ...updatedValue,
    }));
    await setLastRadioButtonChanged(lastSelection);
    
    buttonRef.current.click();
  };

  const handleChange = async (event, id) => {
    const lastSelection = {};
    lastSelection[id] = event.target.value;
    //console.log("--handleChange---")
    //console.log(lastSelection)
    const updatedValue = {};

    //value field is not used, in case it is neccesary.. further development is necessary
    updatedValue[id] = { value: "", identifier: event.target.name, valueId: event.target.value };
    await setCurrentSKUAttributes((currentSKUAttributes) => ({
      ...currentSKUAttributes,
      ...updatedValue,
    }));
    await setLastRadioButtonChanged(lastSelection);
    buttonRef.current.click();
  };

  const handleSelectChange = async (event, id) => {
    const lastSelection = {};
    lastSelection[id] = event.target.value;
    await setLastRadioButtonChanged(lastSelection);

    const updatedValue = {};
    //value field is not used, in case it is neccesary.. further development is necessary
    updatedValue[id] = { value: "", identifier: event.target.name, valueId: event.target.value };
    await setCurrentSKUAttributes((currentSKUAttributes) => ({
      ...currentSKUAttributes,
      ...updatedValue,
    }));

    buttonRef.current.click();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const radioGroupSelection = {};

    superSKUAttributes.forEach((attr) => {
      radioGroupSelection[attr.id] = formRef?.current?.elements[attr.identifier].value;
    });

    const availableItems: any = []
    items.map(item => {
      const available = itemsAvailability[item.id]
      if(available){
        availableItems.push(item)
      } 
    })

    //console.log("--result--")
    const match = findItemByRadioGroupSelection(availableItems, radioGroupSelection);
    
    if (match?.seo?.href) {
      const color = colorCode ? `#colorCode:${colorCode}` : "";
      handleSetScrollPosition()
      navigate(match.seo.href + color);
    }
  };

  let cantidadOpciones: number = 0;
  if (superSKUAttributes && superSKUAttributes.length > 0) {
    cantidadOpciones = superSKUAttributes.length;
  
    superSKUAttributes.forEach((attribute) => {
      if (attribute?.identifier.includes("BASE_") && attribute?.usage === "Defining") {
        cantidadOpciones = superSKUAttributes.length - 1;
      }
    });
  }

  const PDPRightSideCard = styled(({ ...props }) => <StyledBox {...props} />)`
    ${({ theme }) => `

    background-color:white;

    .super-sku-label-unavailable{
      opacity:60%;
    }

    .atribute-sku-hide {
      display: none;
    }


  `}
  `;
  const PDPRightSideCardRadioContainer = styled(({ ...props }) => <StyledBox {...props} />)`
    ${({ theme }) => `

      display: flex;
      
      margin-right:10px;
      margin-bottom:10px;
      p{
        margin-right:5px;
      }

      .PDP-SSKUContainer-RadioComponent{
        margin-bottom:10px;
        display: flex;
        align-items:center;
      }

  `}
  `;

  const PDPRightSideCardContainer = styled(({ ...props }) => <StyledBox {...props} />)`
    ${({ theme }) => `

    padding:10px;
    margin-bottom: 10px;

    

    .PDPRightSideCard-title{
      margin-bottom: 10px;
    }

    .PDPRightSideCard-divider{
      background-color:${theme.palette.gray["50"]};
      margin-bottom:10px;
    }


  `}
  `;

  //console.log("---supersku---")
  //console.log(product);
  // console.log('superSKUAttributes', superSKUAttributes);
  // console.log('currentSKUAttributes', currentSKUAttributes)

  return (
    <>
      {attributes && items && currentStoreData?.currentStore?.uniqueID && (
        <PDPRightSideCard id="pdp-supersku-card">
          <PDPRightSideCardContainer className="super-sku supersku-card">
            <StyledTypography variant="textSegment" className={"PDPRightSideCard-title"}>
              Selecciona tus opciones
            </StyledTypography>

            <Divider className={"PDPRightSideCard-divider"} />
            <form ref={formRef} onSubmit={handleSubmit}>
              {/* This over-validations seems unnecesary, but must be this way for Skeleton Loader right working */}
              {(!showSkeletonLoader || superSKUAttributes != null) && currentSKUAttributes != null
                ? <> {
                superSKUAttributes != null && currentSKUAttributes != null && currentSKUAttributes !== undefined && 
                superSKUAttributes.map((attribute) => {
                  
                  let classAttrHide = "";
                  if (attribute?.identifier.includes("BASE_") && attribute?.usage === "Defining") {
                    classAttrHide = "atribute-sku-hide";
                  }

                  return (
                    <StyledBox key={attribute?.valueId} className={classAttrHide}>
                      <PDPRightSideCardRadioContainer className="supersku-attribute">
                        <StyledTypography variant="bodyBaseline">{attribute.title}</StyledTypography>
                        <StyledTypography variant="bodyBaseline" fontWeight={600}>
                          {currentSKUAttributes[attribute.id].value}
                        </StyledTypography>
                      </PDPRightSideCardRadioContainer>

                      {attribute.counter < 5 ? (
                        <>
                          <StyledRadioGroup
                            aria-labelledby={"super-sku-" + attribute.identifier}
                            defaultValue={currentSKUAttributes[attribute.id].valueId}
                            name={attribute.identifier}
                            onChange={(e) => handleChange(e, attribute.id)}>
                              <PDPRightSideCardRadioContainer>
                              {attribute.values.map((value) => (
                                value.isDisplayable && value.isAvailable && cantidadOpciones < 2 ? 
                                <div className={"PDP-SSKUContainer-RadioComponent"} style={{ marginBottom: "10px" }}>
                                  <StyledRadio
                                    color="primary"
                                    /* disabled={!value.isAvailable} */
                                    className={
                                      value.isAvailable ? "super-sku-label-available" : "super-sku-label-unavailable"
                                    }
                                    value={value.id}
                                    control={<StyledRadio />}
                                    key={attribute.id + "|" + value.id}
                                  />
                                  <StyledTypography
                                    variant="bodyBaseline"
                                    className={
                                      value.isAvailable ? "super-sku-label-available" : "super-sku-label-unavailable"
                                    }>
                                    {value.value}
                                  </StyledTypography>
                                </div>
                                 : value.isDisplayable && cantidadOpciones > 1 ? 
                                 <div className={"PDP-SSKUContainer-RadioComponent"} style={{ marginBottom: "10px" }}>
                                 <StyledRadio
                                   color="primary"
                                   disabled={!value.isAvailable}
                                   className={
                                     value.isAvailable ? "super-sku-label-available" : "super-sku-label-unavailable"
                                   }
                                   value={value.id}
                                   control={<StyledRadio />}
                                   key={attribute.id + "|" + value.id}
                                 />
                                 <StyledTypography
                                   variant="bodyBaseline"
                                   className={
                                     value.isAvailable ? "super-sku-label-available" : "super-sku-label-unavailable"
                                   }>
                                   {value.value}
                                 </StyledTypography>
                               </div>
                                : null
                              ))}
                            </PDPRightSideCardRadioContainer>
                          </StyledRadioGroup>
                        </>
                      ) : (
                        <PDPRightSideCardRadioContainer style={{ marginBottom: "10px" }}>

                          <StyledSelectHdm
                            label={null}
                            name={attribute.identifier}
                            value={currentSKUAttributes[attribute.id].valueId}
                            handleChange={(e) => handleSelectChange(e, attribute.id)}
                            id={"super-sku-" + attribute.id}
                            className="supersku-select"
                            defaultValue={currentSKUAttributes[attribute.id].valueId}
                            items={attribute.values.map((value) => (
                              value.isDisplayable &&
                              <MenuItemHDM
                                className={
                                  value.isAvailable ? "super-sku-label-available" : "super-sku-label-unavailable"
                                }
                                value={value.id}
                                disabled={!value.isAvailable}
                                key={attribute.id + "|" + value.id}>
                                {value.value}
                              </MenuItemHDM>
                            )
                            )}
                          />

                          {/*
                  <StyledSelect
                    labelId={"super-sku-"+attribute.id}
                    id={"super-sku-"+attribute.id}
                    defaultValue={currentSKUAttributes[attribute.id].valueId}
                    value = {currentSKUAttributes[attribute.id].valueId}
                    name={attribute.identifier}
                    onChange={(e)=>handleSelectChange(e,attribute.id)}
                    style={{marginBottom:"10px"}}
                  >
                    {attribute.values.map((value)=>(
                          <option  className={value.isAvailable?"super-sku-label-available":"super-sku-label-unavailable"}
                           value={value.id} 
                           key={attribute.id+'|'+value.id}>
                            {value.value} 
                          </option>

                        ))}
                  </StyledSelect>
                    */}
                        </PDPRightSideCardRadioContainer>
                      )}
                    </StyledBox>
                  );
                })}</>
                : (currentSKUAttributes !== undefined && <SLPDPSelectYourOptions/>)
                }
              <button ref={buttonRef} type="submit" style={{ display: "none" }}>
                Submit
              </button>
            </form>

            {/* While espotHasContent equals null, show Skeleton Loader */}
            {espotHasContent !== null ? (espotHasContent === true && superSKUEspotComponent &&
              <>
                <StyledLinkButton variant="bodySubText" onClick={() => setOpenDrawer(true)} to="" color="secondary">
                  {" Ayuda para elegir"}
                </StyledLinkButton>

                <StyledSwipeableDrawerHDM
                  isOpen={openDrawer}
                  setOpenDrawer={setOpenDrawer}
                  variant={"super-sku"}
                  title={superSkuDrawerTitle}>
                  {superSKUEspotComponent}
                </StyledSwipeableDrawerHDM>
              </>
            )
            : <SLPDPHelpForChoose/>}
          </PDPRightSideCardContainer>
        </PDPRightSideCard>
      )}
    </>
  );
};

export default SuperSkuContainer;
