/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
//Standard libraries
import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios, { Cancel, Canceler } from "axios";
import getDisplayName from "react-display-name";
//Redux
import wishListService from "../../_foundation/apis/transaction/wishList.service";
import * as successActions from "../../redux/actions/success";
import { getWishListSelector } from "../../redux/selectors/wish-list";
import * as wishListActions from "../../redux/actions/wish-list";
import productsService from "../../_foundation/apis/search/products.service";
//Custom libraries
import { EMPTY_STRING, REG_EX } from "../../constants/common";
import { currentContractIdSelector } from "../../redux/selectors/contract";
import * as orderActions from "../../redux/actions/order";
import { GET_USER_WISHLIST_ACTION } from "../../redux/actions/wish-list";
import * as ROUTES from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { currentStoreSelector } from "../../redux/selectors/current-store";
import { FETCH_HDM_DATA_REQUESTED } from "../../redux/action-types/hdmData";
import { hdmColorsRdc, hdmDrawerColorDataRdc, hdmWishListProductsQuantityRdc, lineaGamaColorsRdc, onlineStoreRdc } from "../../redux/selectors/hdmData";
import { useSite } from "../../_foundation/hooks/useSite";
import { triggerAddToWishListDL } from "../../components/custom-components/Datalayer-Utils/dataLayer-func";
import { loginStatusSelector } from "../../redux/selectors/user";
import { proCustomerLevelSelector } from "../../redux/selectors/organization";
import { CLOSE_BACKDROP_LOADER } from "../../redux/action-types/backdrop-loader";
import { CLOSE_BACKDROP_LOADER_ACTION, OPEN_BACKDROP_LOADER_ACTION } from "../../redux/actions/backdrop-loader";
import { LoaderActions } from "../../components/custom-components/skeleton-loader/actions/sl-actions";
import { WISH_LIST_FAVORITES } from "../../hdm/functions/constants";
import { checkIfHasColorBase, logEventWithValues } from "../../hdm/functions/utils";


const cancels: Canceler[] = [];
const CancelToken = Axios.CancelToken;
interface AddToWishListProps {
  partNumber: string | any[];
  externalId: string | number;
  isMoveToList?: any;
}

export const useWishList = () => {
  const { t } = useTranslation();
  const [wishListName, setWishListName] = useState<string>(EMPTY_STRING);
  const [errorWishListName, setErrorWishListName] = useState<any>({ error: false, errorMsg: "" });
  const widgetName = getDisplayName("ProductDetailsWidget");
  const storeConfData = useSelector(onlineStoreRdc);
  const dispatch = useDispatch();
  const userWishList = useSelector(getWishListSelector);
  const contract = useSelector(currentContractIdSelector);
  const storeSelector = useSelector(currentStoreSelector);
  const hdmWishListProductsQuantity = useSelector(hdmWishListProductsQuantityRdc);
  const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);
  const hdmLineaGamaColor = useSelector(lineaGamaColorsRdc);
  const hdmColors = useSelector(hdmColorsRdc);
  
  const widget = useMemo(() => getDisplayName("useWishList"), []);

  const pageLimit: number = 4;
  const [pageCountTotal, setPageCountTotal] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [paginatedList, setPaginatedList] = useState<any[]>([]);
  const [productsData, setProductsData] = useState<any[]>([]);
  const [emptyWishlist, setEmptyWishlist] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const toggleExpand = () => setIsExpanded((prev) => !prev);
  const { mySite } = useSite();
  const loginStatus = useSelector(loginStatusSelector);
  const proCustomerLevel = useSelector(proCustomerLevelSelector);

  const payloadBase = {
    storeId: mySite.storeID,
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const handleWishListName = (event) => {
    const valor = event.target.value.trim();
    const patt = new RegExp(/^[A-Za-zñÑáéíóúÁÉÍÓÚ´\s0-9]+$/g);
    const isValid = patt.test(event.target.value);

    if ((isValid && valor.length <= 50) || valor === "") {
      setErrorWishListName({
        error: false,
        errorMsg: EMPTY_STRING,
      });
      setWishListName(event.target.value);
    }
  };

  const handleSetWishListName = (name) => {
    setWishListName(name);
  };

  const validateWishListName = useCallback((wishListName: any) => {
    // const WISHLISTNAME_ALPHA_NUMERIC_SPECIAL_CHAR = REG_EX.NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR;
    if (wishListName.trim() === EMPTY_STRING) {
      setErrorWishListName({
        error: true,
        errorMsg: "Campo obligatorio",
      });
      // addToWishList()
      return true;
    }

    let isCreated = false;

    if (userWishList && userWishList.length !== 0) {
      isCreated = userWishList.some((lista) => {
        return lista.description.trim().toLowerCase() === wishListName.trim().toLowerCase();
      });
    }

    if (isCreated) {
      setErrorWishListName({
        error: true,
        errorMsg: "Ya existe una lista con este nombre",
      });
      return true;
    }

    return false;
  }, []);

  const canCreateWishList = async () => {
    if (validateWishListName(wishListName)) {
      setErrorWishListName({
        error: true,
        errorMsg: t("WishList.errors.sameNameList"),
      });
      return true;
    }
    const newList = await createWishList();
    return newList;
  };

  const canCreateWishListAndAddItem = async (partNumber, isMoveToList) => {
    if(wishListName.trim().toLocaleLowerCase() === WISH_LIST_FAVORITES.toLocaleLowerCase()){
      return true
    }

    if (validateWishListName(wishListName)) {
      return true;
    }
    await createWishListAndAddItem(partNumber, isMoveToList);
    // return false;
  };

  const createWishListAndAddItem = async (partNumber, isMoveToList) => {
    const params = {
      body: {
        description: wishListName.trim(),
        registry: false,
      },
    };
    try {
      const res = await wishListService.createWishlist(params);
      if (res?.data?.uniqueID) {
        const bodyItemWishList = { externalId: res.data.uniqueID, partNumber: partNumber, isMoveToList: isMoveToList };
        await addToWishList(bodyItemWishList);
        // dispatch(GET_USER_WISHLIST_ACTION({})
        // );
        // const successMessage = {
        //   key: "WishList.snackBar.addedToAnotherWishList",
        //   link: {
        //     url: `${ROUTES.WISH_LIST_DISPLAY_VIEW}/?id=${res?.data?.uniqueID}`,
        //     textKey: "WishList.snackBar.showList",
        //   },
        //   icon: "wishlist",
        // };
        // dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
        setWishListName(EMPTY_STRING);
        toggleExpand();
      }
    } catch (error) {
      console.log("Error in creating wish list", error);
    }
  };

  const isMobileApp = window.navigator.userAgent.includes("THDMXAPP");

  const addToWishList = async (props: AddToWishListProps) => {
    const { partNumber, externalId, isMoveToList } = props;

    const params = {
      body: {
        item: [{}],
      },
      addItem: true,
      externalId: externalId,
      ...payloadBase,
    };

    if (typeof partNumber === "object") {
      const items = partNumber.map((product) => {
        return {
          partNumber: product.partNumber,
          quantityRequested: product.quantity,
        };
      });
      params.body.item = items;
    } else {
      params.body.item = [
        {
          partNumber: partNumber,
          quantityRequested: "1",
        },
      ];
    }

    const res = await wishListService.updateWishlist(params);
    if (res.data.item) {
      const result = res.data.item;
      if (result && result.length > 0 && result[0].giftListItemID) {
        let successMessage: any = null;
        if(isMobileApp){
          logEventWithValues("success_add_to_wish_list", externalId)
        }else {
          if (isMoveToList) {
            deleteWishListItem(isMoveToList.externalId, isMoveToList.giftListItemID, isMoveToList.productName, true);
            successMessage = {
              key: "WishList.snackBar.addedToAnotherWishList",
              link: {
                url: `${ROUTES.WISH_LIST_DISPLAY_VIEW}?id=${externalId}`,
                textKey: "WishList.snackBar.showList",
              },
              icon: "moveWishlistItem",
            };
            } else {
            successMessage = {
              key: "WishList.snackBar.success",
              link: {
                url: `${ROUTES.WISH_LIST_DISPLAY_VIEW}?id=${externalId}`,
                textKey: "WishList.snackBar.showList",
              },
              icon: "wishlist",
            };
          }
        }

        dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
        dispatch(wishListActions.GET_USER_WISHLIST_ACTION({}));
        try {
          const itemAffiliation = storeConfData?.DL_ITEM_AFFILIATION;
          triggerAddToWishListDL(
            mySite.storeID,
            mySite?.isB2B && loginStatus && proCustomerLevel
              ? storeSelector?.currentStore?.stLocId + "_" + proCustomerLevel
              : storeSelector?.currentStore?.stLocId,
            contract,
            partNumber,
            1,
            itemAffiliation,
            storeSelector?.currentStore?.marketId,
            mySite?.isB2B
          );
        } catch (e) {
          console.log("Error DataLayer - Add To Wishlist", e);
        }
      } else {
        return false;
      }
    }
  };

  const createDefaultWishList = async (partNumber) => {
    const params = {
      body: {
        description: WISH_LIST_FAVORITES,
        registry: false,
        item:[
        {
          partNumber: partNumber,
          quantityRequested: "1"
        }
      ]
      },
    };
    try {
      const res = await wishListService.createWishlist(params);
      if (res?.data?.uniqueID) {
        dispatch(GET_USER_WISHLIST_ACTION({}));
        // const successMessage = {
        //   key: "success-message.CREATE_WISHLIST_SUCCESS",
        //   messageParameters: { "0": "Mis favoritos" },
        // };
        const successMessage = {
          key: "WishList.snackBar.success",
          link: {
            url: `${ROUTES.WISH_LIST_DISPLAY_VIEW}?id=${res?.data?.uniqueID}`,
            textKey: "WishList.snackBar.showList",
          },
          icon: "wishlist",
        };
        dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
        dispatch(wishListActions.GET_USER_WISHLIST_ACTION({}));
        setWishListName(EMPTY_STRING);
        toggleExpand();
        return res?.data?.uniqueID;
      }
    } catch (error) {
      console.log("Error in creating wish list", error);
    }
  }

  const createWishList = useCallback(async () => {
    if(wishListName.trim().toLocaleLowerCase() === WISH_LIST_FAVORITES.toLocaleLowerCase()){
      return true
    }

    const params = {
      body: {
        description: wishListName.trim(),
        registry: false,
      },
    };
    try {
      const res = await wishListService.createWishlist(params);
      if (res?.data?.uniqueID) {
        dispatch(GET_USER_WISHLIST_ACTION({}));
        const successMessage = {
          key: "success-message.CREATE_WISHLIST_SUCCESS",
          messageParameters: { "0": wishListName.trim() },
        };
        dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
        setWishListName(EMPTY_STRING);
        toggleExpand();
        return res?.data?.uniqueID;
      }
    } catch (error) {
      console.log("Error in creating wish list", error);
    }
  }, [wishListName, dispatch, widget]);

  const updateWishListName = useCallback(
    async (wishListId: string) => {
      if (validateWishListName(wishListName)) {
        return false;
      }

      const params = {
        externalId: wishListId,
        body: {
          description: wishListName.trim(),
        },
        widget,
        cancelToken: new CancelToken((c) => cancels.push(c)),
      };
      try {
        const res = await wishListService.updateWishlist(params);
        if (res?.data?.uniqueID) {
          // const successMessage = {
          //   key: "success-message.UPDATE_WISHLIST_NAME_SUCCESS",
          //   messageParameters: { "0": wishListName.trim() },
          // };
          // dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
          dispatch(wishListActions.GET_USER_WISHLIST_ACTION({}));

          return true;
        }
      } catch (error) {
        console.log("Error in updating wish list name", error);
      }
    },
    [dispatch, wishListName, widget]
  );

  const cleanWishListName = () => {
    setWishListName("");
  };

  const getProductsData = useCallback(

    async (wishList: any) => {
      let numberOfProducts = undefined;
      const partNumber: string[] = [];
      if (wishList.item) {
        for (const product of wishList.item) {
          if (product.partNumber) partNumber.push(product.partNumber);
        }
      }
      if (partNumber.length > 0) {
        const requestParameters = {
          physicalStoreId: storeSelector?.currentStore?.stLocId,
          partNumber,
          widget,
          cancelToken: new CancelToken((c) => cancels.push(c)),
        };
        try {
          const res = await productsService.findProductsUsingGET(requestParameters);
          const products = res?.data?.contents;
          numberOfProducts = !products ? 0 : products.length;
          dispatch(OPEN_BACKDROP_LOADER_ACTION({ src: LoaderActions.FETCH_WISHLIST_PRODUCTS, idx: numberOfProducts }))
          if (products) {
            setProductsData(
              products.map((p) => {
                const _item = wishList.item.find((i) => i.partNumber === p.partNumber) ?? {};
                return { ..._item, ...p };
              })
            );

            const data = products.map((product) => {
              return {
                id: product.id,
                quantity: "1",
              };
            });

            const query = {
              url: "",
              data,
              option: "WISH_LIST_PRODUCTS_QUANTITY",
              fetch: false,
            };
            dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
          }
        } catch (error:any) {
          setProductsData([]);
          Object.getPrototypeOf(error).__CANCEL__ && dispatch(OPEN_BACKDROP_LOADER_ACTION({ src: LoaderActions.FETCH_WISHLIST_PRODUCTS, idx: 0 }))
          console.log("Error in getting product details", error);
        }

      } else {
        dispatch(OPEN_BACKDROP_LOADER_ACTION({ src: LoaderActions.FETCH_WISHLIST_PRODUCTS, idx: 0 }))
        setProductsData([]);
        setEmptyWishlist(true);
      }

    },
    [widget, storeSelector]
  );

  const deleteWishList = useCallback(
    (wishListId: string, wishListName: string) => {
      const parameters: any = {
        externalId: wishListId,
        wishListName,
        length: userWishList.length,
        widget,
        // cancelToken: new CancelToken((c) => cancels.push(c)),
      };

      dispatch(wishListActions.WISHLIST_DELETE_ACTION({ ...parameters }));
    },
    [userWishList, dispatch, widget]
  );

  // const moveItemToNewList = () => [

  // ]

  const deleteWishListItem = useCallback(
    (wishListId: string, id: string, name: string, showSnackbarMsg: boolean = true) => {
      const parameters: any = {
        externalId: wishListId,
        itemId: id,
        productName: name,
        widget,
        // deleteMsgSnackbar: showSnackbarMsg,
        // cancelToken: new CancelToken((c) => cancels.push(c)),
      };

      dispatch(wishListActions.WISHLIST_DELETE_ITEM_ACTION({ ...parameters }));
      dispatch(GET_USER_WISHLIST_ACTION({}));
    },
    [dispatch, widget]
  );

  const deleteWishListMultipleItems = useCallback(
    async (items: Array<any>, showSnackbarMsg: boolean = true) => {
      const deletingArray: Promise<any>[] = [];
      items.forEach(({ wishListId: externalId, id: itemId, name: productName }) => {
        const payload = {
          externalId,
          itemId,
          productName,
          widget,
          cancelToken: new CancelToken((c) => cancels.push(c)),
        };
        deletingArray.push(wishListService.deleteWishlist(payload));
      });
      try {
        await Promise.all(deletingArray);
        if (showSnackbarMsg) {
          const msg = {
            key: "success-message.DELETE_WISHLIST_ITEMS_SUCCESS",
            messageParameters: { "0": items.length.toString() },
          };
          dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(msg));
        }
        dispatch(GET_USER_WISHLIST_ACTION({}));
      } catch (e) {
        console.log("Encountered an error deleting items from wish-list", e);
      }
    },
    [dispatch, widget]
  );

  const addToCart = useCallback(
    (products) => {
      const param = {
        fromWishList: true,
        products,
        partnumber: products.map(({ partNumber }) => partNumber),
        quantity: products.map((v) => "1"),
        contractId: contract,
        widget,
        cancelToken: new CancelToken((c) => cancels.push(c)),
      };
      dispatch(orderActions.ADD_ITEM_ACTION(param));
    },
    [contract, dispatch, widget]
  );

  const addAllToCart = (products) => {
    // for paints we need to add the colorCode
    products?.forEach((product) => {
      // We need to iterate each product in the wishlist to know what type of sku is
      let isMainPaint = false;

      const { manufacturer, attributes, partNumber: partnumber } = product
      if (hdmDrawerColorData?.partNumber) {
        if (typeof partnumber === "object" && partnumber.includes(hdmDrawerColorData?.partNumber)) {
          isMainPaint = true;
        }
      }
      const hasProductBaseColor = checkIfHasColorBase(hdmColors, { manufacturer, attributes });
      let baseGamaColorDefault: any = null;
      let colorCode: any =  null;
      if (hasProductBaseColor) {
        const { branchColor, lineColor, baseColor } = hasProductBaseColor;
        const pinturasHDM = hdmLineaGamaColor;
        const colorBase = pinturasHDM.filter(
          (database) => database?.marca === branchColor && database?.linea === lineColor && database.gama === "#N/D"
        )[0];
        baseGamaColorDefault = colorBase?.colores.filter((color) => color?.base === baseColor)[0];
        colorCode = baseGamaColorDefault?.valor;
      }
      const param = {
        fromWishList: true,
        productsData,
        partnumber,
        // partnumber: products.map(({ partNumber }) => partNumber),
        quantity: hdmWishListProductsQuantity.filter((item) => item.quantity && item.id === product.id)[0]?.quantity,
        colorCode: colorCode
        ? colorCode
        : !isMainPaint
        ? null
        : hdmDrawerColorData?.colorCode
        ? hdmDrawerColorData?.colorCode
        : null,
        contractId: contract,
        widget,
        cancelToken: new CancelToken((c) => cancels.push(c)),
      };
      dispatch(orderActions.ADD_ITEM_ACTION(param));
    });

    const data = hdmWishListProductsQuantity.map((item) => {
      return {
        id: item.id,
        quantity: "1",
      };
    });

    const query = {
      url: "",
      data,
      option: "WISH_LIST_PRODUCTS_QUANTITY",
      fetch: false,
    };
    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
  };

  useEffect(() => {
    return () => {
      cancels.splice(0).forEach((cancel: Canceler) => {
        cancel();
      });
    };
  }, []);

  return {
    wishListName,
    handleWishListName,
    validateWishListName,
    canCreateWishList,
    createWishList,
    deleteWishList,
    getProductsData,
    productsData,
    addToCart,
    addAllToCart,
    setWishListName,
    updateWishListName,
    deleteWishListItem,
    pageLimit,
    pageCountTotal,
    setPageCountTotal,
    selectedPage,
    setSelectedPage,
    setPaginatedList,
    paginatedList,
    emptyWishlist,
    deleteWishListMultipleItems,
    isExpanded,
    toggleExpand,
    errorWishListName,
    addToWishList,
    cleanWishListName,
    canCreateWishListAndAddItem,
    handleSetWishListName,
    setErrorWishListName,
    createDefaultWishList
  };
};
