import EspotHdm from "../../../components/custom-components/shared/EspotHdm";

import { StyledCardEmpty } from "../../elements/card/styled-card-empty";
import { StyledTypography } from "../../elements/typography";
import { Divider } from "@material-ui/core";

import { ContainerStyledBox, ContainerStyledTypography } from "./containers-precio-promocion";

import ProductPrice from "../../elements/product-card/styled-product-price";
import PrecioPesoMedida from "../../components/precio-peso-medida";
import { palette } from "../../themes/color-palette";
import { AssignedPromo } from "./AssignedPromo";
import { useTranslation } from "react-i18next";
import ErrorSharpIcon from "@material-ui/icons/ErrorSharp";
import { StyledBox } from "../../elements";
import withSkeletonLoader from "../../../components/custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { loaders } from "../../../components/custom-components/skeleton-loader/components";
import { WEIGHT_BOX, WEIGHT_LM, WEIGHT_MTK, WEIGHT_MTR } from "../../functions/constants";


interface Props {
  catentryData: any;
  product: any;
  proPriceReady: boolean;
  brand: any;
  attributes: any;
  partNumber: any;
  shortDescription: any;
  nominalQuantity: any;
  productId: any;
  quantityMeasure: any;
  weightMeasure: any;
  promociones: any;
  urlRibbons: any;
  orderRibbons: any;
  isB2B: boolean;
  loginStatus: boolean;
}

const PdpCardPrecio = (props: Props) => {
  const { catentryData, product, proPriceReady, productId, quantityMeasure, weightMeasure, promociones, urlRibbons, orderRibbons, isB2B, loginStatus, brand, attributes, partNumber, shortDescription, nominalQuantity } = props;

  const { t } = useTranslation();

  let isValidweightMeasure = false
  if(weightMeasure){
    if(quantityMeasure === WEIGHT_MTR && (weightMeasure === WEIGHT_BOX || weightMeasure === WEIGHT_LM)){
      isValidweightMeasure = true
    }
    if(quantityMeasure === WEIGHT_MTK && (weightMeasure === WEIGHT_BOX || weightMeasure === WEIGHT_LM)){
      isValidweightMeasure = true
    }
  }

  const hasPromo = orderRibbons && orderRibbons.some(item => item.includes("PROMO"));

  const ProductPriceCard = () => {
    return (
      <ContainerStyledBox className="precio-promocion-container">
        <StyledCardEmpty className="product-price-card">
          <ContainerStyledBox mb={2}>
            {isB2B ?
              <EspotHdm espotName="PRO_PDP-Price_SuperiorSection" />
              :
              <EspotHdm espotName="PDP-Price_SuperiorSection" />
            }
          </ContainerStyledBox>
          <ContainerStyledBox display="flex" p={1}>
            <ContainerStyledBox className="product-price" flexGrow={1}>
              {catentryData && quantityMeasure &&
                <ProductPrice
                  parentProduct={product}
                  productData={catentryData}
                  quantityMeasure={quantityMeasure}
                  weightMeasure={weightMeasure}
                  promociones={promociones}
                  urlRibbons={urlRibbons}
                />
              }
            </ContainerStyledBox>
            {quantityMeasure && isValidweightMeasure && quantityMeasure !== "" && quantityMeasure !== "c62" ? (
              <>
                <Divider orientation="vertical" flexItem style={{ backgroundColor: palette.gray["50"] }} />
                <ContainerStyledBox ml={2} alignContent="center">
                  <PrecioPesoMedida
                    catentryData={catentryData}
                    promociones={promociones}
                    quantityMeasure={quantityMeasure}
                    weightMeasure={weightMeasure}
                  />
                  {/* <ContainerStyledTypography variant="bodyBaseline" fontWeight="bold">
                    {quantityMeasure !== "" ? "por " + quantityMeasure : ""}
                  </ContainerStyledTypography> */}
                  {/* <ContainerStyledTypography variant="bodyBaseline" fontWeight="bold">
                    $000,000.00
                    {labelMeasureUnit !== "" ? (
                      <StyledTypography variant="body" weight="semibold" component="span">
                        {labelMeasureUnit}
                      </StyledTypography>
                    ) : (
                      <></>
                    )}
                  </ContainerStyledTypography> */}
                  {/* <ContainerStyledTypography variant="bodySubText">Antes $000,000.00</ContainerStyledTypography> */}
                </ContainerStyledBox>
              </>
            ) : (
              <></>
            )}
          </ContainerStyledBox>

          <ContainerStyledBox mb={2}>
            {!loginStatus && isB2B && (
              <EspotHdm espotName="PRO_PDP-Price_MiddleSection_GuestLogin" />
            )}
            {loginStatus && isB2B && (
              <EspotHdm espotName="PRO_PDP-Price_MiddleSection_SCC" />
            )}
          </ContainerStyledBox>
          <Divider style={{ backgroundColor: palette.gray["50"] }} />


          <ContainerStyledBox id={"productMSI"} className={hasPromo ? "assigned-promo" : "notAssigned-promo"}>
            <AssignedPromo catentryData={catentryData} product={product}
              brand={brand}
              attributes={attributes}
              partNumber={partNumber}
              shortDescription={shortDescription}
              nominalQuantity={nominalQuantity}
              productId={productId}
              orderRibbons={orderRibbons}
              urlRibbons={urlRibbons} />
          </ContainerStyledBox>
          <ContainerStyledBox>
            {isB2B ?
              <EspotHdm espotName="PRO_PDP-Price_InferiorSection" />
              :
              <EspotHdm espotName="PDP-Price_InferiorSection" />
            }
          </ContainerStyledBox>
        </StyledCardEmpty>
      </ContainerStyledBox>
    );
  }

  return withSkeletonLoader(<ProductPriceCard />, product, loaders.pdp.priceCard)
};

export default PdpCardPrecio;
